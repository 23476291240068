import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Container, Typography, TextField, Button, IconButton, FormControl, InputAdornment, Avatar,CircularProgress } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from 'react-toastify';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

import Page from '../../components/Page';
import { useUserContext } from '../../UserContext';
import useAccount from '../../_mock/account'; // Assuming this mock provides account data
import { profileupdate } from '../../apis/auth';
import storage from '../../firebase';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatPhone = (phone) => {
  phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    }else if (phone.startsWith('11') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
};

export default function Profile() {
  const { user,setUserDetails } = useUserContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [phonenumber, setPhoneNumber] = useState(user ? user.phonenumber : '');
  const [error, setError] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [success, setSuccess] = useState(false);
  const account = useAccount(); // Fetch the account data
  const [imageUrl, setImageUrl] = useState(account.photoURL); // State variable for original image URL
  const [uploadUrl, setUploadUrl] = useState(''); // State variable for newly uploaded image URL
  const [response, setResponse] = useState(null); // State to hold response data
  const [isUploading, setUploading] = useState(false); 
  const navigate = useNavigate();

  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    
    // Ensure the file is an image file
    if (file.type.startsWith('image/')) {
      setUploading(true);
  
      try {
        // console.log("Handling image upload...");
        
        const imageRef = storageRef(storage, `profile_images/${user.username}`);
        
        // Upload the image file directly without any conversion
        uploadBytes(imageRef, file)
          .then((snapshot) => {
            getDownloadURL(snapshot.ref)
              .then((url) => {
                setUploadUrl(url);
                toast.success("Image uploaded successfully!");
                // console.log("Image uploaded successfully:", url);
              })
              .catch((error) => {
                console.error('Failed to get download URL:', error);
                toast.error('Failed to get download URL. Please try again later.');
              });
          })
          .catch((error) => {
            console.error('Failed to upload image:', error);
            toast.error('Failed to upload image. Please try again later.');
          });
      } catch (error) {
        console.error('Failed to handle image upload:', error);
        toast.error('Failed to handle image upload. Please try again later.');
      } finally {
        setUploading(false);
      }
    } else {
      // Handle the case where the selected file is not an image
      toast.error('Please select an image file.');
    }
  };
  
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      // console.log(phonenumber);

      const phone = formatPhone(phonenumber) || user.phonenumber;

      // const phone = formatPhone(phonenumber);

      if (!/^\d{12}$/.test(phone) || !phone.startsWith('254')) {
        throw new Error('Invalid phone number format. Please enter a 12-digit number starting with 254.');
      }
      const requestBody = {
        accountNumber: accountNumber || user.accountNumber,
        phonenumber: phone,
        url: uploadUrl || imageUrl, // Use the newly uploaded image URL if available, otherwise use the original image URL
      };
      // console.log(requestBody);

      const response = await profileupdate(user, requestBody);
      setUserDetails(response.data.user);
      setSuccess('Profile Updated Successfully.')
      toast.success('Profile updated successfully!');
    } catch (error) {
      // toast.error(error.message || 'Failed to update profile. Please try again later.');
      if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        navigate('/login');
      } 
    } finally {
      setIsSubmitting(false);
      setIsEditing(false);
    }
  }; 

  return (
    <Page title="Rotsi Profile page">
      <Container>
          {!uploadUrl && 
            <Avatar src={imageUrl} alt="photoURL" sx={{ width: 100, height: 100, margin: '0 auto' }} /> 
          }
          {uploadUrl && 
              <Avatar src={uploadUrl} alt="photoURL" sx={{ width: 100, height: 100, margin: '0 auto' }} />
          }
          {success && 
            <Typography variant="body1" style={{ color:"green", marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              {success}
            </Typography>
          }
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
            <IconButton component="label">
              <CloudUploadIcon />
              <input
                type="file"
                accept="image/jpeg, image/png, image/gif"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </IconButton>
            
            {isUploading && (
              <div style={{ position: 'relative' }}>
                <CircularProgress
                  size={64}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              </div>
            )}
          </div>
          <Typography variant="body2" align="center" display='flex' alignItems= 'center' justifyContent= 'center' sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} marginTop= '10px'>
            Please upload a square-shaped picture. Max 2MB. Formats allowed: jpg, png, and gif
          </Typography>

          <Typography variant="h5" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom align="center">
            {capitalizeFirstLetter(account.displayName)}
          </Typography>        

          <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label="Email"
              variant="outlined"
              value={isEditing ? user.email : user.email}
              onChange={(e) => {}}
              sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              disabled={!isEditing}
            />
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label="Secret Key"
              variant="outlined"
              value={user.secretKey}
              onChange={(e) => {}}
              sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              disabled={!isEditing}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => {
                      navigator.clipboard.writeText(user.secretKey);
                      toast.info('Secret Key copied to clipboard');
                    }}>
                      <FileCopyIcon/>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label={account.phonenumber}
              variant="outlined"
              placeholder={account.phonenumber}
              sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              value={phonenumber}
              onChange={(e) => {
                const inputPhoneNumber = e.target.value;
                // Validate phone number format
                if (/^\d{0,12}$/.test(inputPhoneNumber)) {
                  setPhoneNumber(inputPhoneNumber);
                }
              }}
            />
          </FormControl>
{/* 
          <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label={`Current account: ${account.accountNumber}`} // Use backticks for dynamic label
              variant="outlined"
              sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              placeholder={account.accountNumber}
              value={accountNumber}
              onChange={(e) => {
                const inputAccountNumber = e.target.value;
                setAccountNumber(inputAccountNumber);
              }}
            />
          </FormControl> */}

          {!isUploading && (
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting || isUploading}
              onClick={handleFormSubmit}
              sx={{ display: 'block', margin: '0 auto', width: '200px',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
            >
              <span>{isSubmitting ? 'Updating Profile' : 'Update Profile'}</span>
            </Button>
          )}
      </Container>
    </Page>
  );
}