// src/DownloadReceipt.js
import React, { useEffect, useState, useRef } from 'react';
import { useParams,Navigate,useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Container, Typography } from '@mui/material';
import ReactToPrint from 'react-to-print';
import { IoMdAlert } from 'react-icons/io';
import { ColorRing } from 'react-loader-spinner';
import ReceiptPage from './ReceiptPage';
import { useUserContext } from '../UserContext';
import Login from './Login';
import {generateReceipt} from '../apis/paymentapis';

const ProcessingComponent = () => (
    // View when processing
    <Container style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // Ensures the container takes the full height of the viewport
    }}>
      <div style={{ textAlign: 'center' }}>
        <ColorRing visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/>
        <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Generating Receipt...
        </Typography>
      </div>
    </Container>
); 

const ErrorComponent = ({ receiptError,handlePaymentErrorButtonClick }) => (
    <div style={{ textAlign: 'center' }}>
      <IoMdAlert size={24} color="red" />
      <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        {receiptError.message}
      </Typography> 
      <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 }}>
        Try Again
      </Button>
    </div>
);
  
const DownloadReceipt = () => {
  const { receiptId } = useParams();
  // console.log(receiptId);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [receiptData, setReceiptData] = useState(null);
  const [receiptError, setReceiptError] = useState('');
  const componentRef = useRef();

  useEffect(() => {
    let timer;
    if (user && receiptId) {
      // Set a timer to wait for 5 seconds before fetching receipt details
      timer = setTimeout(() => {
        fetchReceiptDetails(user, receiptId);
      }, 5000); // 5000 milliseconds = 5 seconds
    }
  
    // Cleanup function to clear the timer if the component unmounts or the dependency changes
    return () => clearTimeout(timer);
  }, [user, receiptId]); // Include user and receiptId as dependencies  

  if (!user && !receiptId) {
    if(!user){
      <Login />;
    }
    if(!receiptId && user){
      if(user.location === 'businessclients'){
        // If login is successful, navigate to the desired page (e.g., /home)
        navigate('/business-dashboard/app', { replace: true });
      }else if(user.location === 'staffemployee'){
        // If login is successful, navigate to the desired page (e.g., /home)
        navigate('/staff-dashboard/app', { replace: true });
      }else{
        // If login is successful, navigate to the desired page (e.g., /home)
        navigate('/dashboard/app', { replace: true });
      }
    }
  }

  if (!user) {
    return <Navigate to="/login" />; // Use Navigate to redirect to the login page
  }

  const fetchReceiptDetails = async (user, receiptId) => {
    try {
      setLoading(true);  
      // console.log(receiptId);
      const data = await generateReceipt(user,receiptId);
      const receiptData = data;
      // console.log(receiptData);
      setReceiptData(receiptData); 
      setLoading(false);// Assuming the response structure has a property 'numTransactions'
    } catch (error) {
      console.error(`Error receipt Data: ${error}`);
      setReceiptError(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentErrorButtonClick = () => {
    if(user.location === 'businessclients'){
      // If login is successful, navigate to the desired page (e.g., /home)
      navigate('/business-dashboard/app', { replace: true });
    }else if(user.location === 'staffemployee'){
      // If login is successful, navigate to the desired page (e.g., /home)
      navigate('/staff-dashboard/app', { replace: true });
    }else{
      // If login is successful, navigate to the desired page (e.g., /home)
      navigate('/dashboard/app', { replace: true });
    }
  };

  return (
    <>
    {loading && <ProcessingComponent />}
    {receiptError && <ErrorComponent receiptError={receiptError} handlePaymentErrorButtonClick={handlePaymentErrorButtonClick}/>}
    {receiptData && !receiptError && !loading && (
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <ReceiptPage ref={componentRef} receiptData={receiptData} />
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" sx={{ marginTop:5, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }} color="primary">
                  Print this receipt
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </Container>
    )}
    </>
  );
};

export default DownloadReceipt;
