import React, { useState, useEffect } from 'react';
import { useNavigate , Link as RouterLink} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, List, ListItem, ListItemText, Typography, Button ,IconButton, InputAdornment,Tooltip,Link,TextField,Container} from '@mui/material';
import { Edit,SwapHoriz } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Checkmark } from 'react-checkmark';
import { toast } from 'react-toastify';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Fuse from 'fuse.js';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ShareIcon from '@mui/icons-material/Share';
import * as Yup from 'yup';

import { FormProvider, RHFTextField,RHFTextFieldImage } from '../../../components/hook-form';
import { referralProgram } from '../../../apis/auth';

const defaultData = {
  phonenumber: '',
  email: '',
  name: '',
};

const formatPhone = (phone) => {
  phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    }else if (phone.startsWith('11') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
};

const SuccessComponent = ({ response,handleCopyLink, isMobile }) => (
  <div style={{ textAlign: 'center' }}>
    <Checkmark size='96px' color='green' />
    {/* <Typography variant="body1" style={{ marginTop: 10,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>Deposit successful!</Typography> */}
    <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      {response}
    </Typography>
    <IconButton onClick={handleCopyLink} aria-label={isMobile ? 'Share link' : 'Copy link'}>
        {isMobile ? <ShareIcon /> : <FileCopyIcon />}
    </IconButton>
  
  </div>
);
export default function ReferralProgramForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(defaultData);
  const [step, setStep] = useState(1); 
  const [email, setEmail] = useState(''); 
  const [name, setName] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [response, setResponse] = useState('');
  const [isBankChosen, setIsBankChosen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const paybillNumber = process.env.REACT_APP_ROTSIPAYBILL;
  
  useEffect(() => {
    // Check if the user is on a mobile device
    const checkMobileDevice = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };
    checkMobileDevice();
    // Optionally add a resize event listener if you want to update this on window resize
  }, []);

  const PhoneSchema = Yup.object().shape({
    phonenumber: Yup.string()
        .required('Phone number is required')
        .matches(/^(254)\d{9}$/, 'Phone number must start with 254 and be 12 digits long'),
  });

  const EmailSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
  });

  const BankSchema = Yup.object().shape({
    accountNumber: Yup.string().required('Bank account is required'),
  });

  const methods = useForm({
    resolver: yupResolver(step === 1 ? PhoneSchema : BankSchema),
    defaultValues: defaultData,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBackButton = () => {
    setStep(1);
  };

  const handleCopyLink = () => {
    const inviteLink = `${response.url}`;

    if (isMobile) {
      if (navigator.share) {
        navigator.share({
          title: 'Share out this referral-link, and start earning monthly!',
          url: inviteLink,
        })
        .then(() => toast.success('Link shared successfully'));
      } else {
        navigator.clipboard.writeText(inviteLink);
        toast.success('Link copied to clipboard');
      }
    } else {
      navigator.clipboard.writeText(inviteLink)
        .then(() => toast.success('Link copied to clipboard'));
    }
  };

  const proceed = () => {
    const phone = formatPhone(phonenumber);
    if (step === 1) {     
      if (!/^\d{12}$/.test(phone) || !phone.startsWith('254')) {
        toast.error('Phone number must start with 254 and be 12 digits long');
        return; // Stop proceeding if the phone number is invalid
      }
      setIsBankChosen(true);
      setStep(2); // Proceed to the next step if the phone number is valid
    }
  };  
  
  const formSubmit = async (data) => {
    try {
      setIsLoading(true);
      const phone = formatPhone(phonenumber);

      const submitData = { phone, email, name };
      // console.log(submitData);
      
      try {
        const response = await referralProgram(submitData);
        console.log(response);
        setResponse(response.url);
        toast.success(response.message);
      } catch (error) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }

    } catch (error) {
      console.error('Submission failed', error);
      toast.error('Submission failed. Please try again later.');
    }
  };

  const onSubmit = handleSubmit(formSubmit); // Call formSubmit when form is submitted

  return (
    response ? (
      <SuccessComponent response={response} />
    ) : (
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={3}>
          {step === 1 && (
            <>
              <TextField
                label="Phone Number to be receiving the payment"
                type="number"
                variant="outlined"
                placeholder="2547XXXXXXXX"
                value={phonenumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
              />
              <LoadingButton 
                fullWidth 
                size="large" 
                variant="contained" 
                onClick={proceed}
                sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
              >
                Proceed
              </LoadingButton>
            </>
          )}
  
          {step === 2 && (
            <>
              {isBankChosen && (
                <>
                  <IconButton onClick={handleBackButton} sx={{ marginBottom: 0.5 }}>
                    <SwapHoriz /> 
                  </IconButton>
  
                  <RHFTextField
                    name="email"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                    autoFocus
                  />
                  <RHFTextField
                    name="name"
                    label="Full Name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                  />
                </>
              )}
  
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={formSubmit}
                loading={isLoading}
                sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem', display: isBankChosen ? 'block' : 'none' }}
              >
                Submit Details
              </LoadingButton>
            </>
          )}
        </Stack>
      </FormProvider>
    )
  );  
}