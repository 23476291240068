import React, { useEffect, useState } from 'react';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import { Container, Table, TableBody, TableCell, TableContainer,TextField,IconButton, TableHead, TableRow, Paper, TablePagination, Button, Modal, Box, Typography, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ColorRing } from 'react-loader-spinner';
import { getBusinessOrders,updateUserPermission,updateUserStatus,extraPersonnel } from '../../apis/auth';
import { useUserContext } from '../../UserContext';

const ProcessingComponent = ({message}) => (
  // View when processing
  <Container style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Ensures the container takes the full height of the viewport
  }}>
    <div style={{ textAlign: 'center' }}>
      <ColorRing visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/>
      <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          {message}
      </Typography>
    </div>
  </Container>
); 

const BusinessUsers = () => {
  const { user } = useUserContext();
  console.log(user.subscriptionend);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPermission, setSelectedPermission] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState(null); // State to hold response data
  const [success, setSuccess] = useState(false);
  const [selectedRole, setSelectedRole] = useState(false);
  const [employeeFields, setEmployeeFields] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmpassword: '',
    permissions: '',
  });

  const [isUploading, setIsUploading] = useState(false);
  // const [fetching, setFetching ] = useState(false);

  useEffect(() => {
      if (!user) {
      navigate('/login'); // Navigate to the login page if user is not available
      }
  }, [user, navigate]);

  useEffect(() => {
      if (user) {
          fetchDetails();   
      }
  }, [user]);

  const fetchDetails = async () => {
      try {
        setLoading(true);
          const responseData = await getBusinessOrders(user);
          setUsers(responseData.data); // Adjusted to match the expected data structure
      } catch (error) {
          if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
              navigate('/login');
          } else{
              setError(error.message);
          }
      } finally {
          setLoading(false);
      }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleAddUser = async () => {
  //   // Add user logic here
  // };
  const handleAddUser = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      if(!employeeFields.firstname || !employeeFields.lastname || !employeeFields.password || !employeeFields.confirmpassword || !employeeFields.email) {
        setError('All fields must be filled');
        setIsSubmitting(false);
        return;
      }
      if(employeeFields.password !== employeeFields.confirmpassword){
        setError("Sorry, but the Passwords must match");
        setIsSubmitting(false);
        return;
      }
      // console.log('Adding Employee:', employeeFields);
      const requestBody = {
        firstname:employeeFields.firstname,
        lastname: employeeFields.lastname,
        Email:employeeFields.email,
        selectedRole:'staff',
        password:employeeFields.password,
        permissions:employeeFields.permissions,          
      };
      // console.log(requestBody);
      const response = await extraPersonnel(user,requestBody);
      setSelectedRole(false);
      setResponse(response.message);
      setSuccess('New Staff User has been Added. He/She has also been alerted via Email, with necessary credentials')
      toast.success('New Staff User has been Added.');
    } catch (error) {
      if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        navigate('/login');
      } 
    } finally {
      setIsSubmitting(false);
    }
  }; 

  const handleFieldChange = (field, value) => {
    setEmployeeFields((prevFields) => ({
      ...prevFields, [field]: value
    }));
  };

  const handleBackClick = () => {
    setIsAddingUser(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedUser(null);
    setSelectedPermission('');
  };

  const handleDeactivateUser = async (selectedUser) => {
    try {
      setLoading(true);
      // console.log(selectedUser);
      
      let newStatus;
  
      // eslint-disable-next-line camelcase
      const { activestatus } = selectedUser;
  
      if (activestatus === 'active') {
        newStatus = 'Deactivated';
      } else {
          newStatus = 'active';
      }
  
      const response = await updateUserStatus(user,selectedUser,newStatus);
      toast.success(`User has been ${newStatus === 'active' ? 'Activated' : 'Deactivated'} updated successfully.`)
      // console.log(response);
    } catch (error) {
        if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          navigate('/login');
        }else{
          toast.error('Failed to update permission');
          setError(error.message);
          setLoading(true);
        }
    } finally {
        setLoading(false);
        fetchDetails();
    };
  };

  const handleUpdatePermission = async () => {
    try {
      setLoading(true);  
      const response = await updateUserPermission(user,selectedUser,selectedPermission);
      toast.success(`User Permission has been updated successfully.`)
      // console.log(response);
    } catch (error) {
        if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          navigate('/login');
        } else {
          toast.error('Failed to update permission');
          setError(error.message);
          setLoading(true);
        }
    } finally {
        setLoading(false);
        handleCloseModal();
        fetchDetails();
    };
  };

  return (
    <Container>
      {/* {loading && <CircularProgress />} */}
      {loading && <ProcessingComponent message={"Fetching Details..."} />}
      {error && <Typography color="error">{error}</Typography>}
      {!loading && !error && (
        <>
           {isAddingUser ? (
              <Container>
                {!success && (
                  <>
                    <Typography variant="h6">Add New User</Typography>
                    <IconButton onClick={handleBackClick} variant="contained" sx={{ mt: 2, ml: 2 }}>
                      <ArrowBackIcon />
                    </IconButton>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                      <TextField
                        label="First Name"
                        variant="outlined"
                        value={employeeFields.firstname}
                        onChange={(e) => handleFieldChange('firstname', e.target.value)}
                        sx={{ marginBottom: 2 }}
                      />
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        value={employeeFields.lastname}
                        onChange={(e) => handleFieldChange('lastname', e.target.value)}
                        sx={{ marginBottom: 2 }}
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        type="email"
                        value={employeeFields.email}
                        onChange={(e) => handleFieldChange('email', e.target.value)}
                        sx={{ marginBottom: 2 }}
                      />
                      <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={employeeFields.password}
                        onChange={(e) => handleFieldChange('password', e.target.value)}
                        sx={{ marginBottom: 2 }}
                      />
                      <TextField
                        label="Confirm Password"
                        variant="outlined"
                        type="password"
                        value={employeeFields.confirmpassword}
                        onChange={(e) => handleFieldChange('confirmpassword', e.target.value)}
                        sx={{ marginBottom: 2 }}
                      />
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Choose permissions for this user.</InputLabel>
                        <Select
                          value={employeeFields.permissions}
                          onChange={(e) => handleFieldChange('permissions', e.target.value)}
                          label="Choose permissions for this user."
                        >
                          <MenuItem value={0}>Receive money in favor of the business</MenuItem>
                          <MenuItem value={1}>Receive and make only phone payouts</MenuItem>
                          <MenuItem value={2}>Receive and make both phone and bank payouts</MenuItem>
                        </Select>
                      </FormControl>
                      {!isUploading && (
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={isSubmitting || !employeeFields.lastname || !employeeFields.firstname || !employeeFields.password || !employeeFields.confirmpassword || !employeeFields.email}
                          onClick={handleAddUser}
                          sx={{ display: 'block', margin: '0 auto', width: '200px', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                        >
                          <span>{isSubmitting ? 'Adding User' : 'Add User'}</span>
                        </Button>
                      )}
                      {error && (
                        <Container>
                          <Typography variant="body2" color="error" gutterBottom>
                            {error}
                          </Typography>
                        </Container>
                      )}
                    </FormControl>     
                    </>        
                )}
                {success && 
                  <Typography variant="body1" style={{ color:"green", marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                    {success}
                  </Typography>
                } 
              </Container>
           ) : (
            <>
              <Container>
              <Typography
                    variant="body2"
                    align="left"
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                    sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                    marginTop="10px"
                >
                    Below is a list of all users in your business. You can deactivate or activate them, update their permissions or add a new user.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    align="left"
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                    onClick={() => setIsAddingUser(true)} // Assuming this sets the state to add a new user
                  >
                    Add Another User
                  </Button>
                </Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Balance</TableCell>
                        <TableCell>Permissions</TableCell>
                        {/* <TableCell>Date Joined</TableCell> */}
                        <TableCell>Actions</TableCell>
                        <TableCell>Update Permissions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                        <TableRow key={user.email}>
                          <TableCell>{user.firstname}</TableCell>
                          <TableCell>{user.lastname}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{user.activestatus === 'active' ? 'Active':'Deactivated'}</TableCell>
                          <TableCell>{user.balance}</TableCell>
                          <TableCell>
                            {(() => {
                                switch (parseInt(user.permissions,10)) {
                                    case 0:
                                        return 'Only Payins';
                                    case 1:
                                        return 'Payins and Phone Payouts';
                                    case 2:
                                        return 'Payins and Both Payouts';
                                    default:
                                        return 'Unknown Permissions'; // Optional: handle any unexpected values
                                }
                            })()}
                        </TableCell>
                          {/* <TableCell>{user.datejoined}</TableCell> */}
                          <TableCell>
                            <LoadingButton onClick={() => handleDeactivateUser(user)} variant="contained" color={user.activestatus === 'active' ? 'error':'primary'}>
                              {user.activestatus === 'active' ? 'Deactivate':'Activate'}
                            </LoadingButton>
                          </TableCell>
                          <TableCell>
                            <Button onClick={() => handleOpenModal(user)} variant="contained" color="primary">
                              Update
                            </Button>     
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Container>
            </>
           )}
          <Modal open={open} onClose={handleCloseModal}>
            <Box sx={{ width: 400, padding: 3, margin: 'auto', marginTop: '10%', backgroundColor: 'white', borderRadius: 1 }}>
              <Typography variant="h6">Update User Permission</Typography>
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Permission</InputLabel>
                <Select
                  value={selectedPermission}
                  onChange={(e) => setSelectedPermission(e.target.value)}
                  label="Permissions"
                >
                  <MenuItem value="0">Receive money in favor of the business</MenuItem>
                  <MenuItem value="1">Receive and make only phone payouts</MenuItem>
                  <MenuItem value="2">Receive and make both phone and bank payouts</MenuItem>
                </Select>
              </FormControl>
              <LoadingButton 
                disabled={loading}
                variant="contained" 
                color="primary" 
                onClick={handleUpdatePermission} sx={{ marginTop: 2 }}
              >
                Submit
              </LoadingButton>
            </Box>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default BusinessUsers;