import React, { useState } from 'react';
import { useNavigate, Navigate,Link as RouterLink } from 'react-router-dom';
import { Container, Typography, TextField, Button, Link, Box,Checkbox, FormControl, FormControlLabel, RadioGroup, Radio,MenuItem,InputLabel,Select,Card,CardContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { toast } from 'react-toastify'; // Import toast library
import QRCode from 'qrcode.react'; // Import QRCode library
import Page from '../components/Page';
import { useUserContext } from '../UserContext';
import { Kickstartercreation } from '../apis/Kickstarterapis'; // Import Kickstarter creation function

export default function KickStarter() {
  const [formData, setFormData] = useState({
    productname: '',
    productDescription: '',
    pitchvideo:'',
    prototypevideo:'',
    whatsappnumber:'',
    termsAccepted: false
  });

  // const [copySuccess, setCopySuccess] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [phoneFormatError, setPhoneFormatError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState(null); // State to hold response data
  const { user } = useUserContext();
  
  // // Redirect to login if user is not logged in
  if (!user) {
    return <Navigate to="/login" />;
  }
  const formatPhone = (phone) => {
    phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    }else if (phone.startsWith('11') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
  };

const validateAndFormatPhoneNumber = (phone) => {
    if (/^\254\d{8}$/.test(phone)) {
        return phone;
    } 
    if (/^07\d{8}$/.test(phone)) {
        return phone;
    } 
    if (/^01\d{8}$/.test(phone)) {
        return phone;
    }
    setPhoneFormatError('Phone number must start with +254, 07, or 011 and have the specified number of digits.');
    return false;
};    
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      // console.log(formData);
      const{ whatsappnumber, ...restFormData } = formData;
      const testedPhone = validateAndFormatPhoneNumber(whatsappnumber);
      if (!testedPhone) {
          return;
      }
      const formattedPhoneNumber = formatPhone(testedPhone);
      const responseData = await Kickstartercreation(user, { ...formData, whatsappnumber: formattedPhoneNumber});
      setResponse(responseData); // Store response data
    } catch (error) {
      toast.error('Failed to create Kickstarter');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'termsAccepted' ? checked : value
    }));
  };

  const handleNextStep = () => {
    if (currentStep === 1) {
      // Check if role is selected
      if (!formData.productname) {
        toast.error('Please enter your product name');
        return;
      }
    } else if (currentStep === 2) {
      // Check if productname and productname description are provided
      if (!formData.productDescription) {
        toast.error('Please provide the productname description');
        return;
      }
    } else if (currentStep === 3) {
      // Check if amount is provided
      if (!formData.pitchvideo) {
        toast.error('Please provide a video of your pitch');
        return;
      }
    } else if (currentStep === 4) {
      // Check if amount is provided
      if (!formData.prototypevideo) {
        toast.error('Please provide a video of your prototype');
        return;
      }
    } else if (currentStep === 5) {
      // Check if amount is provided
      if (!formData.whatsappnumber) {
        toast.error('Please provide your Whatsapp Number to get a copy of people who have contributed.');
        return;
      }
    } else if (currentStep === 6) {
      // Check if terms are accepted
      if (!formData.termsAccepted) {
        toast.error('Please accept the terms and conditions');
        return;
      }
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };
  

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleCopyLink = () => {
    const inviteLink = `${response.url}`;
    navigator.clipboard.writeText(inviteLink);
    toast.success('Link copied to clipboard');
  };

  return (
    <Page title="Rotsi Kickstarter Creation">
      {response ? (
        <Card variant="outlined" sx={{ maxWidth: 400, margin: '0 auto' }}>
          <CardContent>
          <Typography variant="h4" gutterBottom sx={{ marginBottom: 8, color: 'black' }}>
            Rotsi Kickstarter Services
          </Typography>
          <div>
            <Typography variant="h6" gutterBottom sx={{ color: 'green', fontWeight: 'bold' }}>
              Kickstarter Created Successfully
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold' }}>
              Here is your invite link: 
            </Typography>
            <Box sx={{ width: '200px', height: '200px', margin: '20px auto' }}>
              <QRCode value={response.url} />
            </Box>
            <IconButton onClick={handleCopyLink} aria-label="Copy link">
              <FileCopyIcon />
            </IconButton>
          </div>
          <Link variant="subtitle2" component={RouterLink} to="/dashboard" sx={{ color: 'green', fontWeight: 'bold' , mt: 2 }}>
            Go back to dashboard
          </Link>
         </CardContent>
        </Card>
      ) : (
        <Container>
          <Typography variant="h4" gutterBottom sx={{ marginBottom: 8, color: 'black' }}>
            Kickstarter Services
          </Typography>

          {currentStep >= 1 && currentStep <= 6 && (
            <form onSubmit={handleFormSubmit}>
              <FormControl component="fieldset" fullWidth>
                {currentStep === 1 && (
                  <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <TextField
                      label="Item"
                      variant="outlined"
                      value={formData.productname}
                      onChange={handleChange}
                      name="productname"
                    />
                  </FormControl>  
                )}

                {currentStep > 1 && currentStep <= 6 && (
                  <>
                    {currentStep === 2 && (
                      <>
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <TextField
                          label="Item Description"
                          variant="outlined"
                          value={formData.productDescription}
                          onChange={handleChange}
                          name="productDescription"
                        />
                      </FormControl>
                      </>
                    )}
                    {currentStep === 3 && (
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                       <TextField
                         label="Pitch Video"
                         variant="outlined"
                         value={formData.pitchvideo}
                         onChange={handleChange}
                         name="pitchvideo"
                         type='url'
                       />
                     </FormControl>
                    )}
                    {currentStep === 4 && (
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                       <TextField
                         label="Prototype Video"
                         variant="outlined"
                         value={formData.prototypevideo}
                         onChange={handleChange}
                         name="prototypevideo"
                         type='url'
                       />
                     </FormControl>
                    )}
                    {currentStep === 5 && (
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                       <TextField
                         label="Whatsapp Number"
                         variant="outlined"
                         value={formData.whatsappnumber}
                         onChange={handleChange}
                         name="whatsappnumber"
                       />
                        {phoneFormatError && (
                          <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
                            {phoneFormatError}
                          </Typography>
                        )}
                      </FormControl>
                    )}
                    {currentStep === 6 && (
                      <FormControlLabel
                        control={<Checkbox checked={formData.termsAccepted} onChange={handleChange} name="termsAccepted" />}
                        label="I accept the terms and conditions"
                      />
                    )}
                  </>
                )}
              </FormControl>
              <div sx={{ marginTop: 5 }}>
                {currentStep > 1 && (
                  <Button variant="contained" onClick={handlePreviousStep} sx={{ marginRight: 1 }}> {/* Add right margin */}
                    Back
                  </Button>
                )}
                {currentStep < 6 && (
                  <Button variant="contained" onClick={handleNextStep} sx={{ marginRight: 1 }}> {/* Add right margin */}
                    Next
                  </Button>
                )}
                {currentStep === 6 && (
                  <Button variant="contained" type="submit" disabled={!formData.termsAccepted || isSubmitting} sx={{ marginRight: 1 }}> {/* Add right margin */}
                    Submit
                  </Button>
                )}
              </div>
            </form>
          )}
        </Container>
      )}
    </Page>
  );
}