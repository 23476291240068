import React, { useEffect, useState } from 'react';
import { useNavigate,Navigate,Redirect } from 'react-router-dom';
import { Container, Typography, TextField, Button, IconButton,Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, CardContent } from '@mui/material';
import { Edit, Delete,ArrowBack } from '@mui/icons-material';
import { toast } from 'react-toastify'; // Moved the import here
import { ThreeDots, Puff } from 'react-loader-spinner';
// import { ThreeDots } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';

import { useUserContext } from '../../../../UserContext';
import Login from '../../../Login';
import { checkbalance } from '../../../../apis/gettransactions';
import { bulkb2cpayments } from '../../../../apis/bulkpaymentsapis';

const ProcessingComponent = () => (
  // View when processing
  <Container style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Ensures the container takes the full height of the viewport
  }}>
    <div style={{ textAlign: 'center' }}>
      <Puff visible height="80"width="80" color="#306cce" radius="9" ariaLabel="puff-loading" wrapperStyle={{}} wrapperClass=""/>
      {/* <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/> */}
      <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Processing Payment...
      </Typography>
    </div>
  </Container>
);  

const ErrorComponent = ({ paymentError, handlePaymentErrorButtonClick }) => (
  <div style={{ textAlign: 'center' }}>
    <IoMdAlert size={24} color="red" />
    <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>An error occurred!</Typography>
    <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>{paymentError}</Typography>
    <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Try Again
    </Button>
  </div>
);

const SuccessComponent = ({ response, handleSuccessButtonClick }) => (
  <div style={{ textAlign: 'center' }}>
    <Checkmark size='96px' color='green' />
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Comprehensive report of the Bulk B2C Payment : Only successful disbursements amounts have been deducted from your Account :
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Message: {response.Message}
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Service: {response.Service}
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Total Mpesa Transaction Cost Incurred: {response.TotalTransactionCost} KES
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Total Amount Transaction-cost Inclusive: {response.TotalAmountTransactionCostInclusive} KES
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Successful disbursements : {response.BulkRequests.successful.length}
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Unsuccessful disbursements : {response.BulkRequests.unsuccessful.length || 'none'}
    </Typography>
    {response.BulkRequests.unsuccessful.map((request, index) => (
      <div key={index}>
        <Typography variant="body1" gutterBottom sx={{ color: 'red', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Unsuccessful Disbursement: Phone - {request.phone}, Status - {request.status}, Error - {request.error || request.message}
        </Typography>
      </div>
    ))}
    <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Return to Dashboard
    </Button>
  </div>
);

export default function ManualEntryForm({ payments, setPayments, handleDelete,handleBack }) {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [paymentAmount, setPaymentAmount] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');

  
  const [phoneFormatError, setPhoneFormatError] = useState('');

  const [paymentError, setPaymentError] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [emptyfields, setEmptyfields] = useState(false);
  const [response, setResponse] = useState(null);
  const [balance, setBalance] = useState(user ? user.balance : '');
  const [transactionCost, setTransactionCost] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(false);
  let totalTransactionAmount = 0;
  let totalTransactionCost = 0;

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   fetchBalance(user, setBalance);
  // }, [user]);  
  
  useEffect(() => {
    if (user) {
      fetchBalance(user, setBalance);
    }
  // }, [user]);  
  }, []);  

  if (!user) {
    <Login />;
  }
  if (!user) {
    return <Navigate to="/login" />; // Use Navigate to redirect to the login page
  }

  const fetchBalance = async (user, setBalance) => {
    try {
      const data = await checkbalance(user);
      const bal = data.Account_Bal;
      setBalance(bal); // Set balance state
    } catch (error) {
      console.error(`Error fetching transactions: ${error}`);
    }
  };

  const calculateTransactionCharge = (deductedAmount) => {
    // Your existing table data
    const transactionChargeTable = [
        { min: 1, max: 49, registered: 0, unregistered: 8 },
        { min: 50, max: 100, registered: 0, unregistered: 8 },
        { min: 101, max: 500, registered: 5, unregistered: 8 },
        { min: 501, max: 1000, registered: 5, unregistered: 14 },
        { min: 1001, max: 1500, registered: 5, unregistered: 14 },
        { min: 1501, max: 2500, registered: 9, unregistered: 18 },
        { min: 2501, max: 3500, registered: 9, unregistered: 25 },
        { min: 3501, max: 5000, registered: 9, unregistered: 30 },
        { min: 5001, max: 7500, registered: 11, unregistered: 37 },
        { min: 7501, max: 10000, registered: 11, unregistered: 46 },
        { min: 10001, max: 15000, registered: 11, unregistered: 62 },
        { min: 15001, max: 20000, registered: 11, unregistered: 67 },
        { min: 20001, max: 25000, registered: 13, unregistered: 73 },
        { min: 25001, max: 30000, registered: 13, unregistered: 73 },
        { min: 30001, max: 35000, registered: 13, unregistered: 73 },
        { min: 35001, max: 40000, registered: 13, unregistered: null },
        { min: 40001, max: 45000, registered: 13, unregistered: null },
        { min: 45001, max: 50000, registered: 13, unregistered: null },
        { min: 50001, max: 70000, registered: 13, unregistered: null },
        { min: 70001, max: 250000, registered: 13, unregistered: null },
    ];
    

    const foundRow = transactionChargeTable.find((row) => deductedAmount >= row.min && deductedAmount <= row.max);
    return foundRow ? foundRow.registered : null;
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    setPaymentAmount(value);
    // Clear phoneError when the user starts typing again
    setError('');
  };
  
  const handleSuccessButtonClick = () => {
    setSuccess(false); // Set success state back to false
    navigate('/business-dashboard/app', { replace: true }); // Navigate to the dashboard
  };

  const clearPhoneErrors = () => {
    setPhoneFormatError('');
  };

  const showToast = (message) => {
    toast.success(message, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  const formatPhoneNumber = (phone) => {
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
  };

  const handleAddPayment = () => {
    // Clear phone number errors
    clearPhoneErrors();

    // Check if all fields are filled in
    if (!paymentAmount || !phonenumber) {
      setEmptyfields('Please fill in all fields');
      return;
    }

    // Validate phone number format
    const testedPhoneNumber = formatPhoneNumber(phonenumber);
    if (!testedPhoneNumber) {
      setPhoneFormatError('Invalid phone number format');
      return;
    }

    const paymentInt = parseInt(paymentAmount,10);

    const transactionCharge = calculateTransactionCharge(paymentInt); 

    totalTransactionCost += transactionCharge;

    const transactionAmount = paymentInt + transactionCharge;

    totalTransactionAmount += transactionAmount;

    // Add payment to payments array
    const updatedPayments = [...payments, { amount: paymentInt, phone: testedPhoneNumber }];
    setPayments(updatedPayments);
    
    setTransactionAmount(totalTransactionAmount);
    setTransactionCost(totalTransactionCost);

    // Show success toast
    showToast('Payment Object added successfully');

    // Clear input fields
    setPaymentAmount('');
    setPhoneNumber('');
  };

  const handleFormSubmit = async () => {
    setLoading(true);

    try {
      // Perform bulk SMS operation using payments array
      // console.log('Payments:', payments);
      // Calculate the sum of amounts in the payments array
      const totalAmount = payments.reduce((acc, curr) => acc + curr.amount, 0);
      // console.log('Total Amount:', totalAmount);

      const minimumBalance = parseInt(process.env.REACT_APP_MINIMUM_AMOUNT, 10);

      const balanceInt = parseInt(balance,10);
      // console.log(balanceInt)

      // Calculate the minimum allowed balance after withdrawal
      const minimumAllowedBalance = balanceInt - minimumBalance;
      // console.log(minimumAllowedBalance);

      if (minimumBalance > balanceInt) {
        setError(`Kindly add more funds to the Rotsi wallet, to be able to make a Bulk disbursement from the account. Minimum Account Balance is ${minimumBalance} KES`);
        toast.error(`A minimum of ${minimumBalance} KES is required to maintain your account. Kindly add more funds to your wallet.`);
        setLoading(false);
        return;
      }   

      if (totalAmount > minimumAllowedBalance) {
        setError(`Kindly add more funds to the Rotsi wallet, to be able to send SMS(s) from the platform. Minimum Account Balance is ${minimumBalance} KES. Total amount for your messages is : ${totalAmount}`);
        toast.error(`A minimum of ${minimumBalance} is required to maintain your account. You can withdraw up to ${minimumAllowedBalance} KES`);
        setLoading(false);
        return;
      }  

      // Check if any amount inside the payments array is less than 10
      const hasAmountLessThanTen = payments.some((payment) => payment.amount < 10);
      if (hasAmountLessThanTen) {
        setError('Minimum amount allowed to disburse is KES. 10');
        toast.error('Minimum amount allowed to disburse is KES. 10');
        setLoading(false);
        return;
      }
      const responseData = await bulkb2cpayments(user, payments);
      setSuccess(true);
      setResponse(responseData);
    } catch (error) {
      // Handle error
      // toast.error('Bulk disbursement unavailable. Please try again later.', error);
      setError(true);
      setPaymentError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && !paymentError && !success && <ProcessingComponent />}
      {paymentError && !success && !loading && <ErrorComponent paymentError={paymentError} handlePaymentErrorButtonClick={() => setPaymentError('')} />}
      {success && !paymentError && !loading && <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick} />}

      {!loading && !paymentError && !success && (
        <Container>
          <IconButton onClick={handleBack} sx={{ marginBottom: 2 }}>
            <ArrowBack />
          </IconButton>
          
          <Typography variant="h6" gutterBottom sx={{ marginTop: 2 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Manual Entry
          </Typography>
          <TextField
            label="Amount"
            variant="outlined"
            fullWidth
            type='number'
            value={paymentAmount}
            onChange={handleAmountChange}
            sx={{ marginBottom: 2 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            type='number'
            value={phonenumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            onFocus={clearPhoneErrors}
            error={!!phoneFormatError}
            helperText={phoneFormatError}
            sx={{ marginBottom: 2 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          />
          <Button variant="contained" onClick={handleAddPayment} sx={{ marginRight: 2 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Add Payment
          </Button>
          {emptyfields && (
            <Container> 
              <Typography variant="body2" color="error" gutterBottom>
                {emptyfields}
              </Typography>
            </Container>
          )}
          <TableContainer sx={{ marginTop: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment, index) => (
                  <TableRow key={index}>
                    <TableCell>{payment.amount}</TableCell>
                    <TableCell>{payment.phone}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(index)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {transactionCost !== false && transactionAmount !== false && (
            <Container sx={{ marginTop: 2 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Bulk Payment Details : 
                </Typography>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Total Transaction Amount: {transactionAmount} KES
                </Typography>
              </Container>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Transaction Cost: {transactionCost} KES
                </Typography>
              </Container>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Number of Payments Added: {payments.length}
                </Typography>
              </Container>
            </Container>
          )}
          {error && (
            <Container> 
              <Typography variant="body2" color="error" gutterBottom>
                {error}
              </Typography>
            </Container>
          )}
          <Button variant="contained" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }} onClick={handleFormSubmit} disabled={loading}>
            Send Bulk Payment
          </Button>
        </Container>
      )}
    </>
  );
}