import { useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Button, Popover, Paper } from '@mui/material'; // Import Popover and related components
import { fShortenNumber } from '../../../utils/formatNumber';
import Iconify from '../../../components/Iconify';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

AppWidgetSummaryDeposit.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  popoverContent: PropTypes.node.isRequired, // New prop for popover content
  buttonText: PropTypes.string, // New prop for the button text
  sx: PropTypes.object,
};

export default function AppWidgetSummaryDeposit({ title, total, icon, color = 'primary', popoverContent,buttonText, sx, ...other }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState('');

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
    setSelectedMethod(''); // Clear the selected deposit method
  };
  

  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </IconWrapperStyle>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>

      <Typography variant="h3">{fShortenNumber(total)}</Typography>

      {buttonText && ( // Render button if buttonLink and buttonText are provided
        <Button variant="contained" color="primary" onClick={handlePopoverOpen}>
          {buttonText}
        </Button>
      )}
  
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Paper sx={{ padding: 2 }}>
          {popoverContent}
        </Paper>
      </Popover>
    </Card>
  );
}