import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate,Link as RouterLink } from 'react-router-dom';
import { Container, Typography, TextField, Button, IconButton, FormControl, InputAdornment,Card,CardContent,Link,Select,MenuItem,FormHelperText } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify'; // Import toast library
import { ThreeDots } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';
import Page from '../../components/Page';
import { useUserContext } from '../../UserContext';
import { checkbalance } from '../../apis/gettransactions';
import { b2cPush } from '../../apis/paymentapis'; // Import b2cPush function
import Login from '../Login';

const formatPhone = (phone) => {
  phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    }else if (phone.startsWith('11') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
};

const calculateTransactionCharge = (deductedAmount) => {
  // Your existing table data
  const transactionChargeTable = [
      { min: 1, max: 49, registered: 0, unregistered: 8 },
      { min: 50, max: 100, registered: 0, unregistered: 8 },
      { min: 101, max: 500, registered: 5, unregistered: 8 },
      { min: 501, max: 1000, registered: 5, unregistered: 14 },
      { min: 1001, max: 1500, registered: 5, unregistered: 14 },
      { min: 1501, max: 2500, registered: 9, unregistered: 18 },
      { min: 2501, max: 3500, registered: 9, unregistered: 25 },
      { min: 3501, max: 5000, registered: 9, unregistered: 30 },
      { min: 5001, max: 7500, registered: 11, unregistered: 37 },
      { min: 7501, max: 10000, registered: 11, unregistered: 46 },
      { min: 10001, max: 15000, registered: 11, unregistered: 62 },
      { min: 15001, max: 20000, registered: 11, unregistered: 67 },
      { min: 20001, max: 25000, registered: 13, unregistered: 73 },
      { min: 25001, max: 30000, registered: 13, unregistered: 73 },
      { min: 30001, max: 35000, registered: 13, unregistered: 73 },
      { min: 35001, max: 40000, registered: 13, unregistered: null },
      { min: 40001, max: 45000, registered: 13, unregistered: null },
      { min: 45001, max: 50000, registered: 13, unregistered: null },
      { min: 50001, max: 70000, registered: 13, unregistered: null },
      { min: 70001, max: 250000, registered: 13, unregistered: null },
  ];
  

  const foundRow = transactionChargeTable.find((row) => deductedAmount >= row.min && deductedAmount <= row.max);
  return foundRow ? foundRow.registered : null;
};

export default function B2CForm() {
  const { user, setNewBalance } = useUserContext();
  const navigate = useNavigate();
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [amount, setWithdrawAmount] = useState('');
  const [phone, setPhoneNumber] = useState(user ? user.phonenumber : '');
  const [balance, setBalance] = useState(user ? user.balance : '');
  const [paymentError, setPaymentError] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [amountError, setAmountError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [response, setResponse] = useState(null); // State to hold response data
  const [transactionCost, setTransactionCost] = useState(false);
  const [paymentreason, setPaymentReason] = useState('');
  // let totalTransactionCost = 0;

  useEffect(() => {
    if (user) {
      fetchBalance(user, setBalance);
    }
  // }, [user]);  
  }, []);  

  if (!user) {
    <Login />;
  }
  if (!user) {
    return <Navigate to="/login" />; // Use Navigate to redirect to the login page
  }

  const fetchBalance = async (user, setBalance) => {
    try {
      const data = await checkbalance(user);
      const bal = data.Account_Bal;
      setBalance(bal); // Set balance state
    } catch (error) {
      console.error(`Error fetching transactions: ${error}`);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
    // Clear phoneError when the user starts typing again
    setPhoneError('');
  };

  const handleSuccessButtonClick = () => {
    setSuccess(false); // Set success state back to false
    navigate('/dashboard/app', { replace: true });
  };

  const ErrorComponent = ({ handlePaymentErrorButtonClick }) => (
    <div style={{ textAlign: 'center' }}>
      <IoMdAlert size={24} color="red" />
      <Typography variant="body1" color="error" sx={{ marginTop: 10,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>An error occurred!</Typography>
      <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 }}>
        Try Again
      </Button>
    </div>
  );

  const ProcessingComponent = () => (
    // View when processing
    <Container style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // Ensures the container takes the full height of the viewport
    }}>
      <div style={{ textAlign: 'center' }}>
        <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
        <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Processing Payment...
        </Typography>
      </div>
    </Container>
  );  

  const SuccessComponent = ({ response, handleSuccessButtonClick,navigate }) => (
    <div style={{ textAlign: 'center' }}>
      <Checkmark size='96px' color='green' />
      <Typography variant="body1" style={{ marginTop: 10,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>Deposit successful!</Typography>
      <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Transaction Id: {response.RotsiAPITransactionId}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Service: {response.Service}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Status: {response.status}
      </Typography>
      <Container>
        <Button onClick={() => navigate(`/download-receipt/${response.RotsiAPITransactionId}`)} variant="contained" style={{ marginTop: 20, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Generate Receipt
        </Button>
        <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Return to Dashboard
        </Button>
      </Container>
    </div>
  );  

  const handleAmountChange = (e) => {
    setTransactionCost(false); 
    const { value } = e.target;
    setWithdrawAmount(value);
    const amountInt = parseInt(value, 10);
    const transactionCharge = calculateTransactionCharge(amountInt);
    setTransactionCost(transactionCharge);
    // Clear phoneError when the user starts typing again
    setError('');
  };

  const handlePaymentReasonChange = (e) => {
    setPaymentReason(e.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formattedPhoneNumber = formatPhone(phone);
    //  console.log(formattedPhoneNumber);
  
    // Validate phone number
    if (formattedPhoneNumber === null) {
      setPhoneError('Phone number must start with 254 and have a total of 12 digits.');
      setIsSubmitting(false);
      return;
    }
    const minimumBalance = parseInt(process.env.REACT_APP_MINIMUM_AMOUNT, 10);
    const amountInt = parseInt(amount, 10);
    const amountWithoutDecimals = Math.trunc(amountInt);
    const balanceInt = parseInt(balance,10);
    const transactionChargeInt = parseInt(transactionCost,10);
    // console.log(balanceInt)
    // Calculate the minimum allowed balance after withdrawal
    const minimumAllowedBalance = balanceInt - minimumBalance;
    // console.log(minimumAllowedBalance);
    const totalAmount = transactionChargeInt + amountInt;

    if (minimumBalance > balanceInt) {
      setError(`Kindly add more funds to the Rotsi wallet, to be able to make a Withdrawal from the account. Minimum Account Balance is ${minimumBalance} KES`);
      toast.error(`A minimum of ${minimumBalance} KES is required to maintain your account. Kindly add more funds to your wallet.`);
      setIsSubmitting(false);
      return;
    }   

    if (amountInt < 10) {
      setError(`Minimum withdrawal amount is KES. 10`);
      toast.error(`Minimum withdrawal amount is KES. 10`);
      setIsSubmitting(false);
      return;
    }   

    if (amountInt > minimumAllowedBalance) {
      setError(`Withdrawal amount exceeds the allowed balance. You can withdraw up to ${minimumAllowedBalance} KES`);
      toast.error(`A minimum of ${minimumBalance} is required to maintain your account. You can withdraw up to ${minimumAllowedBalance} KES`);
      setIsSubmitting(false);
      return;
    }   

    if (amountInt > minimumAllowedBalance) {
      setError(`Withdrawal amount exceeds the allowed balance. You can withdraw up to ${minimumAllowedBalance} KES`);
      toast.error(`A minimum of ${minimumBalance} is required to maintain your account. You can withdraw up to ${minimumAllowedBalance} KES`);
      setIsSubmitting(false);
      return;
    }   

    if (totalAmount > minimumAllowedBalance) {
      setError(`Transaction Declined due to insufficient funds required for the Mpesa Transaction charge ${transactionChargeInt}. Total amount after transaction cost is ${totalAmount}, with your maximum withdrawal amount being ${minimumAllowedBalance} KES`);
      toast.error(`Transaction Declined due to insufficient funds required for the Mpesa Transaction charge`);
      setIsSubmitting(false);
      return;
    }   

    if (!paymentreason || paymentreason === '') {
      setError('Please select a payment reason.');
      setIsSubmitting(false);
      return;
    }

    try {
      // Prepare the request body
      const requestBody = {
        phone:formattedPhoneNumber,
        amount: amountWithoutDecimals,
        paymentreason
      };

      // console.log('Request Body:', requestBody);

      // Perform the withdrawal operation
      const responseData = await b2cPush(user, requestBody);
      setSuccess(true);
      setResponse(responseData);
      // Display success message and navigate to login page
      toast.success('Rotsi to Mpesa Withdrawal successful!');
      // navigate('/login');
    } catch (error) {
      if(error.message){
        setError(true);
        setPaymentError(error.message);
      }else{
        setError(true);
        setPaymentError('Withdrawal failed. Please try again later.');
      }
    } finally {
      setIsSubmitting(false);
      setIsEditing(false);
    }
  };

  return (
    <Page title="Rotsi to Mpesa phone Withdrawals">
      <Container>
      {isSubmitting && <ProcessingComponent />}
      {paymentError && <ErrorComponent handlePaymentErrorButtonClick={() => setPaymentError('')} />}
      {success && <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick} navigate={navigate} />}
      {!isSubmitting && !success && !paymentError && (
        <div>
        <Typography variant="h4" gutterBottom sx={{ marginBottom: 8, color: 'black',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Easily withdraw money from your Rotsi Wallet, straight to a phone Number (Mpesa or Non-Mpesa).
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Withdraw from Account
        </Typography>
        <form onSubmit={handleFormSubmit}>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="Your Phone Number"
              variant="outlined"
              placeholder="2547XXXXXXXX"
              value={phone}
              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              onChange={handlePhoneChange}
              InputProps={{
                readOnly: !isEditing,
                endAdornment: (
                  <InputAdornment position="end">
                    {!isEditing && (
                      <IconButton onClick={handleEditClick}>
                        <Edit />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          {phoneError && (
            <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
              {phoneError}
            </Typography>
          )}

          {transactionCost !== false && (
            <Container sx={{ marginTop: 2 }}>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="outlined" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Transaction Cost: {transactionCost} KES
                </Typography>
              </Container>
            </Container>
          )}
          <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label="Amount you wish to withdraw"
              variant="outlined"
              value={amount}
              type="number"
              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              onChange={handleAmountChange}
            />
          </FormControl>

          <FormControl fullWidth margin="normal" error={Boolean(error)}>
            <Select
              value={paymentreason}
              onChange={handlePaymentReasonChange}
              displayEmpty
            >
              <MenuItem value="" disabled>Select payment reason</MenuItem>
              <MenuItem value="Service Fee">Service Fee</MenuItem>
              <MenuItem value="Product Purchase">Product Purchase</MenuItem>
              <MenuItem value="Commission Payment">Commission Payment</MenuItem>
              <MenuItem value="Contract Settlement">Contract Settlement</MenuItem>
              <MenuItem value="Royalties Due">Royalties Due</MenuItem>
              <MenuItem value="Refund Issuance">Refund Issuance</MenuItem>
              <MenuItem value="Salary Payment">Salary Payment</MenuItem>
              <MenuItem value="Supplier Payment">Supplier Payment</MenuItem>
              <MenuItem value="Loan Repayment">Loan Repayment</MenuItem>
              <MenuItem value="Utility Bills">Utility Bills</MenuItem>
              <MenuItem value="Rent Payment">Rent Payment</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
          
          {error && (
            <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
              {error}
            </Typography>
          )}
          
          <LoadingButton type="submit" variant="contained" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} disabled={isSubmitting}>
            {isSubmitting ? `Depositing to ${phone}`: 'Withdraw from Account'}
          </LoadingButton>
        </form>
      </div>
      )}
      </Container>
    </Page>
  );
}