import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { format, parseISO, isValid } from 'date-fns';
import { noCase } from 'change-case';
import { faker } from '@faker-js/faker';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
// utils
import { fToNow,formatTimestamp } from '../../utils/formatTime';
// components
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { useUserContext } from '../../UserContext';
import { allStafftransactions } from '../../apis/gettransactions';

// ----------------------------------------------------------------------

const convertToISO = (timestamp) => {
  const year = timestamp.slice(0, 4);
  const month = timestamp.slice(4, 6);
  const day = timestamp.slice(6, 8);
  const hour = timestamp.slice(8, 10);
  const minute = timestamp.slice(10, 12);
  const second = timestamp.slice(12, 14);
  return `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
};

function convertTimestamp(timestamp) {
  const year = timestamp.substring(0, 4);
  const month = timestamp.substring(4, 6);
  const day = timestamp.substring(6, 8);
  const hours = parseInt(timestamp.substring(8, 10), 10);
  const minutes = timestamp.substring(10, 12);
  const seconds = timestamp.substring(12, 14);

  // Determine AM/PM and adjust hour
  const period = hours >= 12 ? 'PM' : 'AM';
  const adjustedHour = hours % 12 || 12; // Convert 0 to 12 for 12 AM

  // Create formatted date and time strings
  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${adjustedHour}:${minutes}:${seconds} ${period}`;

  // return `${formattedDate} ${formattedTime}`;
  return `${formattedTime}`;
}

export default function NotificationsPopover() {
  const { user } = useUserContext();
  const anchorRef = useRef(null);

  const [notifications, setNotifications] = useState([]);

  const [open, setOpen] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await allStafftransactions(user);
        // console.log(data.jsonData);
        setNotifications(data.jsonData);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    }
    fetchData();
  }, [user]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const parseCustomTimestamp = (timestamp) => {
    const year = timestamp.slice(0, 4);
    const month = timestamp.slice(4, 6);
    const day = timestamp.slice(6, 8);
    const hours = timestamp.slice(8, 10);
    const minutes = timestamp.slice(10, 12);
    const seconds = timestamp.slice(12, 14);
  
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  };
  
  const notificationsByDate = notifications.reduce((acc, notification) => {
    try {
      const date = parseCustomTimestamp(notification.timestamp);
      if (!isValid(date)) {
        throw new Error(`Invalid date: ${notification.timestamp}`);
      }
      const formattedDate = format(date, 'yyyy-MM-dd');
      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push({
        ...notification,
        parsedDate: date,  // Store the parsed date for easier sorting later
      });
    } catch (error) {
      console.error('Error parsing date for notification:', error, notification);
    }
    return acc;
  }, {});
  
  const notificationsByDateKeys = Object.keys(notificationsByDate).sort((a, b) => new Date(b) - new Date(a));
  
  // Sort the notifications within each date group by the parsed time in descending order
  notificationsByDateKeys.forEach(dateKey => {
    notificationsByDate[dateKey].sort((a, b) => b.parsedDate - a.parsedDate);
  });

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={notifications.length} color="error">
          {/* <Iconify icon="eva:bell-fill" width={20} height={20} /> */}
          <HistoryIcon fontSize="large" />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Transactions</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {notifications.length} transactions
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: 300 }}> {/* Adjust height as needed */}
          <Box sx={{ maxHeight: 300, overflowY: 'auto' }}> {/* Adjust maxHeight and overflowY */}
            {notificationsByDateKeys.map((date) => (
              <Box key={date}>
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  {format(new Date(date), 'MMMM dd, yyyy')}
                </ListSubheader>
                <List disablePadding>
                  {notificationsByDate[date].map((notification) => (
                    <NotificationItem key={notification.mpesaReceipt} notification={notification} />
                  ))}
                </List>
              </Box>
            ))}
          </Box>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    timestamp: PropTypes.string,
    mpesaReceipt: PropTypes.string,
    recipientname: PropTypes.string,
    amount: PropTypes.string,
    transactiontype: PropTypes.string,
  }),
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton sx={{ py: 1.5, px: 2.5, mt: '1px' }}>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
              {/* {formatTimestamp(notification.timestamp)} */}
              {convertTimestamp(notification.timestamp)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  let title;
  if (notification.transactioncategory === 'Pay-outs') {
    title = (
      <Typography variant="subtitle2">
        You sent {notification.amount} to {notification.recipientname}
      </Typography>
    );
  } else if (notification.transactioncategory === 'Pay-ins') {
    title = (
      <Typography variant="subtitle2">
        {notification.recipientname} sent you {notification.amount}
      </Typography>
    );
  } else {
    title = (
      <Typography variant="subtitle2">
        {notification.recipientname} sent you {notification.amount}
      </Typography>
    );
  }

  if (notification.transactiontype === 'b2c') {
    return {
      avatar: <img alt={notification.recipientname} src="/static/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.transactiontype === 'c2b') {
    return {
      avatar: <img alt={notification.recipientname} src="/static/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.transactiontype === 'paybill') {
    return {
      avatar: <img alt={notification.recipientname} src="/static/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.transactiontype === 'm2b') {
    return {
      avatar: <img alt={notification.recipientname} src="/static/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.recipientname} src={notification.avatar} /> : null,
    title,
  };
}