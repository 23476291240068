import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../UserContext';


// const API = (token) =>
//   axios.create({
//     baseURL: process.env.REACT_APP_SERVER_URL,
//     headers: { Authorization: token },
//   });

const url = process.env.REACT_APP_SERVER_URL;
// console.log("Url :",url);

const PaymentsAPI = (secretKey) =>
  axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': secretKey,
    },
});

  export const bulkb2cPush = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone} = body;
     
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/bulkpayments/b2c/v1`, { username, amount, phone });
      return data;
    } catch (error) {
      toast.error(error.response.data.Message);
      throw error.response.data;
    }
  };

  export const m2bPush = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone,bankcode,accountNumber} = body;
      
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/payments/m2b/v1`, { username, amount, phone, accountNumber, bankcode });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      throw error.response.data;
    }
  };

  export const m2sPush = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone,servicecode,accountNumber} = body;
      
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/payments/m2s/v1`, { username, amount, phone, accountNumber, servicecode });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message) // Display error message from server
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      throw error.response.data;
    }
  };

  
  export const kraPush = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone,accountNumber} = body;
      
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const response = await PaymentsAPIInstance.post(`${url}/payments/kratax/v1`, { username, amount, phone, accountNumber });
      // console.log('Response Data:', response.data);
       
      if (response.data.error) {
        toast.error(response.data.error); // Toast the error message
        return; // Return early as there's an error
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error); // Toast the error message from the server
      } else {
        toast.error('Failed to Remit tax. Please try again later.'); // Generic error message
      }
      throw error.response.data;
    }
  };

  export const bulkb2cpayments = async (user, payments) => {
    try {
      const { secretKey, username } = user;
    
      const payload = {
        username,
        payments,
      };
      
      // console.log(payload);

      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/bulkpayments/b2c/v1`, payload);
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      toast.error(error.response.data.Message);
      throw error.response.data;
    }
  };

  export const bulkpaybillpayments = async (user, payments) => {
    try {
      const { secretKey, username } = user;
    
      const payload = {
        username,
        payments,
      };
      
      // console.log(payload);

      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/bulkpayments/paybill/v1`, payload);
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      toast.error(error.response.data.Message);
      throw error.response.data;
    }
  };

  export const bulkm2bpayments = async (user, payments) => {
    try {
      const { secretKey, username } = user;
    
      const payload = {
        username,
        payments,
      };
      
      // console.log(payload);

      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/bulkpayments/m2b/v1`, payload);
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      toast.error(error.response.data.Message);
      throw error.response.data;
    }
  };

  export const bulkpaybillm2bpayments = async (user, payments) => {
    try {
      const { secretKey, username } = user;
    
      const payload = {
        username,
        payments,
      };
      
      // console.log(payload);

      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/bulkpayments/paybillm2b/v1`, payload);
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      toast.error(error.response.data.Message);
      throw error.response.data;
    }
  };