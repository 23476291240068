import React, { useState, useEffect } from 'react';
import { Link as RouterLink,Navigate } from 'react-router-dom';
import { Table, TableContainer, TableHead, TableRow, TableCell, 
    TableBody, Paper, IconButton, Menu, MenuItem, Typography,
     Box, Link, Button, Popover, TextField } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import noSenderImage from '../assets/Escrow.jpg';
import { getwalletdetails,initiatePayment } from '../apis/escrowapis';
import { useUserContext } from '../UserContext';

const ManageEscrowsPage = () => {
    const { user } = useUserContext();
    const [senderDetails, setSenderDetails] = useState([]);
    const [receiverDetails, setReceiverDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showSenderDetails, setShowSenderDetails] = useState(true); // Initial state to show sender details
    const [isProcessingTransaction, setIsProcessingTransaction] = useState(false); // New state variable
    
    const [phoneFormatError, setPhoneFormatError] = useState('');
    const [phoneLengthError, setPhoneLengthError] = useState('');
    const [editFieldValue, setEditFieldValue] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopover, setOpenPopover] = useState(false);
    const [phonenumber, setPhoneNumber] = useState('');

    useEffect(() => {
        if (!user) {
            return;
        }

        const fetchDetails = async () => {
            try {
                const { senderDetails, receiverDetails } = await getwalletdetails(user);
                setSenderDetails(senderDetails);
                setReceiverDetails(receiverDetails);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching wallet details:', error);
                toast.error('Error fetching wallet details:', error);
                setIsLoading(true);
            }
        };

        fetchDetails();
    }, [user]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleSwitch = () => {
        setShowSenderDetails(!showSenderDetails);
    };
    const formatPhone = (phone) => {
        phone = String(phone);
        let formattedPhone = phone;
        if (phone.startsWith('+254') && phone.length === 13) {
        formattedPhone = `254${phone.substr(4)}`;
        } else if (phone.length === 12 && phone.startsWith('254')) {
        return formattedPhone;
        } else if (phone.startsWith('07') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
        } else if (phone.startsWith('011') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
        } else if (phone.startsWith('0') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
        } else if (phone.startsWith('7') && phone.length === 9) {
        formattedPhone = `254${phone}`;
        }else if (phone.startsWith('11') && phone.length === 9) {
        formattedPhone = `254${phone}`;
        } else {
        formattedPhone = null;
        }
        return formattedPhone;
    };    
   
    const validateAndFormatPhoneNumber = (phone) => {
        if (/^\254\d{8}$/.test(phone)) {
            return phone;
        } 
        if (/^07\d{8}$/.test(phone)) {
            return phone;
        } 
        if (/^01\d{8}$/.test(phone)) {
            return phone;
        }
        setPhoneFormatError('Phone number must start with +254, 07, or 011 and have the specified number of digits.');
        return false;
    };
    
    const handlePayClick = async (damount, uniquecode,companyname) => {
        setIsProcessingTransaction(true); // Start processing transaction
        try {
            const testedPhone = validateAndFormatPhoneNumber(phonenumber);
            if (!testedPhone) {
                return;
            }
            const formattedPhoneNumber = formatPhone(testedPhone);

            const amountWithoutDecimals = Math.trunc(damount);

            const requestBody = {
                phone: formattedPhoneNumber,
                amount: amountWithoutDecimals, // Use the amount without decimals
                uniquecode,
                companyname
            };
            // console.log(requestBody);
            const response = await initiatePayment(requestBody, user);
            // Handle success
        } catch (error) {
            // Handle error
            console.error('Error initiating payment:', error);
        } finally {
            setIsProcessingTransaction(false); // End processing transaction
        }
        setOpenPopover(false);
    };
    
    const handlePopoverClose = () => {
        setOpenPopover(false);
    };

    const handleEditFieldChange = (event) => {
        setEditFieldValue(event.target.value);
    };


    const renderTable = (details) => {
        return (
            <TableContainer component={Paper} sx={{ border: '1px solid #ddd' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Item Name</TableCell>
                            <TableCell>Item Description</TableCell>
                            {details === receiverDetails && <TableCell>Company name</TableCell>}
                            <TableCell>Price</TableCell>
                            {details === senderDetails && <TableCell>Paid amount</TableCell>}
                            {details === senderDetails && <TableCell>Escrow Fee</TableCell>}
                            <TableCell>Unique Code</TableCell>
                            <TableCell>Transaction Status</TableCell>
                            <TableCell>Created At</TableCell>
                            {details.some(row => row.transactionid !== null && row.transactionid !== '') && <TableCell>Transaction ID</TableCell>}
                            {details.some(row => row.mpesareceipt !== null && row.mpesareceipt !== '') && <TableCell>Mpesa Receipt</TableCell>}
                            {details === receiverDetails && <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {details.map((row, index) => (
                            <TableRow key={row.wallet_id} sx={{ border: '1px solid #ddd' }}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.item_name}</TableCell>
                                <TableCell>{row.item_description}</TableCell>
                                {details === receiverDetails && <TableCell>{row.sender_company_name}</TableCell>}
                                <TableCell>{row.amount}</TableCell>
                                {details === senderDetails && <TableCell>{row.balance}</TableCell>}
                                {details === senderDetails && <TableCell>{row.escrow_fee}</TableCell>}
                                <TableCell>{row.unique_code}</TableCell>
                                <TableCell>{row.transaction_status}</TableCell>
                                <TableCell>{row.created_at}</TableCell>
                                {row.transactionid != null && row.transactionid !== '' && <TableCell>{row.transactionid}</TableCell>}
                                {row.mpesareceipt != null && row.mpesareceipt !== '' && <TableCell>{row.mpesareceipt}</TableCell>}
                                {details === receiverDetails && (
                               <TableCell>
                                    <IconButton onClick={handleMenuClick}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => { handleMenuClose(); setOpenPopover(true); }}>Pay</MenuItem>
                                    </Menu>
                                    <Popover
                                        open={openPopover}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        PaperProps={{
                                            sx: {
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                minWidth: '320px', // Adjust width as needed
                                                minHeight: '200px', // Adjust height as needed
                                            }
                                        }}
                                    >
                                        <Paper sx={{ p: 2 }}
                                            PaperProps={{
                                                sx: {
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    minWidth: '320px', // Adjust width as needed
                                                    minHeight: '200px', // Adjust height as needed
                                                }
                                            }}>
                                            <Typography variant="body1">Enter Phone Number:</Typography>
                                            <TextField
                                                label="Phone Number"
                                                value={phonenumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                sx={{ mt: 2 }}
                                            />
                                            <TextField
                                                label="Amount"
                                                value={row.amount} // Use row.amount directly as the value
                                                variant="outlined"
                                                fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                sx={{ mt: 2 }}
                                            />
                                             <LoadingButton
                                                onClick={() => handlePayClick(row.amount, row.unique_code,row.sender_company_name)} 
                                                variant="contained"
                                                loading={isProcessingTransaction} // Use isProcessingTransaction for loading state
                                                disabled={isProcessingTransaction} // Disable button while processing transaction
                                                sx={{ mt: 2 }}
                                            >
                                                Pay
                                            </LoadingButton>
                                        </Paper>
                                    </Popover>
                                </TableCell>                                                      
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };    

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Box textAlign="center">
            <Button onClick={handleSwitch} variant="contained" sx={{ my: 2 }}>
                {!showSenderDetails ? 'Explore Wallets for Goods You\'ve Sent' : 'Discover Wallets for Goods You\'ve Received'}
            </Button>
            {showSenderDetails && senderDetails.length > 0 && renderTable(senderDetails)}
            {!showSenderDetails && receiverDetails.length > 0 && renderTable(receiverDetails)}
            {showSenderDetails && senderDetails.length === 0 && (
                <Box textAlign="center" sx={{ my: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, borderRadius: 4 }}>
                        <Typography variant="subtitle1" sx={{ mt: 2 , textAlign: 'center' }}>
                            Why Join as a Trader?
                            <ol style={{ padding: '20px' }}>
                                <li>Enhance transaction security by ensuring the customer pays first.</li>
                                <li>Enjoy peace of mind with our escrow services.</li>
                                <li>Gain access to a trusted platform for buying and selling.</li>
                            </ol>
                            <Link component={RouterLink} to="/dashboard/escrowcreation">
                                Join as a trader today! 😁
                            </Link>
                        </Typography>
                        <img src={noSenderImage} alt="No Sender Details" style={{ width: 'relative',height:'relative', borderRadius: '10%', }} />
                    </Paper>
                </Box>
            )}
            {!showSenderDetails && receiverDetails.length === 0 && (
                <Box textAlign="center" sx={{ my: 2 }}>
                    <Paper elevation={3} sx={{ p: 2, borderRadius: 4 }}>
                        <Typography variant="subtitle1" sx={{ mt: 2 , textAlign: 'center' }}>
                            Why Secure Your Buying and Selling with Rotsi Escrow Services?
                            <ol style={{ padding: '20px' }}>
                                <li>Become a secure receiver without fear of being conned.</li>
                                <li>Enjoy peace of mind knowing your transactions are protected.</li>
                                <li>Join a trusted platform for secure transactions.</li>
                            </ol>
                            <Link component={RouterLink} to="/dashboard/escrowcreation">
                                Visit us to secure your buying and selling.
                            </Link>
                        </Typography>
                        <img src={noSenderImage} alt="No Receiver Details" style={{ width: 'relative',height:'relative', borderRadius: '10%' }} />
                    </Paper>
                </Box>            
            )}
        </Box>

    );
};

export default ManageEscrowsPage;