import { useState } from 'react';
import { useNavigate, Navigate,Link as RouterLink } from 'react-router-dom';
import { Container, Typography, TextField, Button, IconButton, FormControl, InputAdornment,Card,CardContent,Link } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { toast } from 'react-toastify'; // Import toast library
import Page from '../components/Page';
import { useUserContext } from '../UserContext';
import { m2sPush } from '../apis/paymentapis'; // Import m2sPush function

export default function LightBill() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [accountNumber, setaccountNumber] = useState('');
  const [amount, setWithdrawAmount] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [response, setResponse] = useState(null); // State to hold response data
  // const [accountNumberError, setaccountNumberError] = useState('');
  const { user } = useUserContext();
  // const navigate = useNavigate();

  if (!user) {
    return <Navigate to="/login" />;
  }
  
  const { balance } = user;

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const minimumBalance = parseInt(process.env.REACT_APP_MINIMUM_AMOUNT, 10);
  const amountInt = parseInt(amount, 10);
  
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

      // Validate phone number
      if (!/^254\d{9}$/.test(phone)) {
        setPhoneError('Phone number must start with 254 and have a total of 12 digits.');
        setIsSubmitting(false);
        return;
      }
      // Calculate the minimum allowed balance after withdrawal
      const minimumAllowedBalance = balance - minimumBalance;
    
      // Check if the withdrawal amount exceeds the minimum allowed balance
      if (parseInt(amount, 10) > minimumAllowedBalance) {
        setError(`Withdrawal amount exceeds the allowed balance. You can withdraw up to ${minimumAllowedBalance}.`);
        toast.error(`A minimum of ${minimumBalance} is required to maintain your account. You can withdraw up to ${minimumAllowedBalance}.`);
        setIsSubmitting(false);
        return;
      }    

      try {
        // Prepare the request body
        const requestBody = {
          accountNumber,
          servicecode: "117",
          phone,
          amount: amountInt
        };

        // console.log('Request Body:', requestBody);

        // Perform the withdrawal operation
        const responseData = await m2sPush(user, requestBody);
        setResponse(responseData);
        // Display success message and navigate to login page
        toast.success('Withdrawal was successful!');
        // navigate('/login');
      } catch (error) {
        // Display error message if withdrawal fails
        toast.error('Withdrawal failed. Please try again later.', error);
      } finally {
        setIsSubmitting(false);
        setIsEditing(false);
      }

  };

  return (
    <Page title="Rotsi Light Bill Payment">
      <Container>
      {response ? (
        <div>
          <Card variant="outlined" sx={{ maxWidth: 400, margin: '0 auto' }}>
            <CardContent>
              <Typography variant="h4" gutterBottom sx={{ color: 'green', fontWeight: 'bold' }}>
                Rotsi Light Bill Payments successful!
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold' }}>
                Service: {response.Service}
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold' }}>
                Status: {response.status}
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold' }}>
                Transaction Id: {response.RotsiAPITransactionId}
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold' }}>
                Transaction Amount: {response.TransactionAmount} KES
              </Typography>
              <Link variant="subtitle2" component={RouterLink} to="/dashboard" sx={{ color: 'green', fontWeight: 'bold' , mt: 2 }}>
                Go back to dashboard
              </Link>
            </CardContent>
          </Card>
        </div>        
        ) : (
        <div>
        <Typography variant="h4" gutterBottom sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Easily pay your light bills straight from your Rotsi Wallet.
        </Typography>
        <Typography variant="h4" gutterBottom sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Kindly ensure you have the correct Meter Box Number before proceeding ...
        </Typography>
        <form onSubmit={handleFormSubmit}>
        <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label="Your Meter Box Number"
              variant="outlined"
              // placeholder="PRNxxxxxxxxx"
              value={accountNumber}
              onChange={(e) => setaccountNumber(e.target.value)}
            />
          </FormControl>
          {/* {accountNumberError && (
            <Typography variant="body2" color="error" gutterBottom>
              {accountNumberError}
            </Typography>
          )} */}
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <TextField
            label="Phone Number to receive token number"
            variant="outlined"
            placeholder="2547XXXXXXXX"
            value={isEditing ? phone : user.phonenumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
            InputProps={{
              readOnly: !isEditing,
              endAdornment: (
                <InputAdornment position="end">
                  {!isEditing && (
                    <IconButton onClick={handleEditClick}>
                      <Edit />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          </FormControl>
          {phoneError && (
            <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
              {phoneError}
            </Typography>
          )}            
          <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label="Amount you wish to pay"
              variant="outlined"
              sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              value={amount}
              type="number"
              onChange={(e) => setWithdrawAmount(e.target.value)}
            />
          </FormControl>
          {error && (
            <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
              {error}
            </Typography>
          )}
          <Button type="submit" variant="contained" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} disabled={isSubmitting}>
            {isSubmitting ? `Depositing to KPLC Prepaid`: 'Pay Light Bill'}
          </Button>
        </form>
        </div>
      )}
      </Container>
    </Page>
  );
}