import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { differenceInDays, format } from 'date-fns';
import { useUserContext } from '../../UserContext';

const NotificationBar = () => {
  const [open, setOpen] = useState(true);
  const { user } = useUserContext();
  const { subscriptionend } = user; 

  const handleClose = () => {
    setOpen(false);
  };

  // Calculate the number of days until the subscription ends
  const daysUntilEnd = differenceInDays(new Date(subscriptionend), new Date());

  // Conditionally render the notification bar if the subscription ends within a week
  const shouldShowNotification = daysUntilEnd <= 10 && daysUntilEnd >= 0;

  // Optional: Slide in the notification bar when scrolling
  const trigger = useScrollTrigger();

  // Determine the background color based on the days until the subscription ends
  const getBackgroundColor = () => {
    if (daysUntilEnd <= 2) {
      return 'error.main'; // Red color for critical warning
    }
    if (daysUntilEnd >= 5) { 
      return 'secondary.main'; // Default secondary color for other cases
    }
  };

  if (!shouldShowNotification || !open) {
    return null;
  }

  return (
    <Slide appear={false} direction="down" in={!trigger && open}>
      <AppBar position="fixed" sx={{ bgcolor: getBackgroundColor() }}>
        <Toolbar sx={{ justifyContent: 'center', padding: '5px 0' }}>
          {/* <Typography variant="body2" sx={{ flexGrow: 1, textAlign: 'center' }}>
            Oh! Oh! We`ve noticed that your subscription is less than {daysUntilEnd} day{daysUntilEnd !== 1 ? 's' : ''} away.
            You can always add more days to your existing subscription :)){' '}
            <Link href="/renew-subscription" color="inherit" underline="always">
              Learn more
            </Link>
          </Typography>
          <Typography variant="body2" sx={{ flexGrow: 1, textAlign: 'center' }}>
                🚀 Unlock Exclusive Benefits! Your subscription has just {daysUntilEnd} day{daysUntilEnd !== 1 ? 's' : ''} left, but why wait? Extend now and enjoy uninterrupted access to premium features! 🎉{' '}
                <Link href="/renew-subscription" color="inherit" underline="always">
                    Get More Time & Perks!
                </Link>
          </Typography>

          <Typography variant="body2" sx={{ flexGrow: 1, textAlign: 'center' }}>
                😊 Stay Ahead! Your subscription renewal is around the corner in {daysUntilEnd} day{daysUntilEnd !== 1 ? 's' : ''}. Secure your spot now and enjoy extra perks by renewing early! 💪{' '}
                <Link href="/renew-subscription" color="inherit" underline="always">
                    Renew Early & Get Extra Benefits!
                </Link>
          </Typography>

          <Typography variant="body2" sx={{ flexGrow: 1, textAlign: 'center' }}>
            ⏳ Don't Miss Out! Renew your subscription early and get exclusive bonuses! Only {daysUntilEnd} day{daysUntilEnd !== 1 ? 's' : ''} left to lock in this offer.🔥{' '}
            <Link href="/renew-subscription" color="inherit" underline="always">
                Renew Now & Claim Your Bonus!
            </Link>
          </Typography>

          <Typography variant="body2" sx={{ flexGrow: 1, textAlign: 'center' }}>
            ⚠️ Time’s Running Out! With just {daysUntilEnd} day{daysUntilEnd !== 1 ? 's' : ''} left, now’s the best time to renew your subscription. Keep enjoying our top-tier features without interruption! 🚀{' '}
            <Link href="/renew-subscription" color="inherit" underline="always">
                Get More Time & Perks!
            </Link>
          </Typography>

          <Typography variant="body2" sx={{ flexGrow: 1, textAlign: 'center' }}>
            🎁 Loyal members get more! Renew your subscription today and get an additional month on us! Only {daysUntilEnd} day{daysUntilEnd !== 1 ? 's' : ''} left to take advantage of this special offer! 💎{' '}
            <Link href="/renew-subscription" color="inherit" underline="always">
                Renew & Get Extra Time!
            </Link>
          </Typography> */}

          <Typography variant="body2" sx={{ flexGrow: 1, textAlign: 'center' }}>
            😊 Stay Ahead! Your subscription renewal is around the corner in {daysUntilEnd} day{daysUntilEnd !== 1 ? 's' : ''}. left to take advantage of this special offer! 💎Keep enjoying our top-tier features without interruption! 🚀{' '}
            <Link href="/renew-subscription" color="inherit" underline="always">
                Renew & Get Extra Time!
            </Link>
          </Typography>

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ marginLeft: '20px' }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

export default NotificationBar;