import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, Stack, CircularProgress, Dialog, Backdrop, Link, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import ReactPlayer from 'react-player';
import { getinvitedetails, helpfund } from '../apis/Kickstarterapis';
import Page from '../components/Page';

export default function KickStarterInviteLink({ location: propLocation }) {
    const location = useLocation();
    const [inviteLink, setInviteLink] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [taskDetails, setTaskDetails] = useState(null);
    const [phoneFormatError, setPhoneFormatError] = useState('');
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [showForm, setShowForm] = useState(false); // State to manage form display
    const [phonenumber, setPhoneNumber] = useState(''); // State to manage phone number input
    const [amount, setAmount] = useState(''); // State to manage amount input
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const inviteLinkParam = searchParams.get('invitelink');
        if (!inviteLinkParam) {
            navigate('/dashboard/app');
        }
        setInviteLink(inviteLinkParam);

        const fetchTaskDetails = async () => {
            try {
                const taskDetails = await getinvitedetails({ invitelink: inviteLinkParam }); 
                setTaskDetails(taskDetails);
                setLoading(false); // Set loading to false when data is fetched
            } catch (error) {
                console.error('Error fetching task details:', error);
                // Handle error here
            }
        };

        fetchTaskDetails();
    }, [location.search, navigate, inviteLink]);
    
    const formatPhone = (phone) => {
        phone = String(phone);
        let formattedPhone = phone;
        if (phone.startsWith('+254') && phone.length === 13) {
          formattedPhone = `254${phone.substr(4)}`;
        } else if (phone.length === 12 && phone.startsWith('254')) {
          return formattedPhone;
        } else if (phone.startsWith('07') && phone.length === 10) {
          formattedPhone = `254${phone.substr(1)}`;
        } else if (phone.startsWith('011') && phone.length === 10) {
          formattedPhone = `254${phone.substr(1)}`;
        } else if (phone.startsWith('0') && phone.length === 10) {
          formattedPhone = `254${phone.substr(1)}`;
        } else if (phone.startsWith('7') && phone.length === 9) {
          formattedPhone = `254${phone}`;
        }else if (phone.startsWith('11') && phone.length === 9) {
          formattedPhone = `254${phone}`;
        } else {
          formattedPhone = null;
        }
        return formattedPhone;
    };

    const validateAndFormatPhoneNumber = (phone) => {
        if (/^\254\d{8}$/.test(phone)) {
            return phone;
        } 
        if (/^07\d{8}$/.test(phone)) {
            return phone;
        } 
        if (/^01\d{8}$/.test(phone)) {
            return phone;
        }
        setPhoneFormatError('Phone number must start with +254, 07, or 011 and have the specified number of digits.');
        return false;
    };
   
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        // Perform form validation here
        setIsSubmitting(true);

        try {
            // Perform fund action
            const testedPhone = validateAndFormatPhoneNumber(phonenumber);
            if (!testedPhone) {
                return;
            }
            const formattedPhoneNumber = formatPhone(testedPhone);

            const requestBody = {
                phone: formattedPhoneNumber,
                amount,
                invitelink: inviteLink  
            };
            console.log(requestBody);   
            await helpfund(requestBody); // Pass phone number and amount
            // Display success message
            toast.success('Funds successfully transferred!');
        } catch (error) {
            // Display error message
            toast.error('Failed to transfer funds. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Page title={`Help fund our ${taskDetails ? taskDetails.productName : ''} project`}>
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    position: 'relative' // Added for positioning the form
                }}
            >
                {loading ? ( // Show loading spinner while data is being fetched
                    <Backdrop open={loading} style={{ zIndex: 9999 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                ) : (
                    <>
                        <Stack spacing={2}>
                            {taskDetails && (
                                <>
                                    <Typography variant="h6">Product Name : {taskDetails.productname}</Typography>
                                    <Typography variant="body1">Description: {taskDetails.productDescription}</Typography>
                                    {taskDetails.pitchvideo && (
                                        <ReactPlayer
                                            url={taskDetails.pitchvideo} 
                                            width="100%" height="100%"  style={{ maxWidth: '800px', maxHeight: '600px' }} 
                                            playbackRate={1} volume={0.8} 
                                            muted={false}
                                            loop={false} 
                                            pip
                                            controls // Set whether the native controls are displayed
                                        />
                                    )}
                                    {taskDetails.prototypevideo && (
                                        <ReactPlayer
                                            url={taskDetails.prototypevideo}
                                            width="100%" height="100%"  style={{ maxWidth: '800px', maxHeight: '600px' }} 
                                            playbackRate={1} 
                                            volume={0.8}
                                            muted={false}
                                            loop={false} 
                                            pip
                                            controls // Set whether the native controls are displayed
                                        />                                      
                                    )}
                                    <LoadingButton
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        disabled={isSubmitting}
                                        onClick={() => setShowForm(true)} // Show form on button click
                                    >Help Fund ...</LoadingButton>
                                </>
                            )}
                        </Stack>
                        {showForm && (
                            <form
                                onSubmit={handleFormSubmit}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: '#ffffff',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                                }}
                            >
                                <Typography variant="h6" align="center">
                                    Fund our {taskDetails ? taskDetails.productName : ''} project
                                </Typography>
                                <TextField
                                    fullWidth
                                    required
                                    label="Phone Number"
                                    value={phonenumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    required
                                    label="Amount"
                                    type="number"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    margin="normal"
                                />
                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Fund
                                </LoadingButton>
                            </form>
                        )}
                    </>
                )}
            </Container>
        </Page>
    );
}