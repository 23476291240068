import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { Link as RouterLink, useLocation, Navigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import useAccount from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/LogoBs';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
// import UserBasedNavConfig from './NavConfig';
import navConfig from './NavConfig';
import vipPlatinumnavConfig from './NavConfig2';

import { useUserContext } from '../../UserContext';
import Login from '../../pages/Login';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));
// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const getColorAndEmoji = (status) => {
  let color = '';
  let emoji = '';

  switch (status) {
    case 'Basic':
      color = '#808080'; // Define your boring color style
      break;
    case 'VIP(Gold)':
      color = '#FFD700'; // Define your better color style
      break;
    case 'VIP(Platinum)':
      color = '#6A5ACD'; // Define your inviting color style
      emoji = '🌟'; // Emoji for VIP(Platinum)
      break;
    default:
      color = '#2196F3'; // Define a default color style
      break;
  }

  return { color, emoji };
};

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const account = useAccount();
  const { emoji } = getColorAndEmoji(account.status);

  const { user } = useUserContext();

  const usernameTitleCase = capitalizeFirstLetter(account.displayName);
  
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!user) {
    <Login />;
  }
  if (!user) {
    return <Navigate to="/login" />; // Use Navigate to redirect to the login page
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="/business-dashboard/app">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                {usernameTitleCase}
              </Typography>
              <Typography variant="body2" sx={{ color: getColorAndEmoji(account.status).color,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}> {/* Update here */}
                {account.status} {emoji}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      {user.status !== 'VIP(Platinum)' ? (
        <NavSection navConfig={navConfig} />
        /* <NavSection navConfig={UserBasedNavConfig()} /> */
      ) : (
        <NavSection navConfig={vipPlatinumnavConfig} />
      )}
      
      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Have some Questions?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Reach out to us
            </Typography>
          </Box>

          <Button href="https://vinyldavyl.netlify.app" target="_blank" variant="contained">
            Contact us
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}