// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfigb2c = [
  {
    title: 'dashboard',
    path: '/staff-dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Deposits & Pay-ins  ',
    icon: getIcon('eva:settings-2-fill'),
    children: [
      {
        title: 'Receive via Stk',
        path: '/staff-dashboard/stkpayments',
        icon: getIcon('eva:people-fill'),
        // material-symbols--send-money-rounded
      },
      // {
      //   title: 'Scan to Pay(QRCode)',
      //   path: '/staff-dashboard/qrcodepayments',
      //   icon: getIcon('bitcoin-icons--pantheon-outline'),
      //   // fxemoji--bank
      // },
      {
        title: 'Generate Payment Link',
        path: '/staff-dashboard/generatepaymentlink',
        icon: getIcon('eva:people-fill'),
      },
    ],
  },
  {
    title: 'Pay-Outs',
    icon: getIcon('eva:settings-2-fill'),
    children: [
      {
        title: 'Straight To Phone',
        path: '/staff-dashboard/b2cwithdrawals',
        icon: getIcon('eva:people-fill'),
        // material-symbols--send-money-rounded
      },
      // {
      //   title: 'Straight To Bank',
      //   path: '/staff-dashboard/m2bwithdrawals',
      //   icon: getIcon('bitcoin-icons--pantheon-outline'),
      //   // fxemoji--bank
      // },
    ],
  },
  // {
  //   title: 'Pay Bills',
  //   icon: getIcon('eva:settings-2-fill'),
  //   children: [
  //     // {
  //     //   title: 'Pay Light Bills',
  //     //   path: '/staff-dashboard/kplclightbill',
  //     //   icon: getIcon('eva:people-fill'),
  //     //   // material-symbols--send-money-rounded
  //     // },
  //     {
  //       title: 'Make Bill Payment',
  //       path: '/staff-dashboard/otherbills',
  //       icon: getIcon('bitcoin-icons--pantheon-outline'),
  //       // fxemoji--bank
  //     },
  //   ],
  // },
  // {
  //   title: 'Pay Taxes',
  //   icon: getIcon('eva:settings-2-fill'),
  //   children: [
  //     {
  //       title: 'Remit Kra Taxes',
  //       path: '/staff-dashboard/kraremittax',
  //       icon: getIcon('eva:people-fill'),
  //       // material-symbols--send-money-rounded
  //     },
  //   ],
  // },
  
  // {
  //   title: 'SMS Services',
  //   icon: getIcon('eva:settings-2-fill'),
  //   children: [
  //     {
  //       title: 'Create Bulk SMS',
  //       path: '/staff-dashboard/bulksms',
  //       icon: getIcon('eva:people-fill'),
  //       // material-symbols--send-money-rounded
  //     },
  //     // {
  //     //   title: 'Check Scheduled SMS',
  //     //   path: '/staff-dashboard/products',
  //     //   icon: getIcon('bitcoin-icons--pantheon-outline'),
  //     //   // fxemoji--bank
  //     // },
  //   ],
  // },

  // {
  //   title: 'ᴠɪᴘ SERVICES 💎',
  //   icon: getIcon('eva:settings-2-fill'),
  //   children: [
  //     {
  //       title: 'Make Bulk payments.',
  //       path: '/staff-dashboard/bulkpayments',
  //       icon: getIcon('eva:people-fill'),
  //       // material-symbols--send-money-rounded
  //     },
  //     {
  //       title: 'Schedule Recurring payments.',
  //       path: '/staff-dashboard/standingorders',
  //       icon: getIcon('bitcoin-icons--pantheon-outline'),
  //       // fxemoji--bank
  //     },
  //   ],
  // },
  // {
  //   title: 'Transfer Funds',
  //   icon: getIcon('eva:settings-2-fill'),
  //   children: [
  //     {
  //       title: 'Transfer Funds',
  //       path: '/staff-dashboard/transferfunds',
  //       icon: getIcon('eva:people-fill'),
  //       // material-symbols--send-money-rounded
  //     },
  //   //   {
  //   //     title: 'Schedule Recurring payments.',
  //   //     path: '/staff-dashboard/products',
  //   //     icon: getIcon('bitcoin-icons--pantheon-outline'),
  //   //     // fxemoji--bank
  //   //   },
  //   ],
  // },
  
  // {
  //   title: 'Escrow Services',
  //   // <use href="#lets-icons--wallet-duotone-line" />
  //   icon: getIcon('eva:settings-2-fill'),
  //   children: [
  //     {
  //       title: 'Create Escrow Wallet',
  //       path: '/staff-dashboard/escrowcreation',
  //       icon: getIcon('eva:people-fill'),
  //     },
  //     {
  //       title: 'Manage Escrow Wallets',
  //       path: '/staff-dashboard/manageescrows',
  //       icon: getIcon('bitcoin-icons--pantheon-outline'),
  //     },
  //   ],
  // },
  // {
  //   title: 'Generate Payment Link',
  //   // <use href="#lets-icons--wallet-duotone-line" />
  //   icon: getIcon('eva:settings-2-fill'),
  //   children: [
  //     {
  //       title: 'Generate Payment Link',
  //       path: '/staff-dashboard/generatepaymentlink',
  //       icon: getIcon('eva:people-fill'),
  //     },
  //     // {
  //     //   title: 'Manage Payment Link',
  //     //   path: '/staff-dashboard/manageescrows',
  //     //   icon: getIcon('bitcoin-icons--pantheon-outline'),
  //     // },
  //   ],
  // },
];

export default navConfigb2c;