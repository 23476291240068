import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import Page from '../../../../components/Page';
import { useUserContext } from '../../../../UserContext';
import ManualEntryForm from './ManualEntryForm';
import UploadB2cForm from './UploadB2cForm';

export default function BulkB2C() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [manualShowDetails, setManualShowDetails] = useState(false);
  const [csvShowDetails, setCsvShowDetails] = useState(false);
  const [payments, setPayments] = useState([]);
  const { user } = useUserContext();

  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleAddPayment = (payment) => {
    setPayments([...payments, payment]);
  };

  const handleBack = () => {
    setManualShowDetails(false);
    setCsvShowDetails(false);
  };

  const handleDeleteRow = (index) => {
    setPayments(payments.filter((_, i) => i !== index));
  };

  return (
    <Page title="Rotsi Bulk B2C">
      <Container>
        {!manualShowDetails && !csvShowDetails && (
          <div>
            <Typography variant="h4" gutterBottom sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              Easily initiate Bulk payments to your supplier, employees, or customers via M-Pesa using your M-Pesa working account directly from your dashboard ...
            </Typography>
            <div>
              <Button variant="contained" onClick={() => setManualShowDetails(true)} sx={{ marginRight: 1 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}>
                Manual Entry
              </Button>
              <Button variant="contained" onClick={() => setCsvShowDetails(true)} sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                Upload Xlsx
              </Button>
            </div>
          </div>
        )}
        {manualShowDetails && (
          <div>
            <Typography variant="h4" gutterBottom sx={{ marginTop: 4, marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              Manual Entry
            </Typography>
            <ManualEntryForm
              payments={payments}
              setPayments={setPayments}
              handleDelete={handleDeleteRow}
              handleBack={handleBack}
            />
          </div>
        )}
        {csvShowDetails && (
          <div>
            <Typography variant="h4" gutterBottom sx={{ marginTop: 4, marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              Upload Payment File
            </Typography>
            <UploadB2cForm
              payments={payments}
              setPayments={setPayments}
              handleDelete={handleDeleteRow}  
              handleBack={handleBack}
            />
          </div>
        )}
      </Container>
    </Page>
  );
}