import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, TextField, InputLabel, FormControl, FormHelperText, Button } from '@mui/material';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton'; 
import { ThreeDots, Hourglass, ThreeCircles } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';
import { set } from 'date-fns';
import Page from '../components/Page';
import { getinvitedetails, initiatePaymentLink } from '../apis/generatelink';

const formatPhone = (phone) => {
    phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
        formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
        return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
        formattedPhone = `254${phone}`;
    } else if (phone.startsWith('11') && phone.length === 9) {
        formattedPhone = `254${phone}`;
    } else {
        formattedPhone = null;
    }
    return formattedPhone;
};

export default function PaymentInviteLink() {
    const location = useLocation();
    const [inviteLink, setInviteLink] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [phone, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingIcon, setLoadingIcon] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const [success, setSuccess] = useState(false);
    const [paymentError, setPaymentError] = useState('');
    const [response, setResponse] = useState(null);
    const [taskDetails, setTaskDetails] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const inviteLinkParam = searchParams.get('paymentlink');
        if (!inviteLinkParam) {
            navigate('/login');
        }
        setInviteLink(inviteLinkParam);

        const fetchTaskDetails = async () => {
            try {
                setLoading("Fetching Payment Link Details");
                setLoadingIcon(Hourglass);
                const taskDetails = await getinvitedetails({ invitelink: inviteLinkParam });
                setTaskDetails(taskDetails);
            } catch (error) {
                console.error('Error fetching task details:', error);
            } finally {
                setLoadingIcon(false);
                setLoading(false);
            }
        };

        fetchTaskDetails();
    }, [location.search, navigate]);

    const handlePhoneChange = (e) => {
        const { value } = e.target;
        setPhoneNumber(value);
        setPhoneError('');
    };

    const handleSuccessButtonClick = () => {
        setSuccess(false); // Set success state back to false
        navigate('/register', { replace: true });
      };
     
    const SuccessComponent = ({ response }) => (
        <div style={{ textAlign: 'center' }}>
        <Checkmark size='96px' color='green' />
        <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Transaction Id: {response.RotsiAPITransactionId}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            ResponseMessage: {response.CustomerMessage}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Service: {response.Service}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Want to generate your own payment link, to ease Payments ? Click the button below. 😊
        </Typography>
        <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}>
            Register 
        </Button>
        </div>
    );  

    const ErrorComponent = ({ handlePaymentErrorButtonClick }) => (
        <div style={{ textAlign: 'center' }}>
          <IoMdAlert size={24} color="red" />
          <Typography variant="body1" color="error" sx={{ marginTop: 10,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>An error occurred!</Typography>
          <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 }}>
            Try Again
          </Button>
        </div>
    );    

    const ProcessingComponent = ({loadingIcon: LoadingIcon, message}) => (
        // View when processing
        <Container style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh', // Ensures the container takes the full height of the viewport
        }}>
          <div style={{ textAlign: 'center' }}>
            <Hourglass visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
            <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              {message}
            </Typography>
          </div>
        </Container>
    );  
      
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setLoading('Processing Payment for the Payment Link');
        setLoadingIcon(ThreeCircles);
        const formattedPhoneNumber = formatPhone(phone);
        if (formattedPhoneNumber === null) {
            setPhoneError('Phone number must start with 254 and have a total of 12 digits.');
            setIsSubmitting(false);
            return;
        }
        try {
            console.log('Task Details:', taskDetails);

            const requestPayload = { 
                phone: formattedPhoneNumber, 
                invitelink: inviteLink 
            };

            const responseData = await initiatePaymentLink(requestPayload);
            toast.success(`Payment for ${taskDetails.paymentreason} to ${taskDetails.initiatorname} Confirmed!`);
            // console.log('Response:', responseData);
            setSuccess(true);
            setResponse(responseData);
        } catch (error) {
            if(error.message){
                if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
                  navigate('/login');
                }else{
                  setPaymentError(error.message);
                }
            }else{
                setPaymentError('STK Push Initiation failed. Please try again later.');
            }
        } finally {
            setIsSubmitting(false);
            setLoadingIcon(false);
            setLoading(false);
        }
    };

    return (
        <Page title="Rotsi Generated Payment Link Invite">
            {success && <SuccessComponent response={response} />}
            {loading && <ProcessingComponent loadingIcon={loadingIcon} message={loading} />}
            {paymentError && <ErrorComponent handlePaymentErrorButtonClick={() => setPaymentError('')} />}
            {!success && !paymentError && !loading && (
                <Container
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                        padding: '20px',
                    }}
                >
                    <form onSubmit={handleFormSubmit} style={{ width: '100%', maxWidth: '600px' }}>
                        <InputLabel 
                                sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Payment Initiated By
                        </InputLabel>
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <TextField
                                variant="outlined"
                                value={taskDetails?.initiatorname || ''}
                                InputProps={{ readOnly: true }}
                                sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                                fullWidth
                            />
                        </FormControl>

                        <InputLabel 
                            sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                            Amount Expected to Pay
                        </InputLabel>
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <TextField
                                variant="outlined"
                                value={taskDetails?.amount || ''}
                                InputProps={{ readOnly: true }}
                                sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                                fullWidth
                            />
                        </FormControl>

                        <InputLabel 
                            sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                            Reason for Payment
                        </InputLabel>
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <TextField
                                variant="outlined"
                                value={taskDetails?.paymentreason || ''}
                                InputProps={{ readOnly: true }}
                                sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                                fullWidth
                            />
                        </FormControl>

                        <InputLabel 
                                sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Your Phone Number
                        </InputLabel>
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <TextField
                                label="Your Phone Number"
                                variant="outlined"
                                placeholder="2547XXXXXXXX"
                                value={phone}
                                type="number"
                                onChange={handlePhoneChange}
                                sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                                fullWidth
                            />
                            {phoneError && (
                                <FormHelperText error sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                    {phoneError}
                                </FormHelperText>
                            )}
                        </FormControl>
                        
                        <FormControl fullWidth sx={{ marginTop: 2 }}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                                sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                            >
                                Make Payment
                            </LoadingButton>
                        </FormControl>
                    </form>
                </Container>
            )}
        </Page>
    );
}