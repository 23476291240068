import React, { useEffect, useState } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Puff } from 'react-loader-spinner';
import { CSVLink } from 'react-csv';
import { useUserContext } from '../../../UserContext';
import { Nb2ccustomer } from '../../../apis/chatbotapis';

const ProcessingComponent = () => (
  <Container style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  }}>
    <Container style={{ textAlign: 'center' }}>
      <Puff visible height="80"width="80" color="#306cce" radius="9" ariaLabel="puff-loading" wrapperStyle={{}} wrapperClass=""/>
      <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Retrieving Information...
      </Typography>
    </Container>
  </Container>
);

const B2CRepeatedCustomerWithInput = ({ steps }) => {
  const { user } = useUserContext();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDownload, setShowDownload] = useState(false); // State to control download button visibility
  const n = steps.input_512.value;

  useEffect(() => {
    const fetchRepeatedCustomers = async () => {
      try {
        const response = await Nb2ccustomer(user, n);
        setCustomers(response.customers);
        setShowDownload(true); // Show download button when data is loaded
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRepeatedCustomers();
  }, [user, n]);

  if (loading) {
    return <ProcessingComponent />;
  }

  const headers = [
    { label: 'Customer Name', key: 'recipientname' },
    { label: 'Customer Phone', key: 'phone' },
    { label: 'Total Amount', key: 'total_amount' },
  ];

  const csvData = customers.map(customer => ({
    recipientname: customer.recipientname,
    phone: customer.phone,
    total_amount: customer.total_amount,
  }));

  return (
    <Container>
      {customers.length > 0 ? (
        <Container>
          <Typography variant="h6">
            Top {customers.length} Paid to Phone Numbers
          </Typography>
          {showDownload && (
            <Typography variant="contained" color="primary"sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              <CSVLink data={csvData} headers={headers} filename={'top_b2c_customers.csv'}>
                Download Excel Sheet
              </CSVLink>
            </Typography>
          )}
          {/* {customers.map((customer, index) => (
            <Container key={index}>
              <Typography variant="body1">
                <strong>Customer Name:</strong> {customer.recipientname}
              </Typography>
              <Typography variant="body1">
                <strong>Customer Phone:</strong> {customer.phone}
              </Typography>
              <Typography variant="body1">
                <strong>Number of Transactions:</strong> {customer.total_amount}
              </Typography>
              <hr />
            </Container>
          ))} */}
        </Container>
      ) : (
        <Container>No data available</Container>
      )}
    </Container>
  );
};

export default B2CRepeatedCustomerWithInput;