import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { Navigate,useNavigate,useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DeleteForever } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { Stack, IconButton, InputAdornment, FormControl, RadioGroup, Radio, FormControlLabel,CircularProgress,Typography, TextField, Container,Avatar, Box, Paper  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { businessdocs } from '../../../apis/auth';
import storage from '../../../firebase';

const defaultData = {
  paybillNo: ''
};

const WelcomePageForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const registerFormData = location.state;
  console.log('registerFormData:', registerFormData);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(defaultData);
  const [paybillNo, setPaybillNo] = useState('');
  const [PaybillNoError, setPaybillNoError] = useState('');
  const [isUploadingCertificate, setUploadingCertificate] = useState(false); 
  const [isUploadingID, setUploadingID] = useState(false); 
  const [continueAcceptance, setContinueAcceptance] = useState(false);
  const [certificatePicUrl, setCertificatePicUrl] = useState('');
  const [idPicUrl, setIdPicUrl] = useState('');

  useEffect(() => {
    if (registerFormData) {
      // console.log('Received formData:', registerFormData);
    }
  }, [registerFormData]);

  const methods = useForm({
    resolver: yupResolver(Yup.object().shape({
      paybillNo: Yup.string().required('Paybill No is required'),
    })),
  });

  if (!registerFormData) {
    return <Navigate to="/register" />;
  }
  
  const handleOnSubmit = async (formData) => {
    setIsLoading(true);

    const paybillNo = formData.paybillNo;

    if (!paybillNo || !certificatePicUrl || !idPicUrl) {
      setIsLoading(false);
      return;
    }

    // console.log(paybillNo, certificatePicUrl, idPicUrl);
    try {
      // const requestBody = {
      //   paybillNo, certificatePicUrl, idPicUrl
      // };
      const requestBody = {
        // ...formData, // Spread the formData
        paybillNo, // Add paybillNo separately if needed
        certificatePicUrl,
        idPicUrl,
        ...registerFormData, // Spread the registerFormData
      };
  
      // console.log('Combined requestBody:', requestBody);
  
      // console.log(requestBody);
      const response = await businessdocs(requestBody);
      // setUserDetails(response.data.user);

      toast.success("Business documents submitted successfully!");
      navigate('/awaiting-verification'); // Redirect to a success page
    } catch (error) {
      console.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePaybillChange = (e) => {
    const { value } = e.target;
    setPaybillNo(value);
    // Clear phoneError when the user starts typing again
    setPaybillNoError('');
  };

  const handleCertificatePicChange = async (e) => {
    const file = e.target.files[0];
    
    if (file.type.startsWith('image/')) {
      setUploadingCertificate(true);
  
      try {
        // console.log("Handling image upload...");
        
        const imageRef = storageRef(storage, `business_clients/Certificate/${file.name}`);
        
        uploadBytes(imageRef, file)
          .then((snapshot) => {
            getDownloadURL(snapshot.ref)
              .then((url) => {
                setCertificatePicUrl(url);
                toast.success("Business Incorporation Image uploaded successfully!");
              })
              .catch((error) => {
                console.error('Failed to get download URL:', error);
                toast.error('Failed to get download URL. Please try again later.');
              });
          })
          .catch((error) => {
            console.error('Failed to upload image:', error);
            toast.error('Failed to upload image. Please try again later.');
          });
      } catch (error) {
        console.error('Failed to handle image upload:', error);
        toast.error('Failed to handle image upload. Please try again later.');
      } finally {
        setUploadingCertificate(false);
      }
    } else {
      toast.error('Please select an image file.');
    }
  };

  const handleIDPicChange = async (e) => {
    const file = e.target.files[0];
    
    if (file.type.startsWith('image/')) {
      setUploadingID(true);
  
      try {
        // console.log("Handling image upload...");
        
        const imageRef = storageRef(storage, `business_clients/ID/${file.name}`);
        
        uploadBytes(imageRef, file)
          .then((snapshot) => {
            getDownloadURL(snapshot.ref)
              .then((url) => {
                setIdPicUrl(url);
                toast.success("ID/Passport Picture uploaded successfully!");
              })
              .catch((error) => {
                console.error('Failed to get download URL:', error);
                toast.error('Failed to get download URL. Please try again later.');
              });
          })
          .catch((error) => {
            console.error('Failed to upload image:', error);
            toast.error('Failed to upload image. Please try again later.');
          });
      } catch (error) {
        console.error('Failed to handle image upload:', error);
        toast.error('Failed to handle image upload. Please try again later.');
      } finally {
        setUploadingID(false);
      }
    } else {
      toast.error('Please select an image file.');
    }
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const BusinessVerification = () => {
    return (
      <>
        <IconButton edge="start" onClick={() => setContinueAcceptance(false)}>
          <Iconify icon="eva:arrow-back-outline" />
        </IconButton>

        <RHFTextField name="paybillNo" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Enter Paybill No" />
        {/* <TextField 
          name="paybillNo" 
          label="Enter Paybill No"
          onChange={handlePaybillChange}
          type='number'
          // sx={fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}
          value={paybillNo}
          placeholder="Enter Paybill No" 
        /> */}

        {certificatePicUrl && (
          <Paper elevation={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Avatar
                src={certificatePicUrl}
                alt="photoURL"
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: '8px',
                }}
              />
              <IconButton onClick={() => setCertificatePicUrl(null)}>
                <DeleteForever />
              </IconButton>
            </Box>
          </Paper>
        )}

        {!certificatePicUrl && 
          <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              <IconButton component="label">
                <CloudUploadIcon />
                <input
                  type="file"
                  accept="image/jpeg, image/png, image/gif"
                  style={{ display: 'none' }}
                  onChange={handleCertificatePicChange}
                />
              </IconButton>

              <Typography variant="p" align="center" display='flex' alignItems= 'center' justifyContent= 'center' sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} marginTop= '10px'>
                Please upload a square-shaped picture of Business Registration Certificate/Certificate of Incorporation picture. Max 2MB. Formats allowed: jpg, png, and gif
              </Typography>
              
              {isUploadingCertificate && (
                <Box sx={{ position: 'relative' }}>
                  <CircularProgress
                    size={64}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </Box>
              )}
          </Container>
        }

        {idPicUrl && (
          <Paper elevation={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Avatar
                src={idPicUrl}
                alt="photoURL"
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: '8px',
                }}
              />
              <IconButton onClick={() => setIdPicUrl(null)}>
                <DeleteForever />
              </IconButton>
            </Box>
          </Paper>
        )}
        {!idPicUrl && 
          <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              <IconButton component="label">
                <CloudUploadIcon />
                <input
                  type="file"
                  accept="image/jpeg, image/png, image/gif"
                  style={{ display: 'none' }}
                  onChange={handleIDPicChange}
                />
              </IconButton>

              <Typography variant="p" align="center" display='flex' alignItems= 'center' justifyContent= 'center' sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} marginTop= '10px'>
                Please upload a square-shaped picture of ID/passport Picture of the nominated administrator. Max 2MB. Formats allowed: jpg, png, and gif
              </Typography>
    
                             
              {idPicUrl && (
                <Box sx={{ position: 'relative' }}>
                  <CircularProgress
                    size={64}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </Box>
              )}
          </Container>
        }

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
          variant="contained"
          loading={isSubmitting}
          onClick={handleOnSubmit}
          // disabled={!formData.paybillNo || !certificatePicUrl || !idPicUrl || isUploadingCertificate || isUploadingID}
        >
          Submit
        </LoadingButton>
      </>
    );
  };
  
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleOnSubmit)}>
      <Stack spacing={3}>
        {!continueAcceptance && continueAcceptance !== true && (
          <FormControl component="fieldset">
            <IconButton edge="start" onClick={() => setContinueAcceptance(true)}>
              <Iconify icon="eva:arrow-forward-outline" />
              <Typography>Start Uploading</Typography>
            </IconButton>
          </FormControl>
        )}

        {continueAcceptance && (
          <BusinessVerification />
        )}
      </Stack>
    </FormProvider>
  );
};

export default WelcomePageForm;