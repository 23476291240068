import React, { useState } from 'react';
import Slider from 'react-slick';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const DashboardCarousel = ({ children }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 300,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     afterChange: (current) => setCurrentSlide(current),
    // };
    const settings = {
        dots: true,
        infinite: true,
        speed: 200, // Reduced speed for faster transitions
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000, // Adjusted autoplay speed
        afterChange: (current) => setCurrentSlide(current),
      };
  
    return (
      <div>
        {Array.isArray(children) ? (
          <Slider {...settings}>
            {children.map((child, index) => (
              <div key={index}>{child}</div>
            ))}
          </Slider>
        ) : (
          <Slider {...settings}>{children}</Slider>
        )}
        {/* <p>Current Slide: {currentSlide + 1}</p> */}
      </div>
    );
};
  
export default DashboardCarousel;