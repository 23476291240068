import React, { useState, useEffect } from 'react';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import {
    Table, TableContainer, TableHead, TableRow, TableCell,
    TableBody, Paper, IconButton, Menu, MenuItem, Typography,
    Box, Link, Button, Popover, TextField, Container, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Grid,Modal
} from '@mui/material';
import { MoreVert as MoreVertIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { ThreeDots,MutatingDots } from 'react-loader-spinner';
// import moment from 'moment';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import noSenderImage from '../../assets/standingOrders.png';
import { getStandingOrders, updateStandingOrders,updateTaskStatus } from '../../apis/recurringpaymentsapis';
import { useUserContext } from '../../UserContext';

const RecurringPaymentsManage = () => {
    const { user } = useUserContext();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessingTransaction, setIsProcessingTransaction] = useState(false);
    const [standingOrders, setStandingOrders] = useState([]);
    const [phoneFormatError, setPhoneFormatError] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [phoneError,setPhoneError] = useState(null);
    const [amountError,setAmountError] = useState(null);
    const [transactionChargeError,setTransactionChargeError] = useState(null);

    const [paymentError, setPaymentError] = useState('');
    const [openPopover, setOpenPopover] = useState(false);
    const [phonenumber, setPhoneNumber] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [payments, setPayments] = useState([]);
    const [editableRows, setEditableRows] = useState([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        maxHeight: '90vh'
    };

    const formatPhoneNumber = (phone) => {
        let formattedPhone = phone;
        if (phone.startsWith('+254') && phone.length === 13) {
          formattedPhone = `254${phone.substr(4)}`;
        } else if (phone.length === 12 && phone.startsWith('254')) {
          return formattedPhone;
        } else if (phone.startsWith('07') && phone.length === 10) {
          formattedPhone = `254${phone.substr(1)}`;
        } else if (phone.startsWith('7') && phone.length === 9) {
          formattedPhone = `254${phone}`;
        } else {
          formattedPhone = null;
        }
        return formattedPhone;
    };

    const calculateTransactionCharge = (deductedAmount) => {
        // Your existing table data
        const transactionChargeTable = [
            { min: 1, max: 49, registered: 0, unregistered: 8 },
            { min: 50, max: 100, registered: 0, unregistered: 8 },
            { min: 101, max: 500, registered: 5, unregistered: 8 },
            { min: 501, max: 1000, registered: 5, unregistered: 14 },
            { min: 1001, max: 1500, registered: 5, unregistered: 14 },
            { min: 1501, max: 2500, registered: 9, unregistered: 18 },
            { min: 2501, max: 3500, registered: 9, unregistered: 25 },
            { min: 3501, max: 5000, registered: 9, unregistered: 30 },
            { min: 5001, max: 7500, registered: 11, unregistered: 37 },
            { min: 7501, max: 10000, registered: 11, unregistered: 46 },
            { min: 10001, max: 15000, registered: 11, unregistered: 62 },
            { min: 15001, max: 20000, registered: 11, unregistered: 67 },
            { min: 20001, max: 25000, registered: 13, unregistered: 73 },
            { min: 25001, max: 30000, registered: 13, unregistered: 73 },
            { min: 30001, max: 35000, registered: 13, unregistered: 73 },
            { min: 35001, max: 40000, registered: 13, unregistered: null },
            { min: 40001, max: 45000, registered: 13, unregistered: null },
            { min: 45001, max: 50000, registered: 13, unregistered: null },
            { min: 50001, max: 70000, registered: 13, unregistered: null },
            { min: 70001, max: 250000, registered: 13, unregistered: null },
        ];
        
    
        const foundRow = transactionChargeTable.find((row) => deductedAmount >= row.min && deductedAmount <= row.max);
        return foundRow ? foundRow.registered : null;
    };

    const ProcessingComponent = () => (
        <Container style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh', // Ensures the container takes the full height of the viewport
        }}>
            <div style={{ textAlign: 'center' }}>
                <MutatingDots visible height="80" width="80" color="#306cce" ariaLabel="mutating-dots-loading" wrapperStyle={{}} wrapperClass="" />
                <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                    Fetching Standing Orders...
                </Typography>
            </div>
        </Container>
    );

    const fetchDetails = async () => {
        try {
            const responseData = await getStandingOrders(user);
            setStandingOrders(responseData.data); // Adjusted to match the expected data structure
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching wallet details:', error);
            toast.error('Error fetching wallet details:', error);
            setIsLoading(true);
        }
    };

    useEffect(() => {
        if (!user) {
          navigate('/login'); // Navigate to the login page if user is not available
        }
    }, [user, navigate]);
    
    useEffect(() => {
        if (user) {
            fetchDetails();   
        }
    }, [user]);

    const handleMenuClick = (event, order) => {
        setAnchorEl(event.currentTarget);
        setSelectedOrder(order);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleViewClick = (order) => {
        setSelectedOrder(order);
        setViewModalOpen(true);
    };

    const handleUpdateClick = (order) => {
        setSelectedOrder(order);
        setPayments(order.payments); // Initialize the payments state with the selected order's payments
        // setEditableRows(order.payments.map(() => false)); // Initialize editable state for each row
        setUpdateModalOpen(true);
    };

    const handleActionClick = () => {
        setConfirmModalOpen(true);
        handleMenuClose();
    };

    const handleConfirm = async (row) => {
        setIsProcessingTransaction(true);
        try {
            let newStatus;

            // eslint-disable-next-line camelcase
            const { task_id, activestatus } = row;

            if (activestatus === 'active') {
                newStatus = 'Deactivated';
            } else {
                newStatus = 'active';
            }
            
            // Handle success
            const response = await updateTaskStatus(task_id, newStatus, user);
            toast.success(`${selectedOrder.standingordername} has been ${newStatus === 'inactive' ? 'Deactivated' : 'Activated'} updated successfully.`)
            // console.log(response);
        } catch (error) {
            console.error('Error initiating payment:', error);
        } finally {
            setIsProcessingTransaction(false);
            setOpenPopover(false);
            setConfirmModalOpen(false);
            fetchDetails();
        }
    };

    const handlePopoverClose = () => {
        setOpenPopover(false);
    };

    const handleAddPayment = () => {
        setPayments([...payments, { phone: '', amount: '', bankcode: '', accountNumber: '', recipientSc: '' }]);
        setEditableRows([...editableRows, true]); // Make the new row editable by default
    };

    const handleEditPayment = (index, field, value) => {
        const updatedPayments = payments.map((payment, idx) => (
            idx === index ? { ...payment, [field]: value } : payment
        ));
        setPayments(updatedPayments);
    };

    const handleDeletePayment = (index) => {
        const updatedPayments = payments.filter((_, idx) => idx !== index);
        const updatedEditableRows = editableRows.filter((_, idx) => idx !== index);
        setPayments(updatedPayments);
        setEditableRows(updatedEditableRows);
    };

    const handleToggleEdit = (index) => {
        const updatedEditableRows = editableRows.map((editable, idx) => (
            idx === index ? !editable : editable
        ));
        setEditableRows(updatedEditableRows);
    };


    const handleUpdateChanges = async () => {
        setIsProcessingTransaction(true);
        try {
            let totalTransactionCharge = 0;
            let updatedPayments = [];
    
            if (payments && payments.length > 0) {
                updatedPayments = payments.map(payment => {
                    const { phone, amount, bankcode, accountNumber, recipientSc, ...rest } = payment;
    
                    // Ensure amount is a number
                    const amountInt = parseFloat(amount);
                    if (Number.isNaN(amountInt) || amountInt <= 10) {
                        setAmountError(`${amount} is an invalid amount. Please provide valid numeric amounts greater than 10.`);
                        toast.error(`${amount} is an invalid amount. Please provide valid numeric amounts greater than 10.`);
                        throw new Error(`${amount} is an invalid amount.`);
                    }
    
                    // Format the phone number
                    const formattedPhoneNumber = formatPhoneNumber(phone);
                    if (!formattedPhoneNumber) {
                        setPhoneError(`${phone} is an invalid phone number. Please provide a valid phone number.`);
                        toast.error(`${phone} is an invalid phone number. Please provide a valid phone number.`);
                        throw new Error(`${phone} is an invalid phone number.`);
                    }
    
                    // Calculate transaction charge
                    const transactionCharge = calculateTransactionCharge(amountInt);
                    if (transactionCharge === null) {
                        setTransactionChargeError('Unable to determine transaction charge for the amount.');
                        toast.error(`Unable to determine transaction charge for the amount: ${amount}`);
                        throw new Error(`Unable to determine transaction charge for the amount: ${amount}`);
                    }
    
                    if (bankcode && Number.isNaN(parseInt(bankcode, 10))) {
                        setAmountError(`${bankcode} is an invalid number. Ensure that ${bankcode} is a valid number.`);
                        toast.error(`${bankcode} is an invalid number. Ensure that ${bankcode} is a valid number.`);
                        throw new Error(`${bankcode} is an invalid number.`);
                    }
    
                    if (recipientSc && Number.isNaN(parseInt(recipientSc, 10))) {
                        setAmountError(`${recipientSc} is an invalid number. Ensure that you enter a valid number.`);
                        toast.error(`${recipientSc} is an invalid number. Ensure that you enter a valid number.`);
                        throw new Error(`${recipientSc} is an invalid number.`);
                    }
    
                    // Add transaction charge to total
                    totalTransactionCharge += transactionCharge;
    
                    return {
                        phone: formattedPhoneNumber,
                        amount: amountInt,
                        ...(bankcode && { bankcode }),
                        ...(accountNumber && { accountNumber }),
                        ...(recipientSc && { recipientSc }),
                        ...rest
                    };
                });
            } else {
                throw new Error('No payments provided.');
            }
    
            // console.log(updatedPayments);
            toast.info(`Your New Total Transaction Charge will be: ${totalTransactionCharge}.`);
            // console.log('Total Transaction Charge:', totalTransactionCharge);
    
            const response = await updateStandingOrders(user, selectedOrder.task_id, updatedPayments);
            toast.success(`${selectedOrder.taskname} payments has been updated successfully.`)
            // console.log(response);
            // Handle success
        } catch (error) {
            console.error('Error updating payments:', error);
            setPaymentError(error);
        } finally {
            setIsProcessingTransaction(false);
            setUpdateModalOpen(false);
            fetchDetails();
        }
    };

    const renderTable = (details) => {
        return (
            <TableContainer component={Paper} sx={{ border: '1px solid #ddd' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Standing Order Name</TableCell>
                            <TableCell>Job Type</TableCell>
                            <TableCell>Next Scheduled Time</TableCell>
                            <TableCell>Rounds Completed</TableCell>
                            <TableCell>Active Status</TableCell>
                            <TableCell>VIEW</TableCell>
                            <TableCell>UPDATE</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {details.map((row, index) => (
                            <TableRow key={index} sx={{ border: '1px solid #ddd' }}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.standingordername}</TableCell>
                                <TableCell>{row.job_type}</TableCell>
                                <TableCell>{row.scheduled_time}</TableCell>
                                {/* <TableCell>{moment(row.scheduled_time).format('YYYY-MM-DD HH:mm:ss')}</TableCell> */}
                                {/* <TableCell>
                                    {moment(row.scheduled_time).tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:ss')}
                                </TableCell> */}
                                <TableCell>{row.round}</TableCell>
                                <TableCell>{row.activestatus === 'active' ? 'Active' :'Deactivated'}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleViewClick(row)}>VIEW</Button>
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleUpdateClick(row)}>UPDATE</Button>
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={(event) => handleMenuClick(event, row)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => handleActionClick(row)}>Action</MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderViewModal = () => (
        <Dialog open={viewModalOpen} onClose={() => setViewModalOpen(false)}>
            <DialogTitle>View Details</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {selectedOrder ? (
                        <>
                            <Typography>Standing Order Name: {selectedOrder.standingordername}</Typography>
                            <Typography>Job Type: {selectedOrder.job_type}</Typography>
                            <Typography>Next Scheduled Time: {selectedOrder.scheduled_time}</Typography>
                            <Typography>Rounds Completed: {selectedOrder.round}</Typography>
                            <Typography>Active Status: {selectedOrder.activestatus}</Typography>
                        </>
                    ) : (
                        <Typography>No details available</Typography>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setViewModalOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );

    const renderUpdateModal = () => (
        <Modal open={updateModalOpen} onClose={() => setUpdateModalOpen(false)}>
            <Box sx={modalStyle}>
                <Paper>
                    <Typography 
                        variant="h4" 
                        sx={{ color: 'text.primary',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                        component="h2" gutterBottom
                    >
                        Update Payments
                    </Typography>
                    <Typography 
                        variant="h6" 
                        sx={{ color: 'text.secondary',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                        component="h2" gutterBottom>
                        Each Payment is locked to reduce mistakenly edit payments. Click the edit icon alongside the row inorder to edit    
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Amount</TableCell>
                                    {selectedOrder && selectedOrder.job_type === 'recurringsubscribeM2b' && (
                                        <>
                                            <TableCell>Bank Code</TableCell>
                                            <TableCell>Account Number</TableCell>
                                        </>
                                    )}
                                    {selectedOrder && selectedOrder.job_type !== 'dummyTest' && selectedOrder.job_type !== 'recurringsubscribeB2C' && selectedOrder.job_type !== 'recurringsubscribeM2b' && (
                                        <>
                                            <TableCell>Recipient SC</TableCell>
                                            <TableCell>Account Number</TableCell>
                                        </>
                                    )}
                                    <TableCell>Edit</TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payments.map((payment, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell>{idx + 1}</TableCell>
                                        <TableCell>
                                            <TextField
                                                value={payment.phone}
                                                onChange={(e) => handleEditPayment(idx, 'phone', e.target.value)}
                                                fullWidth
                                                InputProps={{
                                                    readOnly: !editableRows[idx]
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                value={payment.amount}
                                                onChange={(e) => handleEditPayment(idx, 'amount', e.target.value)}
                                                fullWidth
                                                InputProps={{
                                                    readOnly: !editableRows[idx]
                                                }}
                                            />
                                        </TableCell>
                                        {selectedOrder.job_type === 'recurringsubscribeM2b' && (
                                            <>
                                                <TableCell>
                                                    <TextField
                                                        value={payment.bankcode}
                                                        onChange={(e) => handleEditPayment(idx, 'bankcode', e.target.value)}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: !editableRows[idx]
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={payment.accountNumber}
                                                        onChange={(e) => handleEditPayment(idx, 'accountNumber', e.target.value)}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: !editableRows[idx]
                                                        }}
                                                    />
                                                </TableCell>
                                            </>
                                        )}
                                        {selectedOrder.job_type !== 'dummyTest' && selectedOrder.job_type !== 'recurringsubscribeB2C' && selectedOrder.job_type !== 'recurringsubscribeM2b' && (
                                            <>
                                                <TableCell>
                                                    <TextField
                                                        value={payment.recipientSc}
                                                        onChange={(e) => handleEditPayment(idx, 'recipientSc', e.target.value)}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: !editableRows[idx]
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={payment.accountNumber}
                                                        onChange={(e) => handleEditPayment(idx, 'accountNumber', e.target.value)}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: !editableRows[idx]
                                                        }}
                                                    />
                                                </TableCell>
                                            </>
                                        )}
                                        <TableCell>
                                            <IconButton onClick={() => handleToggleEdit(idx)} color="primary">
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleDeletePayment(idx)} color="secondary">
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={handleAddPayment} color="primary">
                            Add Payment to your Standing Order
                        </Button>
                        <LoadingButton
                            onClick={handleUpdateChanges}
                            loading={isProcessingTransaction}
                            color="primary"
                            variant="contained"
                        >
                            Update Changes
                        </LoadingButton>
                    </Box>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => setUpdateModalOpen(false)}>Close</Button>
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );

    const renderConfirmModal = () => (
        <Dialog open={confirmModalOpen} onClose={() => setConfirmModalOpen(false)}>
            <DialogTitle>Confirm Action</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to {selectedOrder && selectedOrder.activestatus === 'active' ? 'deactivate' : 'activate'} this standing order?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                <LoadingButton
                    onClick={() => handleConfirm(selectedOrder)}
                    loading={isProcessingTransaction}
                    color="primary"
                >
                    Confirm
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Manage your Standing Orders</Typography>
            {isLoading ? (
                <ProcessingComponent />
            ) : (
                <>
                    {standingOrders.length === 0 ? (
                        <Box sx={{ textAlign: 'center', py: 5, display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                            <img
                                src={noSenderImage}
                                alt="No Standing Orders"
                                style={{ width: '100%', maxWidth: '400px', height: 'auto' }}
                            />
                            <Typography variant="h6" gutterBottom>No Standing Orders</Typography>
                            <Typography variant="body1">You haven't created any standing orders yet. Create one to simplify your recurring payments.</Typography>
                            <Button
                                component={RouterLink}
                                to="/business-dashboard/standingorders"
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2 }}
                            >
                                Create Standing Order
                            </Button>
                      </Box>
                      
                    ) : (
                        renderTable(standingOrders)
                    )}
                </>
            )}
            {renderViewModal()}
            {renderUpdateModal()}
            {renderConfirmModal()}
        </Container>
    );
};

export default RecurringPaymentsManage;