import React, { useState, useEffect } from 'react';
import { IconButton, Menu, MenuItem, Popover, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography, TextField } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import { getwalletdetails,sendwhatsappinformation } from '../apis/Kickstarterapis';

const KickStarterManage = () => {
    const [walletDetails, setWalletDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [isProcessingTransaction, setIsProcessingTransaction] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopover, setOpenPopover] = useState(false);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await getwalletdetails();
                setWalletDetails(response);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching wallet details:', error);
                toast.error('Error fetching wallet details:', error.message);
                setIsLoading(false);
            }
        };

        fetchDetails();
    }, []);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverClose = () => {
        setOpenPopover(false);
    };

    const handleEmailClick = async (whatsappnumber) => {
        setIsProcessingTransaction(true);
        try {
            const requestBody = {
                whatsappnumber,
                email,
            };
            // console.log(requestBody);
            const response = await sendwhatsappinformation(requestBody);
            setIsProcessingTransaction(false);
            setOpenPopover(false);
            toast.success('Email sent successfully!');
        } catch (error) {
            console.error('Error fetching wallet details:', error);
            toast.error('Error fetching wallet details:', error.message);
            setIsProcessingTransaction(false);
            setOpenPopover(false);
            toast.error('Unsuccessfully');
        }
    };

    const renderTable = () => {
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Product Description</TableCell>
                            <TableCell>Balance</TableCell>
                            <TableCell>Pitch Video</TableCell>
                            <TableCell>Prototype Video</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Payment Details</TableCell>
                            <TableCell>Contribution Numbers</TableCell>
                            <TableCell>Reach out</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {walletDetails.map((row, index) => (
                            <TableRow key={row.wallet_id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.productname}</TableCell>
                                <TableCell>{row.productDescription}</TableCell>
                                <TableCell>{row.balance}</TableCell>
                                <TableCell>{row.pitchvideo}</TableCell>
                                <TableCell>{row.prototypevideo}</TableCell>
                                <TableCell>{row.created_at}</TableCell>
                                <TableCell>{row.payment_details ? Object.keys(row.payment_details).length : 0}</TableCell>
                                <TableCell>
                                    <IconButton onClick={handleMenuClick}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => { handleMenuClose(); setOpenPopover(true); }}>Reach out</MenuItem>
                                    </Menu>
                                    <Popover
                                        open={openPopover}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        PaperProps={{
                                            sx: {
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                minWidth: '320px',
                                                minHeight: '200px',
                                            }
                                        }}
                                    >
                                        <Paper sx={{ p: 2 }}>
                                            <Typography variant="body1">Enter the email you`d like to receive the teams contact information, we will reach out to you with their contact information:</Typography>
                                            <TextField
                                                label="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                sx={{ mt: 2 }}
                                            />
                                            <LoadingButton
                                                onClick={() => handleEmailClick(row.whatsappnumber)}
                                                variant="contained"
                                                loading={isProcessingTransaction}
                                                disabled={isProcessingTransaction}
                                                sx={{ mt: 2 }}
                                            >
                                                Fill contact form
                                            </LoadingButton>
                                        </Paper>
                                    </Popover>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Wallet Details
            </Typography>
            {renderTable()}
        </div>
    );
};

export default KickStarterManage;