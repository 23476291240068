import * as Yup from 'yup';
import { useState,React } from 'react';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify'; // Moved the import here
import { Card, CardContent, Typography, TextField, 
    Button, Stack, Link, CircularProgress,IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../components/hook-form';
import { useUserContext } from '../UserContext'; 
import { loginUser,registerUser } from '../apis/auth';

const loginData = {
    email: '',
    password: '',
};

const registerData = {
    username: '',
    email: '',
    password: '',
    company_name: '',
};
const LoginForm = ({ onRegisterClick, onLogin }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState(loginData);
    const [isLoading, setIsLoading] = useState(false);
    const { setUserDetails } = useUserContext();  

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });
      
    const handleOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const defaultValues = {
        email: '',
        password: '',
        // remember: true,
    };
    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
      });
    
      const {
        handleSubmit,
        formState: { isSubmitting },
      } = methods;
      
      const formSubmit = async (data) => {
        setIsLoading(true);
    
        try {
          // Call the API to log in the user
          const response = await loginUser(data);
          // console.log("User Context Set: ", response.data.user);
          setUserDetails(response.data.user);
          // If login is successful, navigate to the desired page (e.g., /home)
          onLogin();
        } catch (error) {
          // Handle login error
          // console.error('Login failed', error);
          toast.error(error);
        } finally {
          setIsLoading(false);
        }
      };
    
    const onSubmit = methods.handleSubmit(formSubmit); // Call formSubmit when form is submitted    
  return (
    <Card variant="outlined" sx={{ p: 2, borderRadius: 2, boxShadow: 4, maxWidth: 300 }}>
      <CardContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
            <RHFTextField name="email" label="Email address" />

            <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                </InputAdornment>
                ),
            }}
            />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}> */}
            {/* <RHFCheckbox name="remember" label="Remember me" /> */}
            {/* <Link to="/forgotpassword" component={RouterLink} variant="subtitle2" underline="hover">
            Forgot password?
            </Link> */}
        {/* </Stack> */}

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Login
        </LoadingButton>
        <Typography variant="body2">Don't have an account? <Link onClick={onRegisterClick}>Register</Link></Typography>
        </FormProvider>
        </CardContent>
    </Card>
  );
};

const RegisterForm = ({ onLoginClick }) => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState(registerData);
    const [isLoading, setIsLoading] = useState(false);
    const { setUserDetails } = useUserContext();
  
    const RegisterSchema = Yup.object().shape({
      username: Yup.string().required('Username is required'),
      company_name: Yup.string().required('Company Name is required'),
      email: Yup.string().email('Email must be a valid email address').required('Email is required'),
      password: Yup.string().required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    });

    const handleOnChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
   
    const handleOnSubmit = async (formData) => {
      setIsLoading(true);
  
      const updatedFormData = {
        ...formData,
      };
  
      try {
        // console.log("updatedFormData ",updatedFormData);
        const response = await registerUser(updatedFormData);
        setUserDetails(response.data.user);
        onLoginClick();
      } catch (error) {
        toast.error(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    const defaultValues = {
      username: '',
      company_name: '',
      email: '',
      password: '',
      confirmPassword: '',
    };
  
    const methods = useForm({
      resolver: yupResolver(RegisterSchema),
      defaultValues,
    });
  
    const {
      handleSubmit,
      formState: { isSubmitting },
    } = methods;
  
  return (
    <Card variant="outlined" sx={{ p: 2, borderRadius: 2, boxShadow: 4, maxWidth: 300 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>Register</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField name="username" label="Username" />
            <RHFTextField name="company_name" label="Company Name" />
            </Stack>
            <RHFTextField name="email" label="Email address" />
            <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                </InputAdornment>
                ),
            }}
            />
            <RHFTextField name="confirmPassword" label="Confirm Password" type="password" />
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Register
            </LoadingButton>
            </Stack>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export { LoginForm, RegisterForm };