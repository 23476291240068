import React, { useState, useEffect } from 'react';
import { useNavigate ,Navigate, Link as RouterLink} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Stack, List, ListItem,FormControl, ListItemText ,IconButton, InputAdornment,Tooltip,Link,TextField,Typography,Button,Select,MenuItem,FormHelperText} from '@mui/material';
import { Edit,SwapHoriz } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Fuse from 'fuse.js';
import * as Yup from 'yup';

import Page from '../../components/Page';
import { FormProvider, RHFTextField,RHFTextFieldImage } from '../../components/hook-form';
import { useUserContext } from '../../UserContext';
import { m2sPush,paybillPush } from '../../apis/paymentapis'; // Import m2sPush function
import { checkbalance } from '../../apis/gettransactions';

const defaultData = {
  phone: '',
  bank: '',
  servicecode: '',
  accountNumber: '',
  recipientSC: '',
  // inputtedaccountNumber:''
};

const formatPhone = (phone) => {
  phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    }else if (phone.startsWith('11') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
};

export default function OtherBill() {
  const navigate = useNavigate();
  const { user,setUserDetails,setNewBalance } = useUserContext();
  const [formData, setFormData] = useState(defaultData);
  const [step, setStep] = useState(1); // Current step of the form
  const [isEditing, setIsEditing] = useState(false);
  const [services, setBanks] = useState([]); // Array to store services fetched from Firebase
  const [filteredBanks, setFilteredBanks] = useState([]); // Array to store filtered services based on search
  const [searchTerm, setSearchTerm] = useState(''); // State to store the search term
  const [selectedBank, setSelectedBank] = useState(''); // State to store the selected bank
  const [isBankListVisible, setIsBankListVisible] = useState(false); // State to manage visibility of bank list

  const [phone, setPhoneNumber] = useState(user ? user.phonenumber : '');
  const [balance, setBalance] = useState(user ? user.balance : '');
  const [amount, setWithdrawAmount] = useState('');
  const [recipientSC, setrecipientSC ] = useState('');
  const [inputtedaccountNumber, setaccountNumber] = useState('');

  const [isBankChosen, setIsBankChosen] = useState(false); // State to track if a bank is chosen
  const [bankName, setBankName] = useState(''); // State to store the selected bank name
  const [bankCodes, setBankCodes] = useState({}); // State to store bank codes
  const [isLoading, setIsLoading] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [response, setResponse] = useState(null);
  const [isManualForm,setManualForm] = useState(false);
  const [paymentreason, setPaymentReason] = useState('');

  // let balance;
  let amountWithoutDecimals;

  // useEffect(() => {
  //   fetchBalance(user, setBalance);
  // }, [user]);  
  useEffect(() => {
    if (user) {
      fetchBalance(user, setBalance);
    } 
  }, []);  
  
  useEffect(() => {
    if (!user) {
      navigate('/login'); // Navigate to the login page if user is not available
    }
  }, [user, navigate]);

  useEffect(() => {
    if (!user) {
      navigate('/login'); // Navigate to the login page if user is not available
    }
  }, [user, navigate]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const snapshot = await firebase.database().ref('services').once('value');
        const data = snapshot.val();
        if (data) {
          const bankNames = Object.values(data);
          const bankCodesObj = Object.keys(data).reduce((acc, key) => {
            acc[data[key]] = key; // Map bank names to bank codes
            return acc;
          }, {});
          setBanks(bankNames);
          setBankCodes(bankCodesObj);
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchBanks();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  useEffect(() => {
    // Initialize Fuse with the list of services
    const fuse = new Fuse(services, {
      keys: ['bank'],
      threshold: 0.4,
    });

    // Perform search whenever searchTerm changes
    const searchResults = fuse.search(searchTerm);
    setFilteredBanks(searchResults.map((result) => result.item));
  }, [searchTerm, services]);

  const PhoneSchema = Yup.object().shape({
    phone: Yup.string()
        .required('Phone number is required')
        .matches(/^(254)\d{9}$/, 'Phone number must start with 254 and be 12 digits long'),
  });

  const BankSchema = Yup.object().shape({
    accountNumber: Yup.string().required('Bank account is required'),
  });

  const methods = useForm({
    resolver: yupResolver(step === 1 ? PhoneSchema : BankSchema),
    defaultValues: defaultData,
  });

  const fetchBalance = async (user, setBalance) => {
    try {
      const data = await checkbalance(user);
      const bal = data.Account_Bal;
      setBalance(bal); // Set balance state
    } catch (error) {
      console.error(`Error fetching transactions: ${error}`);
    }
  };

  const handleManualFormTrue = () => {
    setManualForm(true); // Set state to true
  };

  const handleManualFormFalse = () => {
    setManualForm(false); // Set state to true
  };

  const ErrorComponent = ({ paymentError, handlePaymentErrorButtonClick }) => (
    <div style={{ textAlign: 'center' }}>
      <IoMdAlert size={24} color="red" />
      <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>An error occurred!</Typography>
      <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>{paymentError}</Typography>
      <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Try Again
      </Button>
    </div>
  );

  const ProcessingComponent = () => (
    // View when processing
    <Container style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // Ensures the container takes the full height of the viewport
    }}>
      <div style={{ textAlign: 'center' }}>
        <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
        <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Processing Payment...
        </Typography>
      </div>
    </Container>
  );  
  
  const SuccessComponent = ({ response, handleSuccessButtonClick,navigate }) => (
    <div style={{ textAlign: 'center' }}>
      <Checkmark size='96px' color='green' />
      {/* <Typography variant="body1" style={{ marginTop: 10,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>Deposit successful!</Typography> */}
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Transaction Id: {response.RotsiAPITransactionId}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        ResponseMessage: {response.ResponseMessage}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Service: {response.Service}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Recipient Bank Name: {response.BankName}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Transaction Amount: {response.TransactionAmount} KES
      </Typography>
      <Container>
        <Button onClick={() => navigate(`/download-receipt/${response.RotsiAPITransactionId}`)} variant="contained" style={{ marginTop: 20, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Generate Receipt
        </Button>
        <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}>
          Return to Dashboard
        </Button>
      </Container>
    </div>
  );  
  
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
    // Clear phoneError when the user starts typing again
    setPhoneError('');
  };

  const handleRecipientSCChange = (e) => {
    const { value } = e.target;
    setrecipientSC(value);
  };

  const handleAccountNumberChange = (e) => {
    const { value } = e.target;
    setaccountNumber(value);
  };
  
  const handleAmountChange = (e) => {
    const { value } = e.target;
    setWithdrawAmount(value);
    // Clear phoneError when the user starts typing again
    setError('');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
  
    const formattedPhoneNumber = formatPhone(phone);
    //  console.log(formattedPhoneNumber);
  
    // Validate phone number
    if (formattedPhoneNumber === null) {
      setPhoneError('Phone number must start with 254 and have a total of 12 digits.');
      setIsLoading(false);
      return;
    }
    const amountInt = parseInt(amount,10);
    
    // console.log(recipientSC);
    // const recipientCode = recipientSC;

    const requestBody = {
      phone:formattedPhoneNumber,
      amount: amountInt,
      recipientSC,
      accountNumber:inputtedaccountNumber,
      paymentreason
    };
  
    // const submitData = { ...formData, phone:formattedPhoneNumber, recipientSC: recipientCode, amount:amountInt };
    try {
      // Prepare the request body
      // console.log(requestBody);
  
      // console.log('Request Body:', requestBody);
      // Perform the withdrawal operation
      const responseData = await paybillPush(user, requestBody);
      setSuccess(true);
      setResponse(responseData);
      // Display success message and navigate to login page
      toast.success('Paybill Payment has been successfully initiated!');
      // navigate('/login');
    } catch (error) {
      // Display error message if withdrawal fails
      toast.error('Withdrawal failed. Please try again later.', error);
      setError(true);
      setPaymentError(true);
    } finally {
      setIsLoading(false);
      setIsEditing(false);
    }
  };

  const paybillNumber = process.env.REACT_APP_ROTSIPAYBILL;

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSuccessButtonClick = () => {
    setSuccess(false); // Set success state back to false
    navigate('/dashboard/app', { replace: true });
  };

  const handlePaymentReasonChange = (e) => {
    setPaymentReason(e.target.value);
  };

  const proceed = () => {
    if (step === 1) {
      const minimumBalance = parseInt(process.env.REACT_APP_MINIMUM_AMOUNT, 10);
      const amountInt = parseInt(amount, 10);
      amountWithoutDecimals = Math.trunc(amountInt);
      const balanceInt = parseInt(balance,10);

      // Calculate the minimum allowed balance after withdrawal
      const minimumAllowedBalance = balanceInt - minimumBalance;
      // console.log(amount);

      if (minimumBalance > balanceInt) {
        setError(`Kindly add more funds to the Rotsi wallet, to be able to make a Withdrawal from the account. Minimum Account Balance is ${minimumBalance} KES`);
        toast.error(`A minimum of ${minimumBalance} KES is required to maintain your account. Kindly add more funds to your wallet.`);
        // setIsSubmitting(false);
        return;
      }   

      if (!amountInt) {
        setError(`Minimum withdrawal amount is 10 KES`);
        toast.error(`Minimum withdrawal amount is 10 KES`);
        // setIsSubmitting(false);
        return;
      }  

      if (amountInt < 10) {
        setError(`Minimum withdrawal amount is 10 KES`);
        toast.error(`Minimum withdrawal amount is 10 KES`);
        // setIsSubmitting(false);
        return;
      }  

      if (amountInt > minimumAllowedBalance) {
        setError(`Withdrawal amount exceeds the allowed balance. You can withdraw up to ${minimumAllowedBalance} KES`);
        toast.error(`A minimum of ${minimumBalance} is required to maintain your account. You can withdraw up to ${minimumAllowedBalance} KES`);
        // setIsSubmitting(false);
        return;
      }   

      if (!paymentreason || paymentreason === '') {
        setError('Please select a payment reason.');
        // setIsSubmitting(false);
        return;
      }
  
      setStep(2); // Proceed to the next step if the phone number is valid
    }
  };  
  
  const handleBankSelect = (selectedBank) => {
    setSelectedBank(selectedBank); // Set the selected bank
    setIsBankListVisible(false); // Hide the bank list
    setBankName(selectedBank); // Set the bank name
    setIsBankChosen(true); // Set isBankChosen to true
  };

  const handleBankEdit = () => {
    setIsBankChosen(false); // Reset bank selection
    setFormData({ ...formData, accountNumber: '' }); // Clear bank account field
  };
  
  const formSubmit = async (data) => {
    try {
      setIsLoading(true);
      const bankCode = bankCodes[bankName]; // Get bank code from bank name
      const { searchservice, ...newData } = data;

      const formattedPhoneNumber = formatPhone(phone);
      // console.log(formattedPhoneNumber);
  
      // Validate phone number
      if (formattedPhoneNumber === null) {
        setPhoneError('Phone number must start with 254 and have a total of 12 digits.');
        setIsLoading(false);
        return;
      }
      // console.log(amount);

      const amountInt = parseInt(amount,10);

      const submitData = { ...formData, ...newData, phone:formattedPhoneNumber, bank: bankName, servicecode: bankCode,amount:amountInt,paymentreason };
      try {
        // console.log(submitData)
        const response = await m2sPush(user,submitData);
        setSuccess(true);
        setResponse(response);
        // Display suc/cess message and navigate to login page
        toast.success('Bill Payment Successful!');
        // navigate('/dashboard/app');
      } catch (error) {
        if(error.message){
          setError(true);
          setPaymentError(error.message);
        }else{
          setError(true);
          setPaymentError('Withdrawal failed. Please try again later.');
        }
      } finally {
        setIsLoading(false);
        setIsEditing(false);
      }

    } catch (error) {
      // Handle submission error
      // console.error('Submission failed', error);
      toast.error('Submission failed. Please try again later.');
    }
  };

  const onSubmit = handleSubmit(formSubmit); // Call formSubmit when form is submitted

  return (
    <Page title="Rotsi to Mpesa Bank Withdrawals">
      <Container>
      {isLoading && <ProcessingComponent />}
      {paymentError && <ErrorComponent paymentError={paymentError} handlePaymentErrorButtonClick={() => setPaymentError('')} />}
      {success && <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick} navigate={navigate} />}
      {!isSubmitting && !success && !paymentError && (
        <div>
        <Typography variant="h4" gutterBottom sx={{ marginBottom: 5, color: 'black',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Easily withdraw money from your Rotsi Wallet, straight to your Business account of your Service Provider in Kenya.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Withdraw from Rotsi Wallet to make payment
        </Typography>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={3}>
        {step === 1 && (
          <>
            {/* <Typography variant="h5" gutterBottom sx={{ marginBottom: 2 }}>
              Make Payment
            </Typography> */}
            <RHFTextField 
                  name="amount" 
                  label="Amount you wish to pay"
                  variant="outlined"
                  value={amount}
                  sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                  type="number"
                  onChange={handleAmountChange}
            />
            
            <FormControl fullWidth margin="normal" error={Boolean(error)}>
              <Select
                value={paymentreason}
                onChange={handlePaymentReasonChange}
                displayEmpty
              >
                <MenuItem value="" disabled>Select payment reason</MenuItem>
                <MenuItem value="Service Fee">Service Fee</MenuItem>
                <MenuItem value="Product Purchase">Product Purchase</MenuItem>
                <MenuItem value="Commission Payment">Commission Payment</MenuItem>
                <MenuItem value="Contract Settlement">Contract Settlement</MenuItem>
                <MenuItem value="Royalties Due">Royalties Due</MenuItem>
                <MenuItem value="Refund Issuance">Refund Issuance</MenuItem>
                <MenuItem value="Salary Payment">Salary Payment</MenuItem>
                <MenuItem value="Supplier Payment">Supplier Payment</MenuItem>
                <MenuItem value="Loan Repayment">Loan Repayment</MenuItem>
                <MenuItem value="Utility Bills">Utility Bills</MenuItem>
                <MenuItem value="Rent Payment">Rent Payment</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
              {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
            
            <LoadingButton fullWidth size="large" onClick={proceed} sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="contained">
              Proceed
            </LoadingButton>
          </>
        )}
        {step === 2 && (
          <>
            {!isManualForm && (
              <>
                <Container>
                 <Typography variant="p" container="p" gutterBottom sx={{ marginBottom: 1,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                   Click the switch button to manually input your paybill incase its not in our service search list. 
                  </Typography>
                  <IconButton onClick={handleManualFormTrue} sx={{ marginBottom: 0.5 }}>
                    <SwapHoriz /> 
                  </IconButton>
                </Container>
               
                {/* <Typography variant="h6" container="h6" gutterBottom sx={{ marginBottom: 0.5,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                    Search the service, from a list of over 15 Payment Services.
                </Typography> */}
                <RHFTextFieldImage
                  name="searchservice"
                  label="Search the service, from a list of over 15 Payment Services."
                  value={searchTerm}
                  onFocus={() => setIsBankListVisible(true)}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ display: isBankChosen ? 'none' : 'block' }} // Hide the field if a bank is chosen
                  sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                />
                {isBankListVisible && (
                  <List>
                    {filteredBanks.map((bank) => (
                      <ListItem key={bank} button onClick={() => handleBankSelect(bank)}>
                        <ListItemText primary={bank} />
                      </ListItem>
                    ))}
                  </List>
                )}
                {isBankChosen && (
                  <>
                    <RHFTextField
                      name="bank"
                      label="Service Name"
                      value={selectedBank}
                      sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                      onChange={(e) => setSelectedBank(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Edit Service Name" placement="top">
                              <IconButton
                                onClick={handleBankEdit}
                                edge="end"
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      autoFocus
                    />
                    <RHFTextField name="accountNumber" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Account Number of the service" />
                    <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}>
                        <TextField
                          label="Phone Number"
                          onChange={handlePhoneChange}
                          type="number"
                          placeholder="07XXXXXXXX"
                          value={phone}
                          sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                        />
                    </FormControl>
                  </>
                )}
                {!isBankChosen && (
                  <div>
                    <RHFTextField
                      name="accountNumber"
                      label="Bank Account"
                      value={selectedBank}
                      onChange={(e) => setSelectedBank(e.target.value)}
                      style={{ display: isBankChosen ? 'block' : 'none' }} 
                      sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                    />

                  </div>
                )}
                <LoadingButton 
                  fullWidth size="large" 
                  type="submit" variant="contained" 
                  loading={isSubmitting}
                  style={{ display: isBankChosen ? 'block' : 'none' }} 
                  sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                >
                  Make Payment
                </LoadingButton>
              </>
            )}
            {isManualForm && (
              <>
              {/* <form onSubmit={handleFormSubmit}> */}
              <Container>
                  <Typography 
                    variant="p" 
                    container="p" 
                    gutterBottom 
                    sx={{ marginBottom: 1,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                  >
                   Click the switch button to search from our list. 
                  </Typography>
                  <IconButton onClick={handleManualFormFalse}>
                    <SwapHoriz /> 
                  </IconButton>
                </Container>

                <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}>
                  <TextField
                    label="Paybill of the service"
                    onChange={handleRecipientSCChange}
                    type="number"
                    placeholder="Paybill of the service"
                    value={recipientSC}
                    sx={{marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                  />
                  
                  <TextField
                    onChange={handleAccountNumberChange} 
                    value={inputtedaccountNumber}
                    sx={{marginBottom: 2, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} 
                    label="Account Number of the service"
                    placeholder="Account Number of the service"
                  />
                  
                  <TextField
                    label="Phone Number"
                    onChange={handlePhoneChange}
                    type="number"
                    placeholder="07XXXXXXXX"
                    value={phone}
                    sx={{marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                  />
                
                <LoadingButton 
                  variant="contained" 
                  onClick={handleFormSubmit} 
                  sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? `Depositing to ${recipientSC}`: 'Make Payment'}
                </LoadingButton>
              </FormControl>
              </>
            )}
          </>
        )}
      </Stack>
    </FormProvider>
    </div>
    )}
    </Container>
  </Page>
  );
}