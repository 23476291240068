import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify'; // Moved the import here

import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useUserContext } from '../../../UserContext'; 
import { forgotPassword } from '../../../apis/auth';

const defaultData = {
  email: '',
};

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(defaultData);
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false); // State to track form submission

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const formSubmit = async (data) => {
    setIsSubmittingForm(true); // Set form submission to true
    setIsLoading(true);
    try {
      await forgotPassword(data);
      setEmailSent(true);
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
      setIsSubmittingForm(false); // Set form submission to false after submission is complete
    }
  };

  const onSubmit = methods.handleSubmit(formSubmit);

  return (
    <>
      {!emailSent ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ marginBottom: 2 }}>
            <RHFTextField name="email" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Email address" />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
            type="submit"
            variant="contained"
            loading={isSubmitting || isLoading} // Consider both isLoading and isSubmitting
            disabled={isSubmittingForm} // Disable the button while the form is submitting
          >
            Send Reset Link
          </LoadingButton>
        </FormProvider>
      ) : (
        <Stack spacing={2}>
          <Typography variant="body1" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} color="success">
            Password reset link sent successfully.
          </Typography>
          <LoadingButton
            fullWidth
            size="large"
            sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
            variant="contained"
            onClick={() => navigate('/login')}
          >
            Go to LoginPage
          </LoadingButton>
        </Stack>
      )}
    </>
  );
}