import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { PhoneDetailsForm } from '../sections/auth/PhoneDetails';
// import AuthSocial from '../sections/auth/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const minimumBalance = process.env.REACT_APP_MINIMUM_AMOUNT;

// ----------------------------------------------------------------------

export default function PhoneDetails() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  
  return (
    <Page title="PhoneDetails">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              Hi, Welcome Back
            </Typography>
            <img src="/static/illustrations/illustration_login2.png" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
              Enter your details below to be able to withdraw from your account
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 3,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              We will require your phone number to get your withdrawal details. You can withdraw at any time.
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 5,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              A minimum of Ksh.{minimumBalance} is required for account maintenance.
            </Typography>

            <PhoneDetailsForm />

          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}