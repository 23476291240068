import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import { Container, Typography, Stack, CircularProgress, Dialog, Backdrop,Link,Checkbox,FormControlLabel } from '@mui/material';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { LoginForm, RegisterForm } from './AuthForm'; // Import the login and register forms
import Page from '../components/Page';
import { useUserContext } from '../UserContext';
import { getinvitedetails,acceptinvite,alertsender } from '../apis/escrowapis';

export default function Alertsender({ location: propLocation }) {
    const { user } = useUserContext();
    const location = useLocation();
    const [inviteLink, setInviteLink] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [taskDetails, setTaskDetails] = useState(null); // State to hold task details
    const [showLogin, setShowLogin] = useState(true);
    const [showRegister, setShowRegister] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const inviteLinkParam = searchParams.get('alertbuyer');
        if (!inviteLinkParam) {
            navigate('/dashboard/app');
        }
        setInviteLink(inviteLinkParam);

        // // Fetch task details when the component mounts
        // const fetchTaskDetails = async () => {
        //     try {
        //         const taskDetails = await getinvitedetails(user, { invitelink: inviteLinkParam });
        //         setTaskDetails(taskDetails);
        //     } catch (error) {
        //         console.error('Error fetching task details:', error);
        //         // Handle error here
        //     }
        // };

        // fetchTaskDetails(); // Call the function to fetch task details

    }, [location.search, navigate, inviteLink, user]);

    const handleLogin = () => {
        setShowLogin(false);
    };

    const handleRegister = () => {
        setShowRegister(false);
    };

    const handleLoginClick = () => {
        setShowLogin(true);
        setShowRegister(false);
    };

    const handleRegisterClick = () => {
        setShowRegister(true);
        setShowLogin(false);
    };
    const handleConfirmEscrowCreation = async () => {
      setIsSubmitting(true);

      try {
        //   console.log(inviteLink);
        //   console.log(user);
          // Call the acceptinvite API with user and inviteLink
          await alertsender(user, inviteLink);
          navigate('/dashboard/manageescrows')
          // Display success message
          toast.success('Escrow Creation Confirmed!');
      } catch (error) {
          // Display error message
          toast.error('Failed to confirm Escrow Creation. Please try again later.');
      } finally {
          setIsSubmitting(false);
      }
  };


    return (
        <Page title="Rotsi Escrow Invite">
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                {user ? (
                    // Render escrow invite content if user is logged in
                    <Stack spacing={2}>
                        {/* Render task details if available */}
                        {taskDetails && (
                            <>
                                <Typography variant="h6">Item name : {taskDetails.item_name}</Typography>
                                <Typography variant="body1">Item Description: {taskDetails.item_description}</Typography>
                                <Typography variant="body1">{taskDetails.item_name} Price: {taskDetails.amount}</Typography>
                                <Typography variant="body1">Created At: {taskDetails.created_at}</Typography>
                                {/* <Typography variant="body1">Goods Distributor: {taskDetails.sender_username}</Typography> */}
                                <Typography variant="body1">Goods Distributor: {taskDetails.sender_companyname}</Typography>
                            </>
                        )}

                        <FormControlLabel
                            control={<Checkbox checked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} />}
                            label={<Typography variant="body2" color="textSecondary" sx={{ display: 'inline' }}>Alert Buyer</Typography>}
                        />
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            disabled={isSubmitting || !termsAccepted}
                            onClick={handleConfirmEscrowCreation} // Call handleConfirmEscrowCreation when button is clicked
                        >
                            {isSubmitting ? 'Confirming ...' : 'Confirm Escrow Creation'}
                        </LoadingButton>
                    </Stack>
                ) : (
                    <Stack spacing={4}>
                        {showLogin && <LoginForm onRegisterClick={handleRegisterClick} onLogin={handleLogin} />}
                        {showRegister && <RegisterForm onLoginClick={handleLoginClick} />}
                    </Stack>
                )}
            </Container>
        </Page>
    );
}