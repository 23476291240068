import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, Typography, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { resetPassword } from '../../../apis/auth';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

export default function ResetPasswordForm({ location: propLocation }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });
  const { handleSubmit, formState: { isSubmitting, errors } } = methods;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const resetToken = searchParams.get('token');
    if (!resetToken) {
      navigate('/login'); // If reset token is absent, navigate to login page
    }
  }, [location.search, navigate]);

  const handleFormSubmit = async ({ password }) => {
    setIsSubmittingForm(true);
    setIsLoading(true);
    try {
      const searchParams = new URLSearchParams(location.search);
      const resetToken = searchParams.get('token');
      const submitData = { token: resetToken, newPassword: password };
      await resetPassword(submitData);
      navigate('/login');
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
      setIsSubmittingForm(false);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing={2}>
        <RHFTextField
          name="password"
          label="New Password"
          sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
        <RHFTextField
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword?.message}
        />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          loading={isSubmitting || isLoading}
          disabled={isSubmittingForm}
        >
          Reset Password
        </LoadingButton>
        <Link to="/login" component={RouterLink} sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="subtitle2" underline="hover">
          Go back to login
        </Link>
      </Stack>
    </FormProvider>
  );
}