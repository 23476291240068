import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, IconButton, InputAdornment, FormControl, RadioGroup, Radio, FormControlLabel,Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useUserContext } from '../../../UserContext';
import { registerUser } from '../../../apis/auth';

const RegisterForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralcode = searchParams.get('referralcode');
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ username: '', email: '', password: '', company_name: '', phonenumber: '', registrationType: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [phoneFormatError, setPhoneFormatError] = useState('');
  const { setUserDetails } = useUserContext();

  const defaultData = {
    username: '',
    email: '',
    password: '',
    company_name: '',
    phonenumber: '',
  };
  const clearPhoneErrors = () => {
    setPhoneFormatError('');
  };

  const RegisterSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    company_name: Yup.string().when('registrationType', {
      is: 'businessclients',
      then: Yup.string().required('Company Name is required'),
      otherwise: Yup.string(),
    }),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    phonenumber: Yup.string().when('registrationType', {
      is: 'businessclients',
      then: Yup.string().required('Phone number is required'),
      otherwise: Yup.string(),
    }),
  });

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const formatPhone = (phone) => {
    phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    }else if (phone.startsWith('11') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
  };  

  const validateAndFormatPhoneNumber = (phone) => {
    if (/^\254\d{8}$/.test(phone)) {
        return phone;
    } 
    if (/^07\d{8}$/.test(phone)) {
        return phone;
    } 
    if (/^01\d{8}$/.test(phone)) {
        return phone;
    }
    setPhoneFormatError('Phone number must start with +254, 07, or 011 and have the specified number of digits.');
    return false;
  };

  const handleOnSubmit = async (formData) => {
    setIsLoading(true);

    // Validate and format the phone number
    const formattedPhone = validateAndFormatPhoneNumber(formData.phonenumber);
    if (formattedPhone === false) {
      setIsLoading(false);
      return; // Exit early if phone number is invalid
    }
    const formattedPhoneNumber = formatPhone(formattedPhone);
    // Update the formData with the formatted phone number

    // Capitalize the first letter of the username and lowercase the rest
    const formattedUsername = formData.username.charAt(0).toUpperCase() + formData.username.slice(1).toLowerCase();

    // Update the formData with the formatted phone number and username
    const updatedFormData = { ...formData, phonenumber: formattedPhoneNumber, username: formattedUsername };

    try {
      // console.log(updatedFormData);
      if (formData.registrationType === 'individualclients') {
        const response = await registerUser(updatedFormData);
        setUserDetails(response.data.user);
        navigate('/dashboard/app');
      } else if (formData.registrationType === 'businessclients') {
        const businessData = {...updatedFormData, referralcode: referralcode || null };
        navigate('/business-onboarding', { state: businessData });
      } else if(formData.registrationType === 'freelancers'){
        const freelancersData = {...updatedFormData, company_name: `Freelancer_${formattedUsername}`};
        const response = await registerUser(freelancersData);
        setUserDetails(response.data.user);
        navigate('/dashboard/app');
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues: { ...defaultData, registrationType: '' },
  });


  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;

  const IndividualClientForm = ({
    formData,
    setValue,
    handleOnChange,
    handleSubmit,
    isSubmitting,
    clearPhoneErrors,
    phoneFormatError,
    showPassword,
    setShowPassword,
  }) => {
    return (
      <>
        <IconButton edge="start" onClick={() => setFormData({ ...formData, registrationType: '' })}>
            <Iconify icon="eva:arrow-back-outline" />
        </IconButton>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="username" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Username" />
          <RHFTextField name="company_name" label="Your Venture name" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} placeholder="Venture name" />
        </Stack>
        <RHFTextField name="email" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Email address" />
        <RHFTextField
          name="password"
          label="Password"
          sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField name="confirmPassword" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Confirm Password" type="password" />
        <RHFTextField
          name="phonenumber"
          label="Phone Number"
          type="number"
          onFocus={clearPhoneErrors}
          error={!!phoneFormatError}
          helperText={phoneFormatError}
        />
        <LoadingButton fullWidth size="large" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} type="submit" variant="contained" loading={isSubmitting}>
          Register
        </LoadingButton>
      </>
    );
  };

  const FreeLancersForm = ({
    formData,
    setValue,
    handleOnChange,
    handleSubmit,
    isSubmitting,
    clearPhoneErrors,
    phoneFormatError,
    showPassword,
    setShowPassword,
  }) => {
    return (
      <>
        <IconButton edge="start" onClick={() => setFormData({ ...formData, registrationType: '' })}>
            <Iconify icon="eva:arrow-back-outline" />
        </IconButton>
        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}
          <RHFTextField name="username" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Username" />
          {/* <RHFTextField name="company_name" label="Your Venture name" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} placeholder="Venture name" /> */}
        {/* </Stack> */}
        <RHFTextField name="email" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Email address" />
        <RHFTextField
          name="password"
          label="Password"
          sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField name="confirmPassword" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Confirm Password" type="password" />
        <RHFTextField
          name="phonenumber"
          label="Phone Number"
          type="number"
          onFocus={clearPhoneErrors}
          error={!!phoneFormatError}
          helperText={phoneFormatError}
        />
        <LoadingButton fullWidth size="large" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} type="submit" variant="contained" loading={isSubmitting}>
          Register
        </LoadingButton>
      </>
    );
  };

  const BusinessClientForm = ({
    formData,
    setValue,
    handleOnChange,
    handleSubmit,
    isSubmitting,
    clearPhoneErrors,
    phoneFormatError,
    showPassword,
    setShowPassword,
  }) => {
    return (
      <>
        <IconButton edge="start" onClick={() => setFormData({ ...formData, registrationType: '' })}>
            <Iconify icon="eva:arrow-back-outline" />
        </IconButton>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="username" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Username" />
          <RHFTextField name="company_name" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Registered Company name" placeholder="Company name" />
        </Stack>
        <RHFTextField name="email" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Email address" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField name="confirmPassword" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Confirm Password" type="password" />
        <RHFTextField
          name="phonenumber"
          label="Phone Number"
          type="number"sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          onFocus={clearPhoneErrors}
          error={!!phoneFormatError}
          helperText={phoneFormatError}
        />
        <LoadingButton fullWidth size="large" type="submit" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="contained" loading={isSubmitting}>
          Continue with Onboarding
        </LoadingButton>
      </>
    );
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleOnSubmit)}>
      <Stack spacing={3}>
        {!formData.registrationType && (
          <FormControl component="fieldset">
           <Typography variant="h6" gutterBottom sx={{ marginBottom: 3, color: 'black',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Choose Business Option if you would like to elevate your current business experience, with your active Mpesa paybill or Till Number, and wish to receive  directly to it.
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ marginBottom: 3, color: 'black',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Sign up as a Demo client, while you would like to test our application. In the end, we hope you choose to Rotsi as your Payments, SMS and Data Analytics partner as you elevate your business experience.
          </Typography>
          {/* <Typography variant="h6" gutterBottom sx={{ marginBottom: 3, color: 'black',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Sign up as a Freelancers, if you would like to receive funds and make payments straight from your Secured Rotsi Wallet.  
          </Typography> */}

            <RadioGroup
              aria-label="registrationType"
              name="registrationType"
              sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              value={formData.registrationType}
              onChange={(e) => {
                setValue('registrationType', e.target.value);
                setFormData({ ...formData, registrationType: e.target.value });
              }}
            >
              <FormControlLabel value="individualclients" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} control={<Radio />} label="Demo Clients" />
              {/* <FormControlLabel value="freelancers" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} control={<Radio />} label="Freelancers" /> */}
              <FormControlLabel value="businessclients" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} control={<Radio />} label="Business" />
            </RadioGroup>
          </FormControl>
        )}

        {formData.registrationType === 'individualclients' && (
          <IndividualClientForm
            formData={formData}
            setValue={setValue}
            handleOnChange={handleOnChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            clearPhoneErrors={clearPhoneErrors}
            phoneFormatError={phoneFormatError}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        )}

        {formData.registrationType === 'freelancers' && (
          <FreeLancersForm
            formData={formData}
            setValue={setValue}
            handleOnChange={handleOnChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            clearPhoneErrors={clearPhoneErrors}
            phoneFormatError={phoneFormatError}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        )}

        {formData.registrationType === 'businessclients' && (
          <BusinessClientForm
            formData={formData}
            setValue={setValue}
            handleOnChange={handleOnChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            clearPhoneErrors={clearPhoneErrors}
            phoneFormatError={phoneFormatError}
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        )}
      </Stack>
    </FormProvider>
  );
};

export default RegisterForm;