// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig2 = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Deposits & Pay-ins  ',
    icon: getIcon('eva:settings-2-fill'),
    children: [
      {
        title: 'Receive via Stk',
        path: '/dashboard/stkpayments',
        icon: getIcon('eva:people-fill'),
        // material-symbols--send-money-rounded
      },
      // {
      //   title: 'Scan to Pay(QRCode)',
      //   path: '/dashboard/qrcodepayments',
      //   icon: getIcon('bitcoin-icons--pantheon-outline'),
      //   // fxemoji--bank
      // },
      {
        title: 'Generate Payment Link',
        path: '/dashboard/generatepaymentlink',
        icon: getIcon('eva:people-fill'),
      },
    ],
  },
  {
    title: 'Pay-Outs',
    icon: getIcon('eva:settings-2-fill'),
    children: [
      {
        title: 'Straight To Phone',
        path: '/dashboard/b2cwithdrawals',
        icon: getIcon('eva:people-fill'),
        // material-symbols--send-money-rounded
      },
      {
        title: 'Straight To Bank',
        path: '/dashboard/m2bwithdrawals',
        icon: getIcon('bitcoin-icons--pantheon-outline'),
        // fxemoji--bank
      },
    ],
  },
  {
    title: 'Pay Bills',
    icon: getIcon('eva:settings-2-fill'),
    children: [
      // {
      //   title: 'Pay Light Bills',
      //   path: '/dashboard/kplclightbill',
      //   icon: getIcon('eva:people-fill'),
      //   // material-symbols--send-money-rounded
      // },
      {
        title: 'Make Bill Payment',
        path: '/dashboard/otherbills',
        icon: getIcon('bitcoin-icons--pantheon-outline'),
        // fxemoji--bank
      },
    ],
  },
  // {
  //   title: 'Pay Taxes',
  //   icon: getIcon('eva:settings-2-fill'),
  //   children: [
  //     {
  //       title: 'Remit Kra Taxes',
  //       path: '/dashboard/kraremittax',
  //       icon: getIcon('eva:people-fill'),
  //       // material-symbols--send-money-rounded
  //     },
  //   ],
  // },
  {
    title: 'SMS Services',
    icon: getIcon('eva:settings-2-fill'),
    children: [
      {
        title: 'Create Bulk SMS',
        path: '/dashboard/bulksms',
        icon: getIcon('eva:people-fill'),
        // material-symbols--send-money-rounded
      },
      // {
      //   title: 'Check Scheduled SMS',
      //   path: '/dashboard/products',
      //   icon: getIcon('bitcoin-icons--pantheon-outline'),
      //   // fxemoji--bank
      // },
    ],
  },
  {
    title: 'ᴠɪᴘ SERVICES 💎',
    icon: getIcon('eva:settings-2-fill'),
    children: [
      {
        title: 'Make Bulk payments.',
        path: '/dashboard/bulkpayments',
        icon: getIcon('eva:people-fill'),
        // material-symbols--send-money-rounded
      },
      // {
      //   title: 'Setup Standing Order',
      //   path: '/business-dashboard/standingorders',
      //   icon: getIcon('bitcoin-icons--pantheon-outline'),
      //   // fxemoji--bank
      // },
      // {
      //   title: 'Update Standing Order',
      //   path: '/business-dashboard/recurring-payments-dashboard',
      //   icon: getIcon('bitcoin-icons--pantheon-outline'),
      //   // fxemoji--bank
      // },
    ],
  },
  {
    title: 'Transfer Funds',
    icon: getIcon('eva:settings-2-fill'),
    children: [
      {
        title: 'Transfer Funds',
        path: '/dashboard/transferfunds',
        icon: getIcon('eva:people-fill'),
        // material-symbols--send-money-rounded
      },
      // {
      //   title: 'Setup Standing Order',
      //   path: '/business-dashboard/standingorders',
      //   icon: getIcon('bitcoin-icons--pantheon-outline'),
      //   // fxemoji--bank
      // },
      // {
      //   title: 'Update Standing Order',
      //   path: '/business-dashboard/recurring-payments-dashboard',
      //   icon: getIcon('bitcoin-icons--pantheon-outline'),
      //   // fxemoji--bank
      // },
    ],
  },
  // {
  //   title: 'Escrow Services',
  //   // <use href="#lets-icons--wallet-duotone-line" />
  //   icon: getIcon('eva:settings-2-fill'),
  //   children: [
  //     {
  //       title: 'Create Escrow Wallet',
  //       path: '/dashboard/escrowcreation',
  //       icon: getIcon('eva:people-fill'),
  //     },
  //     {
  //       title: 'Manage Escrow Wallets',
  //       path: '/dashboard/manageescrows',
  //       icon: getIcon('bitcoin-icons--pantheon-outline'),
  //     },
  //   ],
  // },
];

export default navConfig2;