import axios from 'axios';
import { toast } from 'react-toastify';

const url = process.env.REACT_APP_SERVER_URL;

// eslint-disable-next-line camelcase
const GetTransactionsAPI = (secretKey, username) => {
  return axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': secretKey,
      'Username': username, 
    },
  });
};

export const alltransactions = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
     // eslint-disable-next-line camelcase

    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/auth/getAllResults');
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const allStafftransactions = async (user) => {
  try {
     // eslint-disable-next-line camelcase
     const { lastname, firstname, email } = user;

   
     const { data } = await axios.post(`${url}/auth/getAllStaffResults`, { lastname, firstname, email });
     // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const b2ctransactions = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
     // eslint-disable-next-line camelcase
    // console.log('Secret Key:', secretKey);
    // console.log('Username:', username);

     // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/auth/getTransactionsB2C');
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const c2btransactions = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
     // eslint-disable-next-line camelcase
    // console.log('Secret Key:', secretKey);
    // console.log('Username:', username);

     // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/auth/getTransactionsC2B');
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const checkbalance = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
  
    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/balance/accountBalance');
    
    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const mmfbalance = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
  
    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/balance/mmfBalance');

    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const aiCreditsbalance = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
  
    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/balance/aicreditsBalance');

    // console.log('AI Credits Balance:', data.Account_Bal);

    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const SMSbalance = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
  
    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/balance/smsBalance');

    // console.log('AI Credits Balance:', data.Account_Bal);

    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const checkbalanceforStaff = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    // const { secretKey, username } = user;
    const { lastname, firstname, email } = user;
  
    // eslint-disable-next-line camelcase
    // const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    const { data } = await axios.post(`${url}/balance/accountBalanceforStaff`, { lastname, firstname, email });

    // const { data } = await PaymentsAPIInstance.get('/balance/accountBalance');
    
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const checkStaffBalance = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    // const { secretKey, username } = user;
    const { lastname, firstname, email } = user;
  
    // eslint-disable-next-line camelcase
    // const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    const { data } = await axios.post(`${url}/balance/staffBalance`, { lastname, firstname, email });

    // const { data } = await PaymentsAPIInstance.get('/balance/accountBalance');
    
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const mmfbalanceforStaff = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { lastname, firstname, email } = user;
  
    // const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    const { data } = await axios.post(`${url}/balance/mmfBalanceforStaff`, { lastname, firstname, email });
    
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const failedtransactions = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
     // eslint-disable-next-line camelcase
    // console.log('Secret Key:', secretKey);
    // console.log('Username:', username);

    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/auth/getTransactionsFailed');
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};
export const paybilltransactions = async (user) => {
  try {
    // eslint-disable-next-line camelcase
    const { secretKey, username } = user;

    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    const { data } = await PaymentsAPIInstance.get(`${url}/auth/getTransactionsPaybill`);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const buygoodstransactions = async (user) => {
  try {
    // eslint-disable-next-line camelcase
    const { secretKey, username } = user;

    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    const { data } = await PaymentsAPIInstance.get(`${url}/auth/getTransactionsBuygoods`);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const kratransactions = async (user) => {
  try {
    // eslint-disable-next-line camelcase
    const { secretKey, username } = user;

    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    const { data } = await PaymentsAPIInstance.get(`${url}/auth/getTransactionsKra`);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const m2btransactions = async (user) => {
  try {
    // eslint-disable-next-line camelcase
    const { secretKey, username } = user;

    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    const { data } = await PaymentsAPIInstance.get(`${url}/auth/getTransactionsM2b`);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};