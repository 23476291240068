// routes
import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from './routes';
import { UserProvider } from './UserContext';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import noInternetImage from './assets/nointernet.png';

// ----------------------------------------------------------------------
const NoInternetPage = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: '#f0f0f0' }}>
      <div style={{ textAlign: 'center' }}>
        <img src={noInternetImage} alt="No Internet" style={{ width: '200px', marginBottom: '20px' }} />
        <h1>No Internet Connection</h1>
        <p>Please check your network settings and try again.</p>
      </div>
    </div>
  );
};

export default function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
   useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  return (
    <UserProvider>
      <ThemeProvider>
        <div className="bg-[#FFFFFF]">
          <ToastContainer />
          <ScrollToTop />
          <BaseOptionChartStyle />
          {isOnline ? <Router /> : <NoInternetPage />}
        </div>
      </ThemeProvider>
    </UserProvider>
  );
}
