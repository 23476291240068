import React, { useState } from 'react';
import { Navigate, useNavigate, Link as RouterLink } from 'react-router-dom';
import { Container, Typography, FormControl, Select, MenuItem, Button } from '@mui/material';
import Page from '../../components/Page';
import BulkB2c from './BulkPayments/Bulkb2c/BulkB2C'; 
import BulkBuygoods from './BulkPayments/BulkBuygoods/BulkBuygoods'; 
import BulkPaybill from './BulkPayments/BulkPaybill/BulkPaybill';
import BulkM2b from './BulkPayments/Bulkm2b/Bulkm2b'; 
import { useUserContext } from '../../UserContext';
import UpgradeToVIP from '../UpgradeToVIP';
import Login from '../Login';

export default function BulkPayments() {
  const [bulkPaymentType, setBulkPaymentType] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const { user } = useUserContext();

  if (!user) {
    return <Navigate to="/login" />; // Use Navigate to redirect to the login page
  }

  const handleBulkPaymentTypeChange = (event) => {
    setBulkPaymentType(event.target.value);
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  const getTemplateDownloadLink = () => {
    let templateLink = '';
    switch (bulkPaymentType) {
      case 'bulkPaymentsToPhone':
        templateLink = 'https://dashboard.rotsi.co.ke/templates/B2C-Bulk-Template.xlsx';
        break;
      case 'bulkBuyGoodsPayments':
        templateLink = 'https://dashboard.rotsi.co.ke/templates/B2B-Bulk-Template.xlsx';
        break;
      case 'bulkPaymentsToBank':
        templateLink = 'https://dashboard.rotsi.co.ke/templates/M2B-Bulk-Template.xlsx';
        break;
      case 'bulkPayBillPayments':
        templateLink = 'https://dashboard.rotsi.co.ke/templates/B2B-Bulk-Template.xlsx';
        break;
      default:
        break;
    }
    return templateLink;
  };  

  const renderContent = () => {
    switch (bulkPaymentType) {
      case 'bulkPaymentsToPhone':
        return <BulkB2c />;
      case 'bulkBuyGoodsPayments':
        return <BulkBuygoods />;
      case 'bulkPaymentsToBank':
        return <BulkM2b />;
      case 'bulkPayBillPayments':
        return <BulkPaybill />;
      default:
        return null;
    }
  };
  
  return (
    <Page title="Bulk Payments">
      <Container>
        <div>
              {!isSubmitted && (
                <div>
                  <Typography variant="h4" gutterBottom sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                    Step 1: Choose the type of bulk payment to make:
                  </Typography>
                  <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                    <Select
                      value={bulkPaymentType}
                      onChange={handleBulkPaymentTypeChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Bulk Payment Type' }}
                    >
                      <MenuItem value="">Select Bulk Payment Type</MenuItem>
                      <MenuItem value="bulkPaymentsToPhone">Bulk Payments to Phone</MenuItem>
                      <MenuItem value="bulkPaymentsToBank">Bulk Payments to Bank</MenuItem>
                      {/* <MenuItem value="bulkBuyGoodsPayments">Bulk BuyGoods Payments</MenuItem> */}
                      <MenuItem value="bulkPayBillPayments">Bulk Pay Bill Payments</MenuItem>
                    </Select>
                  </FormControl>
                  {bulkPaymentType && (
                    <Container>
                    <Typography
                      variant="body1"
                      component="a"
                      href={getTemplateDownloadLink()} // Use getTemplateDownloadLink to directly get the template link
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ marginTop: 2, cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                    >
                      Download Template File
                    </Typography>
                    </Container>
                  )}
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!bulkPaymentType}
                    sx={{ marginTop: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                  >
                    Submit
                  </Button>
                </div>
              )}
              {isSubmitted && renderContent()}
        </div>
      </Container>
    </Page>
  );
}