import React, { useState } from 'react';
import { useNavigate, Navigate,Link as RouterLink } from 'react-router-dom';
import { Container, Typography, TextField, Button, Link, Checkbox, FormControl, FormControlLabel, RadioGroup, Radio,MenuItem,InputLabel,Select,Card,CardContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { toast } from 'react-toastify'; // Import toast library
import Page from '../components/Page';
import { useUserContext } from '../UserContext';
import { escrowcreation } from '../apis/escrowapis'; // Import escrow creation function

export default function EscrowCreation() {
  const [formData, setFormData] = useState({
    amount: '',
    item: '',
    itemDescription: '',
    termsAccepted: false
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState(null); // State to hold response data
  const { user } = useUserContext();
  
  // Redirect to login if user is not logged in
  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const responseData = await escrowcreation(user, formData);
      setResponse(responseData); // Store response data
    } catch (error) {
      toast.error('Failed to create escrow');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'termsAccepted' ? checked : value
    }));
  };

  const handleNextStep = () => {
    if (currentStep === 1) {
      // Check if role is selected
      if (!formData.role) {
        toast.error('Please select a role');
        return;
      }
    } else if (currentStep === 2) {
      // Check if item and item description are provided
      if (!formData.item) {
        toast.error('Please provide the item name');
        return;
      }
      if (!formData.itemDescription) {
        toast.error('Please provide the item description');
        return;
      }
    } else if (currentStep === 3) {
      // Check if amount is provided
      if (!formData.amount) {
        toast.error('Please provide the amount');
        return;
      }
    } 
    // else if (currentStep === 4) {
    //   // Check if release condition is provided
    //   if (!formData.releaseCondition) {
    //     toast.error('Please provide the release condition');
    //     return;
    //   }
    // }
     else if (currentStep === 4) {
      // Check if terms are accepted
      if (!formData.termsAccepted) {
        toast.error('Please accept the terms and conditions');
        return;
      }
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };
  

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleCopyLink = () => {
    const inviteLink = `${response.url}`;
    navigator.clipboard.writeText(inviteLink);
    toast.success('Link copied to clipboard');
  };

  return (
    <Page title="Rotsi Escrow Creation">
      {response ? (
        <Card variant="outlined" sx={{ maxWidth: 400, margin: '0 auto' }}>
          <CardContent>
          <Typography variant="h4" gutterBottom sx={{ marginBottom: 8, color: 'black' }}>
            Rotsi Escrow Services
          </Typography>
          <div>
            <Typography variant="h6" gutterBottom sx={{ color: 'green', fontWeight: 'bold' }}>
              Escrow Created Successfully
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold' }}>
              Here is your invite link: <a href={response.url} target="_blank" rel="noopener noreferrer">{response.url}</a>
            </Typography>
            <IconButton onClick={handleCopyLink} aria-label="Copy link">
              <FileCopyIcon />
            </IconButton>
          </div>
          <Link variant="subtitle2" component={RouterLink} to="/dashboard" sx={{ color: 'green', fontWeight: 'bold' , mt: 2 }}>
            Go back to dashboard
          </Link>
         </CardContent>
        </Card>
      ) : (
        <Container>
          <Typography variant="h4" gutterBottom sx={{ marginBottom: 8, color: 'black' }}>
            Rotsi Escrow Services
          </Typography>

          {currentStep >= 1 && currentStep <= 5 && (
            <form onSubmit={handleFormSubmit}>
              <FormControl component="fieldset" fullWidth>
                {currentStep === 1 && (
                  <FormControl component="fieldset" fullWidth sx={{ marginBottom: 2 }}>
                    <RadioGroup
                      aria-label="role"
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="sender" control={<Radio />} label="Sender of Goods" />
                      <FormControlLabel value="receiver" control={<Radio />} label="Receiver of Goods" />
                    </RadioGroup>
                  </FormControl>
                )}

                {currentStep > 1 && currentStep <= 4 && (
                  <>
                    {currentStep === 2 && (
                      <>
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <TextField
                          label="Item"
                          variant="outlined"
                          value={formData.item}
                          onChange={handleChange}
                          name="item"
                        />
                      </FormControl>  
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <TextField
                          label="Item Description"
                          variant="outlined"
                          value={formData.itemDescription}
                          onChange={handleChange}
                          name="itemDescription"
                        />
                      </FormControl>
                      </>
                    )}
                    {currentStep === 3 && (
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <TextField
                          label="Amount"
                          type="number"
                          variant="outlined"
                          value={formData.amount}
                          onChange={handleChange}
                          name="amount"
                        />
                      </FormControl>
                    )}
                    
                    {/* {currentStep === 4 && (
                      <>
                        <Typography variant="h6" gutterBottom sx={{ marginBottom: 1, color: 'black' }}>
                          1. Protocol-Based will be executed when the sender and receiver agree to release the funds upon receipt of the intended items of trade by the receiver.
                        </Typography>
                        <Typography variant="h6" gutterBottom sx={{ marginBottom: 3, color: 'black' }}>
                          2. Time-Based will be executed exactly two days after the sender sends the money.
                        </Typography>
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                          <InputLabel id="release-condition-label">Choose Release Condition</InputLabel>
                          <Select
                            labelId="release-condition-label"
                            id="release-condition-select"
                            value={formData.releaseCondition}
                            onChange={handleChange}
                            name="releaseCondition"
                            variant="outlined"
                          >
                            <MenuItem value="time-based">Time-based</MenuItem>
                            <MenuItem value="protocol-based">Protocol-based</MenuItem>
                          </Select>
                        </FormControl>
                      </>
                    )} */}
                    {currentStep === 4 && (
                      <FormControlLabel
                        control={<Checkbox checked={formData.termsAccepted} onChange={handleChange} name="termsAccepted" />}
                        label="I accept the terms and conditions"
                      />
                    )}
                  </>
                )}
              </FormControl>
              <div sx={{ marginTop: 5 }}>
                {currentStep > 1 && (
                  <Button variant="contained" onClick={handlePreviousStep} sx={{ marginRight: 1 }}> {/* Add right margin */}
                    Back
                  </Button>
                )}
                {currentStep < 4 && (
                  <Button variant="contained" onClick={handleNextStep} sx={{ marginRight: 1 }}> {/* Add right margin */}
                    Next
                  </Button>
                )}
                {currentStep === 4 && (
                  <Button variant="contained" type="submit" disabled={!formData.termsAccepted || isSubmitting} sx={{ marginRight: 1 }}> {/* Add right margin */}
                    Submit
                  </Button>
                )}
              </div>
            </form>
          )}
        </Container>
      )}
    </Page>
  );
}