import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack,Link, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify'; // Moved the import here

import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { useUserContext } from '../../../UserContext'; 
import { loginUser } from '../../../apis/auth';

const defaultData = {
  email: '',
  password: '',
};

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(defaultData);
  const [isLoading, setIsLoading] = useState(false);
  const { setUserDetails } = useUserContext();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });
  
  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  
  const formSubmit = async (data) => {
    setIsLoading(true);

    try {
      // Call the API to log in the user
      const response = await loginUser(data);
      // console.log("User Context Set: ", response.data.user);
      setUserDetails(response.data.user);
      // console.log(response.data.user.location);
      if(response.data.user.location === 'businessclients'){
        // If login is successful, navigate to the desired page (e.g., /home)
        navigate('/business-dashboard/app', { replace: true });
      } else if (response.data.user.location === 'staffemployee'){
        // If login is successful, navigate to the desired page (e.g., /home)
        // console.log(response.data.user);
        navigate('/staff-dashboard/app', { replace: true });
      } else{
        // If login is successful, navigate to the desired page (e.g., /home)
        // console.log(response.data.user);
        navigate('/dashboard/app', { replace: true });
      }
      // staffemployee
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = methods.handleSubmit(formSubmit); // Call formSubmit when form is submitted

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} label="Remember me" />
        <Link to="/forgotpassword" component={RouterLink}sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}