import { useUserContext } from '../UserContext';

const useAccount = () => {
  const { user } = useUserContext();

  const account = {
    displayName: user?.username || 'Rotsi',
    email: user?.email || 'rotsi@gmail.com',
    photoURL: user?.url || 'https://rotsi-api-ui.vercel.app/favicon/favicon.png',
    role: 'Admin',
    accountNumber: user?.accountNumber || 'none',
    phonenumber: user?.phonenumber || 'none',
    bank: user?.bank || 'Not set',
    status: user?.status || '',
  };
  
  return account;
};

export default useAccount;