import React, { useEffect, useState } from 'react';
import { useNavigate,Navigate,Redirect } from 'react-router-dom';
import { Container, Typography, TextField, Button, IconButton,Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, CardContent } from '@mui/material';
import { Edit, Delete,ArrowBack } from '@mui/icons-material';
import { toast } from 'react-toastify'; // Moved the import here
import { ThreeDots } from 'react-loader-spinner';
// import { ThreeDots } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';

import { useUserContext } from '../../../UserContext';
import Login from '../../Login';
import { checkbalance } from '../../../apis/gettransactions';
import { bulksmsPush } from '../../../apis/paymentapis';

const ProcessingComponent = () => (
  // View when processing
  <Container style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Ensures the container takes the full height of the viewport
  }}>
    <div style={{ textAlign: 'center' }}>
      <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
      <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Processing ...
      </Typography>
    </div>
  </Container>
);  

const ErrorComponent = ({ paymentError, handlePaymentErrorButtonClick }) => (
  <div style={{ textAlign: 'center' }}>
    <IoMdAlert size={24} color="red" />
    <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>An error occurred!</Typography>
    <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>{paymentError.message}</Typography>
    {paymentError.predictedCost && (<Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>Predicted Cost: {paymentError.predictedCost}</Typography>)}
    {paymentError.minimumBalanceRequired && (<Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>Minimum Balance Required: {paymentError.minimumBalanceRequired}</Typography>)}
    <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Try Again
    </Button>
  </div>
);

const SuccessComponent = ({ response, handleSuccessButtonClick }) => (
  <div style={{ textAlign: 'center' }}>
    <Checkmark size='96px' color='green' />
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Status: {response.status}
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Service: {response.Service}
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Response Message : {response.ResponseMessage}
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Unsuccessful Messages:
    </Typography>
    {/* Render each unsuccessful message */}
    {response.UnsuccessfulMessages.map((message, index) => (
      <div key={index}>
        <Typography variant="body1" gutterBottom sx={{ color: 'red', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Phone: {message.phone}, Message: {message.message}, Error: {message.error}
        </Typography>
      </div>
    ))}
    <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Return to Dashboard
    </Button>
  </div>
);
 

export default function ManualEntryForm({ messages, setMessages, handleDelete,handleBack }) {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [customMessage, setcustomMessage] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');

  
  const [phoneFormatError, setPhoneFormatError] = useState('');

  const [paymentError, setPaymentError] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [emptyfields, setEmptyfields] = useState(false);
  const [response, setResponse] = useState(null);
  const [balance, setBalance] = useState(user ? user.balance : '');
  const [transactionCost, setTransactionCost] = useState(false);
  // const [transactionAmount, setTransactionAmount] = useState(false);

  const messageCost = process.env.REACT_APP_MESSAGECOST;
  // const upgradePrice = process.env.REACT_APP_MESSAGECOST;
  // let totalTransactionAmount = 0;
  // let totalTransactionCost = 0;

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   fetchBalance(user, setBalance);
  // }, [user]);  
  
  useEffect(() => {
    if (user) {
      fetchBalance(user, setBalance);
    }
  // }, [user]);  
  }, []);  

  if (!user) {
    <Login />;
  }
  if (!user) {
    return <Navigate to="/login" />; // Use Navigate to redirect to the login page
  }

  const fetchBalance = async (user, setBalance) => {
    try {
      const data = await checkbalance(user);
      const bal = data.Account_Bal;
      setBalance(bal); // Set balance state
    } catch (error) {
      console.error(`Error fetching transactions: ${error}`);
    }
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    setcustomMessage(value);
    // Clear phoneError when the user starts typing again
    setError('');
  };
  
  const handleSuccessButtonClick = () => {
    setSuccess(false); // Set success state back to false
    navigate('/dashboard/app', { replace: true }); // Navigate to the dashboard
  };

  const clearPhoneErrors = () => {
    setPhoneFormatError('');
  };

  const showToast = (message) => {
    toast.success(message, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  const formatphone = (phone) => {
    if (!phone) {
      return null;
    }

    phone = String(phone)

    // console.log(phone);

    // Remove leading plus sign if present
    let formattedPhone = phone.startsWith('+') ? phone.slice(1) : phone;
    
    // Convert '7' to '07' if length is 9
    if (formattedPhone.startsWith('7') && formattedPhone.length === 9) {
      formattedPhone = `0${formattedPhone}`;
    } 
    // Convert '11' to '011' if length is 8
    else if (formattedPhone.startsWith('11') && formattedPhone.length === 9) {
      formattedPhone = `0${formattedPhone}`;
    }
    // Convert '2547' to '07' if length is 12
    else if (formattedPhone.startsWith('2547') && formattedPhone.length === 12) {
      formattedPhone = `0${formattedPhone.slice(3)}`;
    }
    // Convert '+2547' to '07' if length is 13
    else if (formattedPhone.startsWith('+2547') && formattedPhone.length === 13) {
      formattedPhone = `0${formattedPhone.slice(4)}`;
    } 
    // Add leading '0' for '07' and '011' if length is 10
    else if (formattedPhone.length === 10 && (formattedPhone.startsWith('07') || formattedPhone.startsWith('011'))) {
      // No action needed, maintain the current format
    } 
    else {
      formattedPhone = null;
    }
    
    return formattedPhone;
  };


  const handleAddMessage = () => {
    // Clear phone number errors
    clearPhoneErrors();

    // Check if all fields are filled in
    if (!customMessage || !phonenumber) {
      setEmptyfields('Please fill in all fields');
      return;
    }

    // Validate phone number format
    const testedPhoneNumber = formatphone(phonenumber);
    if (!testedPhoneNumber) {
      setPhoneFormatError('Invalid phone number format');
      return;
    }

    const messageCostInt = parseFloat(messageCost,10);
  
    // Add sms to messages array
    const updatedMessages = [...messages, { message: customMessage, phone: testedPhoneNumber }];
    setMessages(updatedMessages);

    const totalMessages = updatedMessages.length;

    // console.log(messageCostInt);
    // console.log(totalMessages);

    const totalAmount = parseFloat(totalMessages,10) * messageCostInt;
    
    // setTransactionAmount(totalTransactionAmount);
    setTransactionCost(totalAmount);

    // Show success toast
    showToast('Custom Message added successfully');

    // Clear input fields
    setcustomMessage('');
    setPhoneNumber('');
  };

  const handleFormSubmit = async () => {
    setLoading(true);

    try {
      // Perform bulk SMS operation using messages array
      // console.log('Payments:', messages);
      // Calculate the sum of amounts in the messages array
      const totalMessages = messages.length;
      const messageCostInt = parseFloat(messageCost,10);

      const totalAmount = parseFloat(totalMessages,10) * messageCostInt;
      // console.log('Total Amount:', totalAmount);

      const minimumBalance = parseFloat(process.env.REACT_APP_MINIMUM_AMOUNT, 10);

      const balanceInt = parseFloat(balance,10);
      // console.log(balanceInt)

      // Calculate the minimum allowed balance after withdrawal
      const minimumAllowedBalance = balanceInt - minimumBalance;
      // console.log(minimumAllowedBalance);

      if (minimumBalance > balanceInt) {
        setError(`Kindly add more funds to the Rotsi wallet, to be able to send SMS(s) from the platform. Minimum Account Balance is ${minimumBalance} KES`);
        toast.error(`A minimum of ${minimumBalance} KES is required to maintain your account. Kindly add more funds to your wallet.`);
        setLoading(false);
        return;
      }  

      if (totalAmount > minimumAllowedBalance) {
        setError(`Kindly add more funds to the Rotsi wallet, to be able to send SMS(s) from the platform. Minimum Account Balance is ${minimumBalance} KES. Total amount for your messages is : ${totalAmount}`);
        toast.error(`A minimum of ${minimumBalance} is required to maintain your account. You can withdraw up to ${minimumAllowedBalance} KES`);
        setLoading(false);
        return;
      }   

      // console.log(messages);

      const responseData = await bulksmsPush(user, messages);
      setSuccess(true);
      setResponse(responseData);
    } catch (error) {
      // Handle error
      console.log(error)
      // toast.error('Bulk disbursement unavailable. Please try again later.', error);
      // setError(true);
      // setPaymentError(error);
      setPaymentError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && !paymentError && !success && <ProcessingComponent />}
      {paymentError && !success && !loading && <ErrorComponent paymentError={paymentError} handlePaymentErrorButtonClick={() => setPaymentError('')} />}
      {success && !paymentError && !loading && <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick} />}

      {!loading && !paymentError && !success && (
        <Container>
          <IconButton onClick={handleBack} sx={{ marginBottom: 2 }}>
            <ArrowBack />
          </IconButton>
          
          <Typography variant="h6" gutterBottom sx={{ marginTop: 2 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Manual Entry
          </Typography>
          <TextField
            label="Custom Customer Message"
            variant="outlined"
            fullWidth
            value={customMessage}
            onChange={handleAmountChange}
            sx={{ marginBottom: 2 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            value={phonenumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            onFocus={clearPhoneErrors}
            error={!!phoneFormatError}
            helperText={phoneFormatError}
            sx={{ marginBottom: 2 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
          />
          <Button variant="contained" onClick={handleAddMessage} sx={{ marginRight: 2 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Add Custom Message
          </Button>
          {emptyfields && (
            <Container> 
              <Typography variant="body2" color="error" gutterBottom>
                {emptyfields}
              </Typography>
            </Container>
          )}
          <TableContainer sx={{ marginTop: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Message</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map((payment, index) => (
                  <TableRow key={index}>
                    <TableCell>{payment.message}</TableCell>
                    <TableCell>{payment.phone}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDelete(index)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {transactionCost !== false  && (
            <Container sx={{ marginTop: 2 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Bulk Custom Message Details : 
              </Typography>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  We price a single message at : {messageCost} KES, Way below market rates.
                </Typography>
              </Container>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Total Cost: {transactionCost} KES
                </Typography>
              </Container>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Number of Messages Added: {messages.length}
                </Typography>
              </Container>
            </Container>
          )}
          {error && (
            <Container> 
              <Typography variant="body2" color="error" gutterBottom>
                {error}
              </Typography>
            </Container>
          )}
          <Button variant="contained" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }} onClick={handleFormSubmit} disabled={loading}>
            Send Bulk Custom Messages
          </Button>
        </Container>
      )}
    </>
  );
}