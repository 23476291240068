import React, { useEffect, useState } from 'react';
import { useNavigate,Navigate } from 'react-router-dom';
import { 
   Container, Typography, Button, Popover, Paper, 
   List, ListItem,InputLabel,
   ListItemText, MenuItem, TextField, 
   FormControl, Grid, InputAdornment, IconButton,Radio,RadioGroup,
   FormControlLabel,FormLabel } from '@mui/material';

import { toast } from 'react-toastify'; // Import toast library
import Lottie from 'react-lottie'; // Import Lottie from react-lottie
import Select from '@mui/material/Select';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from 'styled-components';
import { ThreeDots } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';

import animationData from '../assets/cryinganimation.json'; 
import { checkbalance } from '../apis/gettransactions';
import { upgradeAccount,rotsiaccountPayment } from '../apis/paymentapis';
import { useUserContext } from '../UserContext';

const formatPhone = (phone) => {
  phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    }else if (phone.startsWith('11') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
};

const UpgradeToVIP = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [balance, setBalance] = useState(null);

  const upgradePrice = process.env.REACT_APP_UPGRADEPRICE;

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  
  const [isProcessing, setIsProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [IsUpgrade, setIsUpgrade] = useState(false);
  const [error, setError] = useState(false);
  const [rotsierror, setRotsiError] = useState(false);

  const [amount, setAmount] = useState(upgradePrice);
  const [isEditing, setIsEditing] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [response, setResponse] = useState(null);
  const [phone, setPhoneNumber] = useState(user ? user.phonenumber : ''); // Use user data conditionally


  // useEffect(() => {
  //   fetchBalance(user, setBalance);
  // }, [user]); 
  
  useEffect(() => {
    if (user) {
      fetchBalance(user, setBalance);
    } 
  }, []);   

  const fetchBalance = async (user, setBalance) => {
    try {
      const data = await checkbalance(user);
      const balance = data.Account_Bal;
      setBalance(balance);
    } catch (error) {
      console.error(`Error fetching balance: ${error}`);
    }
  }; 

  const handleSuccessButtonClick = () => {
    setSuccess(false); // Set success state back to false
    setPaymentMethod('');
    setPopoverOpen(false); // Close the popover
    navigate('/login', { replace: true });
  };
  
  const SuccessComponent = ({ response, handleSuccessButtonClick }) => (
    <div style={{ textAlign: 'center' }}>
      <Checkmark size='96px' color='green' />
      {/* <Typography variant="body1" style={{ marginTop: 10,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>Deposit successful!</Typography> */}
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Transaction Id: {response.RotsiAPITransactionId}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        ResponseMessage: {response.CustomerMessage}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Service: {response.Service}
      </Typography>
      <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}>
        Return to Dashboard
      </Button>
    </div>
  );  

  const ErrorComponent = () => (
    <div style={{ textAlign: 'center' }}>
      <IoMdAlert size={24} color="red" />
      <Typography variant="body1" color="error" style={{ marginTop: 10,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>An error occurred!</Typography>
      <Button onClick={() => setError('')} variant="contained" style={{ marginTop: 20 }}>
        Try Again
      </Button>
    </div>
  );

  const ProcessingComponent = () => (
    // View when processing
    <Container style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // Ensures the container takes the full height of the viewport
    }}>
      <div style={{ textAlign: 'center' }}>
        <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
        <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Processing Payment...
        </Typography>
      </div>
    </Container>
  );  

  const handleRotsiPayment = async () => {
    setIsProcessing(true);
    const balanceInt = parseInt(balance, 10);
    const upgradePriceInt = parseInt(upgradePrice, 10);
  
    try {
      if (balanceInt < upgradePriceInt) {
        setRotsiError(`Insufficient balance. Your balance (${balance}) is less than the upgrade price (${upgradePrice}). Top up, or pay via STK push`);
        setIsProcessing(false);
      } else {
        // console.log('Making payment from RotsiAccount');
        const responseData = await rotsiaccountPayment(user, upgradePriceInt);
        setIsProcessing(false);
        setSuccess(true);
        setResponse(responseData);
        // Display success message and navigate to login page
        toast.success('Account Upgrade successful!');
        // Implement payment logic for RotsiAccount
      }
    } catch (error) {
      console.error('Error making RotsiAccount payment:', error);
      setIsProcessing(false);
      setRotsiError('An error occurred while processing your payment. Please try again later.');
    }
  };  
  
  const handlePopoverOpen = () => {
    setPopoverOpen(true);
  };

  const handleIsUpgrade = () => {
    // console.log("Isupgrade True");
    setIsUpgrade(true);
  };

  const handlePopoverClose = () => {
    setPaymentMethod('');
    setPhoneNumber('');
    setIsProcessing(false);
    setSuccess(false);
    setError(false);
    setIsUpgrade(false);
    setPopoverOpen(false);
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleRotsiAcountBackbutton = (event) => {
    setRotsiError('');
    setPaymentMethod('');
  };

  const handleEditClick = () => {
    setPhoneNumber('');
    setIsEditing(true);
  };

  const handleFormSubmit = async () => {
    setIsProcessing(true);
    const upgradePriceInt = parseInt(upgradePrice, 10);
    
    const formattedPhoneNumber = formatPhone(phone);
    // console.log(formattedPhoneNumber);
  
    // Validate phone number
    if (formattedPhoneNumber === null) {
        setPhoneError('Phone number must start with 254 and have a total of 12 digits.');
        setIsProcessing(false);
        return;
    }
    try {
        const amountWithoutDecimals = Math.trunc(upgradePriceInt);

        // Prepare the request body
        const requestBody = {
            phone:formattedPhoneNumber,
            amount: amountWithoutDecimals
        };

        // console.log('Making payment from RotsiAccount');
        const responseData = await upgradeAccount(user, requestBody);
        setResponse(responseData);
        setIsProcessing(false);
        setSuccess(true);
        // Display success message and navigate to login page
        toast.success('Account Upgrade successful!');
        // Implement payment logic for RotsiAccount
    } catch (error) {
      console.error('Error making RotsiAccount payment:', error);
      setIsProcessing(false);
      setError(true);
    }
  }; 

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // Define the PopoverContent styled component for the popover content
  const PopoverContent = styled('div')`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    z-index: 1001; /* Ensure the content is above the backdrop */
  `;

  const renderPaymentContent = (
    <PopoverContent>
      {paymentMethod ? ( 
        <>
        {isProcessing && !success && !error && (
          <ProcessingComponent/>
        )}
        {success && !isProcessing && !error && (
          // View when successful
          <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick} />
        )}
        {error && !isProcessing && !success && (
          // <ErrorComponent />
          <ErrorComponent />
        )}
        {!isProcessing && !success && !error && (
          // View when form is not processing and no success/error yet
          <>
          
          {paymentMethod === 'RotsiAccount' && (
            <div>
              <Container>
                <Button 
                  onClick={handleRotsiAcountBackbutton} 
                  variant="contained" 
                  sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }} 
                  style={{ borderRadius: '50%', minWidth: 0 }}
                >
                    <ArrowBackIcon />
                </Button>
                <Typography sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="h6">Your Rotsi Balance account is :</Typography>
                <Typography sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="subtitle1">{balance}</Typography>
                {rotsierror && (
                  <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
                    {rotsierror}
                  </Typography>
                )}
                <LoadingButton 
                  fullWidth size="large" type="submit" 
                  sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} 
                  variant="contained" 
                  loading={isProcessing} onClick={handleRotsiPayment}
                >
                  Make Payment
                </LoadingButton>
              </Container>
            </div>
          )}
          {paymentMethod === 'Mpesa' && (
            <div>
              <Button 
                onClick={handleRotsiAcountBackbutton}
                variant="contained" sx={{ marginBottom: 2 }} style={{ borderRadius: '50%', minWidth: 0 }}>
                <ArrowBackIcon />
              </Button>
              <Typography variant="h6" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} >Pay from Mpesa</Typography>
              <form onSubmit={handleFormSubmit}>
                <Grid container spacing={2} marginTop={2}>
                  <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <TextField
                      label="Your Phone Number"
                      variant="outlined"
                      placeholder="2547XXXXXXXX"
                      value={phone}
                      // onChange={handlePhoneChange}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </FormControl>
                  {phoneError && (
                    <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
                      {phoneError}
                    </Typography>
                  )}
                  <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  <TextField
                    // label="Amount you wish to deposit"
                    variant="outlined"
                    value={amount}
                    sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                    type="number"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  </FormControl>
                </Grid>
                <LoadingButton 
                  fullWidth size="large" type="submit" 
                  sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="contained" loading={isProcessing}>
                  Make Payment
                </LoadingButton>
              </form>
            </div>
           )}
           </>
         )}
       </>
      ) : ( // If no method is selected, show the method selection options
        <div>
          {/* {paymentMethod !== 'Mpesa' || paymentMethod !=='RotsiAccount' && ( */}
            <div>
              <FormControl component="fieldset">
                <Typography variant="h6" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
                  Choose Payment Method
                </Typography>
                  <RadioGroup value={paymentMethod} onChange={handlePaymentMethodChange}>
                    <FormControlLabel value="RotsiAccount" control={<Radio />} label="Rotsi Account" />
                    <FormControlLabel value="Mpesa" control={<Radio />} label="Mpesa" />
                  </RadioGroup>
              </FormControl>
            </div> 
          {/* )} */}
        </div> 
      )}
    </PopoverContent>
  );  

  const popoverContent = (
    <PopoverContent>
      {IsUpgrade ? (
        renderPaymentContent
      ) : (
        <FormControl component="fieldset">
          <Typography variant="h6" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
            Enjoy the following benefits of the Rotsi VIP program:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Send Click to pay invoices to your customers with up to 3 reminders before due date" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Schedule recurring payments to your customers, suppliers that will run automatically until you cancel them" />
            </ListItem>
          </List>
          <Button variant="contained" size="large" sx={{ marginTop: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }} onClick={handleIsUpgrade}>
            Upgrade Now
          </Button>
        </FormControl>
      )}
    </PopoverContent>
  );
  
  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: 4,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      <Lottie options={defaultOptions} height={200} width={200} onClick={handlePopoverOpen} />
      <Typography variant="h5" gutterBottom sx={{ marginTop: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Ooops, We've realized you're not a VIP user.
      </Typography>
      <Typography variant="body1" s={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
        Click the button above to enjoy VIP privileges at only {upgradePrice} per year.
      </Typography>
      <Button variant="contained" size="large" sx={{ marginTop: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }} onClick={handlePopoverOpen}>
        Upgrade to VIP
      </Button>
      <Popover
        open={popoverOpen}
        onClose={handlePopoverClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: '50%', left: '50%' }}
      >
        <Paper sx={{ padding: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          {popoverContent}
        </Paper>
      </Popover>
    </Container>
  );
};

export default UpgradeToVIP;