import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, TextField, InputLabel, FormControl, FormHelperText,Button } from '@mui/material';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import { IoMdAlert } from 'react-icons/io';
import { ColorRing } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import dayjs from 'dayjs';
import Page from '../../components/Page';
import { RenewSubscriptionFetchingUsers,RenewSubscriptionPayments } from '../../apis/generatelink';
import { useUserContext } from '../../UserContext';

const formatPhone = (phone) => {
    phone = String(phone);
      let formattedPhone = phone;
      if (phone.startsWith('+254') && phone.length === 13) {
        formattedPhone = `254${phone.substr(4)}`;
      } else if (phone.length === 12 && phone.startsWith('254')) {
        return formattedPhone;
      } else if (phone.startsWith('07') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
      } else if (phone.startsWith('011') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
      } else if (phone.startsWith('0') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
      } else if (phone.startsWith('7') && phone.length === 9) {
        formattedPhone = `254${phone}`;
      }else if (phone.startsWith('11') && phone.length === 9) {
        formattedPhone = `254${phone}`;
      } else {
        formattedPhone = null;
      }
      return formattedPhone;
};

const ProcessingComponent = ({message}) => (
    // View when processing
    <Container style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // Ensures the container takes the full height of the viewport
    }}>
      <div style={{ textAlign: 'center' }}>
        <ColorRing visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/>
        <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            {message}
        </Typography>
      </div>
    </Container>
); 

const ErrorComponent = ({ receiptError,handlePaymentErrorButtonClick }) => (
    <div style={{ textAlign: 'center' }}>
      <IoMdAlert size={24} color="red" />
      <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        {receiptError.message}
      </Typography> 
      <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 }}>
        Try Again
      </Button>
    </div>
);

const SuccessComponent = ({ response, handleSuccessButtonClick }) => (
    <div style={{ textAlign: 'center' }}>
      <Checkmark size='96px' color='green' />
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Service: {response.Service}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        ResponseMessage: {response.message}
      </Typography>
      <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}>
        Return to Dashboard
      </Button>
    </div>
); 

export default function RenewSubscription() {
    const location = useLocation();
    const { user } = useUserContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [totalAmount, setTotalAmount] = useState('');
    const [usersAmounts, setUsersAmounts] = useState('');
    const [usersNames, setUsersNames] = useState([]);
    const [userNumbers, setUserNumbers] = useState('');
    const [userTimeExpires, setUserTimeExpires] = useState('');
    const [phone, setPhoneNumber] = useState('');
    const [error, setError] = useState('');
    const [paymentError, setPaymentError] = useState('');
    const [response, setResponse] = useState(null); // State to hold response data
    const [fetching, setFetching ] = useState(false);
    const [months, setMonths] = useState(0);
    const [calculatedAmount, setCalculatedAmount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }

        const fetchTaskDetails = async () => {
            try {
                setFetching(true);
                const taskDetails = await RenewSubscriptionFetchingUsers(user);
                const { userAmount, numberOfUsers, totalAmount, userNames, timeExpires } = taskDetails;

                setTotalAmount(totalAmount);
                setUsersAmounts(userAmount);
                setUsersNames(userNames);
                setUserNumbers(numberOfUsers);
                setUserTimeExpires(timeExpires);
                setFetching(false);
            } catch (error) {
                console.error('Error fetching task details:', error);
            } finally{
                setFetching(false);
            }
        };

        fetchTaskDetails();
    }, [location.search, navigate]);

    useEffect(() => {
        const userAmount = parseInt((process.env.REACT_APP_USER_AMOUNT), 10);
        const businessAmount = parseInt((process.env.REACT_APP_BUSINESS_AMOUNT), 10);
        const newAmount = ((businessAmount) * months) + (months * (userAmount * userNumbers));
        setCalculatedAmount(newAmount);
    }, [months, totalAmount, usersAmounts, userNumbers]);

    const handlePhoneChange = (e) => {
        const { value } = e.target;
        setPhoneNumber(value);
    };

    const handleMonthsChange = (e) => {
        const { value } = e.target;
        setMonths(value);
    };

    const handlePaymentErrorButtonClick = () => {
        if(user.location === 'businessclients'){
          // If login is successful, navigate to the desired page (e.g., /home)
          navigate('/business-dashboard/app', { replace: true });
        }else if(user.location === 'staffemployee'){
          // If login is successful, navigate to the desired page (e.g., /home)
          navigate('/staff-dashboard/app', { replace: true });
        }else{
          // If login is successful, navigate to the desired page (e.g., /home)
          navigate('/dashboard/app', { replace: true });
        }
    };

    const handleSuccessButtonClick = () => {
        setResponse(false); // Set success state back to false
        setPhoneNumber(false); // Close the popover
        if(user.location === 'businessclients'){
            // If login is successful, navigate to the desired page (e.g., /home)
            navigate('/business-dashboard/app', { replace: true });
          }else if(user.location === 'staffemployee'){
            // If login is successful, navigate to the desired page (e.g., /home)
            navigate('/staff-dashboard/app', { replace: true });
          }else{
            // If login is successful, navigate to the desired page (e.g., /home)
            navigate('/dashboard/app', { replace: true });
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError('');
        setPaymentError('');

        try {
            const formattedPhone = formatPhone(phone);
            if (!formattedPhone) {
                toast.error('Please enter a valid phone number.');
                setError('Please enter a valid phone number.');
                setIsSubmitting(false);
                return;
            }

            const requestBody = {
                phone: formattedPhone,
                amount: calculatedAmount,
                months
            }

             // Perform the withdrawal operation
            const responseData = await RenewSubscriptionPayments(user, requestBody);
            // Update the response state with the received data
            setResponse(responseData);
            toast.success('Payment Confirmed!');
        } catch (error) {
            setPaymentError(error.response.data);
        } finally {
            setIsSubmitting(false);
        }
    };

    const currentDate = dayjs();
    const subscriptionEndDate = dayjs(userTimeExpires);
    const newEndDate = subscriptionEndDate.add(months, 'month'); // Calculate the new end date

    const isSubscriptionEnded = currentDate.isAfter(subscriptionEndDate);

    return (
        <Page title="Renew Subscription">

        {paymentError && <ErrorComponent receiptError={paymentError} handlePaymentErrorButtonClick={handlePaymentErrorButtonClick} />}
        {fetching && <ProcessingComponent message={"Fetching your Subscription Details..."} />}

        {response && !fetching && !paymentError && (
          // View when successful
          <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick} />
        )}

        {!fetching && !paymentError && (        
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    padding: '20px',
                }}
            >
                <form onSubmit={handleFormSubmit} style={{ width: '100%', maxWidth: '600px' }}>
                    {isSubscriptionEnded ? (
                        <>
                            <Typography sx={{ marginBottom: 2, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Subscription Ended
                            </Typography>

                            <InputLabel sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Total Amount
                            </InputLabel>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <TextField
                                    variant="outlined"
                                    value={calculatedAmount}
                                    InputProps={{ readOnly: true }}
                                    sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                                    fullWidth
                                />
                            </FormControl>

                            <Typography sx={{ marginBottom: 2, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Your Active Users:
                            </Typography>
                            <ul>
                                {usersNames.map((name, index) => (
                                    <li key={index}>{name}</li>
                                ))}
                            </ul>

                            <InputLabel sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Phone Number
                            </InputLabel>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                                    fullWidth
                                />
                            </FormControl>
                            {error && (
                                <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
                                    {error}
                                </Typography>
                            )}
                            <FormControl fullWidth sx={{ marginTop: 2 }}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                                >
                                    Make Payment
                                </LoadingButton>
                            </FormControl>
                            {paymentError && (
                                <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
                                    {paymentError}
                                </Typography>
                            )}
                        </>
                    ) : (
                        <>
                            <Typography sx={{ marginBottom: 2, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Hurray! Your subscription is still active. However, you can extend it below.
                                Simply enter the number of months you want to add to your subscription, 
                                click "Extend Subscription," and make the payment. You'll then have peace 
                                of mind until {newEndDate.format('YYYY-MM-DD')}.
                            </Typography>

                            <Typography sx={{ marginBottom: 2, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Time to End: {subscriptionEndDate.format('YYYY-MM-DD')}
                            </Typography>

                            <InputLabel sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Increase Subscription Time (Months)
                            </InputLabel>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    value={months}
                                    onChange={handleMonthsChange}
                                    sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                                    fullWidth
                                />
                            </FormControl>

                            <InputLabel sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Total Amount Payable(KES)
                            </InputLabel>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <TextField
                                    variant="outlined"
                                    value={calculatedAmount}
                                    InputProps={{ readOnly: true }}
                                    sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                                    fullWidth
                                />
                            </FormControl>

                            <Typography sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Your Active Users:
                            </Typography>
                            <ul>
                                {usersNames.map((name, index) => (
                                    <li key={index}>{name}</li>
                                ))}
                            </ul>

                            <InputLabel sx={{ marginTop: 2, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                Phone Number
                            </InputLabel>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                    sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                                    fullWidth
                                />
                            </FormControl>
                            {error && (
                                <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
                                    {error}
                                </Typography>
                            )}
                            <FormControl fullWidth sx={{ marginTop: 2 }}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    disabled={!months || !phone}
                                    sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                                >
                                    Extend Subscription
                                </LoadingButton>
                            </FormControl>
                            {paymentError && (
                                <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
                                    {paymentError}
                                </Typography>
                            )}
                        </>
                    )}
                </form>
            </Container>
        )}
        </Page>
    );
}