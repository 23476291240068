import React from 'react';
import { Container, Typography, TextField, Button,Stack, FormControl, InputAdornment,Card,CardContent,Link } from '@mui/material';

const QrCodeComponent = ({ response, handleReturnButtonClick }) => {
    const downloadQRCode = () => {
        // Logic to download the QR code image
        const qrCodeImage = document.getElementById('qrCodeImage');
        const canvas = document.createElement('canvas');
        canvas.width = qrCodeImage.naturalWidth;
        canvas.height = qrCodeImage.naturalHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(qrCodeImage, 0, 0);
        const dataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'qr_code.png';
        link.click();
    };

    return (
        <Container>
            <img
                src={`data:image/png;base64,${response}`}
                alt="QR Code"
                id="qrCodeImage"
            />
         <Stack spacing={3}>
            <Button variant="contained" sx={{ marginBottom: 20,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }} onClick={downloadQRCode}>
                Download QR Code
            </Button>
            <Button onClick={handleReturnButtonClick} variant="contained" sx={{ marginTop: 20,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                Return to Dashboard
            </Button>
         </Stack>
        </Container>
    );
};

export default QrCodeComponent;