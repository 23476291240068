import React, { useState, useEffect } from 'react';
import { useNavigate , Link as RouterLink} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, List, ListItem, ListItemText ,IconButton, InputAdornment,Tooltip,Link,TextField} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Fuse from 'fuse.js';
import * as Yup from 'yup';

import { FormProvider, RHFTextField,RHFTextFieldImage } from '../../../components/hook-form';
import { useUserContext } from '../../../UserContext';
import { insertwithdrawalDetails } from '../../../apis/auth';

const defaultData = {
  phonenumber: '',
  bank: '',
  bankcode: '',
  accountNumber: '',
};

const formatPhone = (phone) => {
  phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    }else if (phone.startsWith('11') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
};

export default function PhoneDetailsForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(defaultData);
  const [step, setStep] = useState(1); // Current step of the form
  const [banks, setBanks] = useState([]); // Array to store banks fetched from Firebase
  const [filteredBanks, setFilteredBanks] = useState([]); // Array to store filtered banks based on search
  const [searchTerm, setSearchTerm] = useState(''); // State to store the search term
  const [selectedBank, setSelectedBank] = useState(''); // State to store the selected bank
  const [isBankListVisible, setIsBankListVisible] = useState(false); // State to manage visibility of bank list
  const [phonenumber, setPhoneNumber] = useState('');
  const [isBankChosen, setIsBankChosen] = useState(false); // State to track if a bank is chosen
  const [bankName, setBankName] = useState(''); // State to store the selected bank name
  const [bankCodes, setBankCodes] = useState({}); // State to store bank codes
  const { user,setUserDetails } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  const paybillNumber = process.env.REACT_APP_ROTSIPAYBILL;

  useEffect(() => {
    if (!user) {
      navigate('/login'); // Navigate to the login page if user is not available
    }
  }, [user, navigate]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const snapshot = await firebase.database().ref('banks').once('value');
        const data = snapshot.val();
        if (data) {
          const bankNames = Object.values(data);
          const bankCodesObj = Object.keys(data).reduce((acc, key) => {
            acc[data[key]] = key; // Map bank names to bank codes
            return acc;
          }, {});
          setBanks(bankNames);
          setBankCodes(bankCodesObj);
        }
      } catch (error) {
        console.error('Error fetching banks:', error);
      }
    };

    fetchBanks();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  useEffect(() => {
    // Initialize Fuse with the list of banks
    const fuse = new Fuse(banks, {
      keys: ['bank'],
      threshold: 0.4,
    });

    // Perform search whenever searchTerm changes
    const searchResults = fuse.search(searchTerm);
    setFilteredBanks(searchResults.map((result) => result.item));
  }, [searchTerm, banks]);

  const PhoneSchema = Yup.object().shape({
    phonenumber: Yup.string()
        .required('Phone number is required')
        .matches(/^(254)\d{9}$/, 'Phone number must start with 254 and be 12 digits long'),
  });

  const BankSchema = Yup.object().shape({
    accountNumber: Yup.string().required('Bank account is required'),
  });

  const methods = useForm({
    resolver: yupResolver(step === 1 ? PhoneSchema : BankSchema),
    defaultValues: defaultData,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const proceed = () => {
    if (step === 1) {
      const phone = formatPhone(phonenumber);

      if (!/^\d{12}$/.test(phone) || !phone.startsWith('254')) {
        toast.error('Phone number must start with 254 and be 12 digits long');
        return; // Stop proceeding if the phone number is invalid
      }
      setStep(2); // Proceed to the next step if the phone number is valid
    }
  };  
  
  const handleBankSelect = (selectedBank) => {
    setSelectedBank(selectedBank); // Set the selected bank
    setIsBankListVisible(false); // Hide the bank list
    setBankName(selectedBank); // Set the bank name
    setIsBankChosen(true); // Set isBankChosen to true
  };

  const handleBankEdit = () => {
    setIsBankChosen(false); // Reset bank selection
    setFormData({ ...formData, accountNumber: '' }); // Clear bank account field
  };
  
  const formSubmit = async (data) => {
    try {
      setIsLoading(true);
      const bankCode = bankCodes[bankName]; // Get bank code from bank name
      const { searchbank, ...newData } = data;
      const submitData = { ...formData, ...newData, phonenumber, bank: bankName, bankcode: bankCode };
      try {
        const response = await insertwithdrawalDetails(user,submitData);
        setUserDetails(response.data.user);
        if(user.location === 'businessclients'){
          navigate('/business-dashboard/app', { replace: true });
        }else{
          navigate('/dashboard/app', { replace: true });
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }

    } catch (error) {
      // Handle submission error
      console.error('Submission failed', error);
      toast.error('Submission failed. Please try again later.');
    }
  };

  const onSubmit = handleSubmit(formSubmit); // Call formSubmit when form is submitted

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3}>
        {step === 1 && (
          <>
            <TextField
              label="Phone Number to transfer to Money"
              type="number"
              variant="outlined"
              placeholder="2547XXXXXXXX"
              value={phonenumber}
              sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              onChange={(e) => setPhoneNumber(e.target.value)}/>
            <LoadingButton fullWidth size="large" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} onClick={proceed} variant="contained">
              Proceed
            </LoadingButton>
          </>
        )}
        {step === 2 && (
          <>
            <RHFTextFieldImage
              name="searchbank"
              label="Search Bank"
              value={searchTerm}
              onFocus={() => setIsBankListVisible(true)}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ display: isBankChosen ? 'none' : 'block' }} // Hide the field if a bank is chosen
              sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
            />
            {isBankListVisible && (
              <List>
                {filteredBanks.map((bank) => (
                  <ListItem key={bank} button onClick={() => handleBankSelect(bank)}>
                    <ListItemText primary={bank} />
                  </ListItem>
                ))}
              </List>
            )}
            {isBankChosen && (
              <>
                <RHFTextField
                  name="bank"
                  label="Bank Name"
                  value={selectedBank}
                  onChange={(e) => setSelectedBank(e.target.value)}
                  sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Edit Bank" placement="top">
                          <IconButton
                            onClick={handleBankEdit}
                            edge="end"
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  autoFocus
                />
                <RHFTextField name="accountNumber" label="Bank Account" />
              </>
            )}
            {!isBankChosen && (
              <RHFTextField
                name="accountNumber"
                label="Bank Account"
                value={selectedBank}
                onChange={(e) => setSelectedBank(e.target.value)}
                sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                style={{ display: isBankChosen ? 'block' : 'none' }} 
              />
            )}
            <LoadingButton 
              fullWidth size="large" 
              type="submit" variant="contained" 
              loading={isSubmitting}
              sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              style={{ display: isBankChosen ? 'block' : 'none' }} 
            >
              Submit Details
            </LoadingButton>
          </>
        )}
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Link
          variant="subtitle2"
          component={RouterLink}
          to="/dashboard"
          sx={{
            fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'
            // mt:  4, // Adjust the margin-top value as needed
            // position: 'absolute',
            // bottom:  0,
            // right:  0,
          }}
        >
          Fill Later
        </Link>
      </div>
    </FormProvider>
  );
}