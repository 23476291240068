import React from 'react';
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import BotAvatar from "../../assets/rotsiLogo.png";
import { useUserContext } from '../../UserContext';
import useAccount from '../../_mock/account';
import RepeatedCustomerComponent from './ChatbotTemplates/repeated';  
import MVPCustomerComponent from './ChatbotTemplates/mvpcustomer';

import NC2BMVPCustomerWithInput from './ChatbotTemplates/Nc2bmvpcustomer';
import Repeatedc2bCustomerWithInput from './ChatbotTemplates/Nc2brepeated';

import RepeatedSTKCustomerWithInput from './ChatbotTemplates/Nstkrepeated';
import NSTKMVPCustomerWithInput from './ChatbotTemplates/Nstkmvpcustomer';

import B2CRepeatedCustomerComponent from './ChatbotTemplates/b2crepeated'; 
import B2CRepeatedCustomerWithInput from './ChatbotTemplates/b2cNrepeated';
import BankRepeatedCustomerWithInput from './ChatbotTemplates/bankNrepeated';
import BankRepeatedCustomerComponent from './ChatbotTemplates/bankrepeated';
import PaybillRepeatedCustomerWithInput from './ChatbotTemplates/paybillNrepeated';
import PaybillRepeatedCustomerComponent from './ChatbotTemplates/paybillrepeated';


const CHATBOT_THEME = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',
  headerBgColor: '#6e48aa',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#6e48aa',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const config = {
  botAvatar: BotAvatar,
  floating: true,
  width: '300px',
  height: '400px',
  placeholder: 'Type your response.',
  headerTitle: "Rotsi Bot",
};

const Chatbot = () => {
  const { user } = useUserContext();
  const account = useAccount();
  const userImage = account.photoURL;
  const usernameTitleCase = capitalizeFirstLetter(user.username);

  const steps = [
    { id: "1", message: `Hello! ${usernameTitleCase}`, trigger: "2" },
    { id: "2", message: "Here are a few template questions to kickstart your query.", trigger: "3" },
    { id: "3", options: [
        { value: 1, label: "1. Pay-ins/Deposits", trigger: "4" },
        { value: 2, label: "2. Pay-outs/Money-out", trigger: "5" },
        // { value: 3, label: "3. Customers per month", trigger: "6" },
      ]
    },
    { id: "4", options: [
        { value: 1, label: "1. Deposits via Normal Mpesa", trigger: "41" },
        { value: 2, label: "2. Deposits via STK", trigger: "42" },
      ]
    },
    { id: "41", options: [
      { value: 1, label: "1. Visited Customers", trigger: "411" },
      { value: 2, label: "2. Most 'Generous' Customer", trigger: "412" },
      // { value: 3, label: "3. Customers per month", trigger: "6" },
      ]
    },
    { id: "411", options: [
        { value: 1, label: "1. The Most Visited Customer", trigger: "4111" },
        { value: 2, label: "2. Generate an excel sheet of your most visited/recurring customers", trigger: "4112" },
        // { value: 3, label: "3. Customers per month", trigger: "6" },
      ]
    },
    { id: "4111", component: <RepeatedCustomerComponent />, trigger: "2" },
    { id: "4112", message: "Enter the value of customers you would like to search for eg 1, 2 etc.:", trigger: "input_4112" },
    { 
      id: "input_4112", 
      user: true, 
      trigger: "display_repeated_customer_c2b" 
    },
    { id: "display_repeated_customer_c2b", component: <Repeatedc2bCustomerWithInput />},
    { id: "412", options: [
        { value: 1, label: "1. Most Paying Customer", trigger: "4121" },
        { value: 2, label: "2. Generate an excel sheet of your most paying customers", trigger: "4122" },
      ]
    },
    { id: "4121", component: <MVPCustomerComponent />, trigger: "2" },
    { id: "4122", message: "Enter the value of customers you would like to search for eg 1, 2 etc.:", trigger: "input_4122" },
    { 
      id: "input_4122", 
      user: true, 
      trigger: "display_mvp_customer_c2b" 
    },
    { id: "display_mvp_customer_c2b", component: <NC2BMVPCustomerWithInput />},


    { id: "42", options: [
      { value: 1, label: "1. Visited Customers", trigger: "421" },
      { value: 2, label: "2. Most 'Generous' Customer", trigger: "422" },
      // { value: 3, label: "3. Customers per month", trigger: "6" },
      ]
    },
    { id: "421", options: [
        { value: 1, label: "1. The Most Visited Customer", trigger: "4211" },
        { value: 2, label: "2. Generate an excel sheet of your most visited/recurring customers", trigger: "4212" },
        // { value: 3, label: "3. Customers per month", trigger: "6" },
      ]
    },
    { id: "4211", component: <RepeatedCustomerComponent />, trigger: "2" },
    { id: "4212", message: "Enter the value of customers you would like to search for eg 1, 2 etc.:", trigger: "input_4212" },
    { 
      id: "input_4212", 
      user: true, 
      trigger: "display_repeated_customer_stk" 
    },
    { id: "display_repeated_customer_stk", component: <RepeatedSTKCustomerWithInput />},
    { id: "422", options: [
        { value: 1, label: "1. Most Paying Customer", trigger: "4221" },
        { value: 2, label: "2. Generate an excel sheet of your most paying customers", trigger: "4222" },
      ]
    },
    { id: "4221", component: <MVPCustomerComponent />, trigger: "2" },
    { id: "4222", message: "Enter the value of customers you would like to search for eg 1, 2 etc.:", trigger: "input_4222" },
    { 
      id: "input_4222", 
      user: true, 
      trigger: "display_mvp_customer_stk" 
    },
    { id: "display_mvp_customer_stk", component: <NSTKMVPCustomerWithInput />},

    // { id: "2", message: "Choose between the three :", trigger: "3" },
    { id: "5", options: [
        { value: 1, label: "1. B2C", trigger: "51" },
        { value: 2, label: "2. Bank Payments", trigger: "52" },
        { value: 2, label: "2. Paybill Payments", trigger: "53" },
      ]
    },
    { id: "51", options: [
      { value: 1, label: "1. Most Paid to Number", trigger: "511" },
      { value: 2, label: "2. Generate an excel sheet of numbers most paid to", trigger: "512" },
      ]
    },
    { id: "511", component: <B2CRepeatedCustomerComponent />, trigger: "2" },
    { id: "512", message: "Enter the value of customers you would like to search for eg 1, 2 etc.:", trigger: "input_512" },
    { 
      id: "input_512", 
      user: true, 
      trigger: "display_mvp_customer_b2c" 
    },
    { id: "display_mvp_customer_b2c", component: <B2CRepeatedCustomerWithInput />},
    
    { id: "52", options: [
      { value: 1, label: "1. Most Paid to Bank Account", trigger: "521" },
      { value: 2, label: "2. Generate an excel sheet of Bank Account`s most paid to", trigger: "522" },
      ]
    },
    { id: "521", component: <BankRepeatedCustomerComponent />, trigger: "2" },
    { id: "522", message: "Enter the value of customers you would like to search for eg 1, 2 etc.:", trigger: "input_522" },
    { 
      id: "input_522", 
      user: true, 
      trigger: "display_mvp_customer_bank" 
    },
    { id: "display_mvp_customer_bank", component: <BankRepeatedCustomerWithInput />},

    { id: "53", options: [
      { value: 1, label: "1. Most Paid to Paybill", trigger: "531" },
      { value: 2, label: "2. Generate an excel sheet of Paybill Account`s most paid to", trigger: "532" },
      ]
    },
    { id: "531", component: <PaybillRepeatedCustomerComponent />, trigger: "2" },
    { id: "532", message: "Enter the value of customers you would like to search for eg 1, 2 etc.:", trigger: "input_532" },
    { 
      id: "input_532", 
      user: true, 
      trigger: "display_mvp_customer_paybill" 
    },
    { id: "display_mvp_customer_paybill", component: <PaybillRepeatedCustomerWithInput />},

  ];

  return (
    <ThemeProvider theme={CHATBOT_THEME}>
      <ChatBot
        steps={steps}
        floating
        {...config}
        userAvatar={userImage}
      />
    </ThemeProvider>
  );
};

export default Chatbot;