import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Grid, Switch } from '@mui/material';
import MenuPopover from '../../components/MenuPopover';
import useAccount from '../../_mock/account';
import { useUserContext } from '../../UserContext';
import { enableSMS } from '../../apis/auth';

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/business-dashboard/app',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '/business-dashboard/profile',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    linkTo: '/business-dashboard/settings',
  },
];

export default function AccountPopover() {
  const account = useAccount();
  const { user,setNewSmsEnable } = useUserContext();
  const navigate = useNavigate();
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);
  const [smsEnable, setSmsEnable] = useState(user.smsenabled);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    handleClose();
    navigate('/login');
  };

  const handleSmsEnable = async () => {
    try {
      const newEnableSMS = !smsEnable;
      const body = { newEnableSMS };

      await enableSMS(user, body);
      setSmsEnable(newEnableSMS);
      setNewSmsEnable(newEnableSMS);
      toast.success(newEnableSMS ? "SMS Enabled" : "SMS Disabled");
    } catch (error) {
      console.error(error);
    }finally{
      handleClose();
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }} noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }} noWrap>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography sx={{ fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                {smsEnable ? 'Disable SMS' : 'Enable SMS'}
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={smsEnable}
                onChange={handleSmsEnable}
                size="small"
              />
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}