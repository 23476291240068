import React, { useEffect, useState } from 'react';
import { useNavigate,Navigate,Redirect } from 'react-router-dom';
import {
  Typography,Container,
  Button,Card,CardContent,Paper,Grid,
  IconButton,
  Table,Select,InputLabel,Link,MenuItem,InputAdornment,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ThreeDots } from 'react-loader-spinner';
// import { ThreeDots } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';
import { CloudUpload, Delete, ArrowBack } from '@mui/icons-material';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify'; // Moved the import here

// import { useUserContext } from '../../../UserContext';
import { useUserContext } from '../../../UserContext';
import { bulksmsPush } from '../../../apis/paymentapis';
import Login from '../../Login';
import { SMSbalance } from '../../../apis/gettransactions';

const ProcessingComponent = () => (
  // View when processing
  <Container style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Ensures the container takes the full height of the viewport
  }}>
    <div style={{ textAlign: 'center' }}>
      <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
      <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Processing ...
      </Typography>
    </div>
  </Container>
);  


const ErrorComponent = ({ paymentError, handlePaymentErrorButtonClick }) => (
  <div style={{ textAlign: 'center' }}>
    <IoMdAlert size={24} color="red" />
    <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>An error occurred!</Typography>
    <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>{paymentError.message}</Typography>
    {paymentError.predictedCost && (<Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>Predicted Cost: {paymentError.predictedCost}</Typography>)}
    {paymentError.minimumBalanceRequired && (<Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>Minimum Balance Required: {paymentError.minimumBalanceRequired}</Typography>)}
    <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Try Again
    </Button>
  </div>
);

const SuccessComponent = ({ response, handleSuccessButtonClick }) => (
  <div style={{ textAlign: 'center' }}>
    <Checkmark size='96px' color='green' />
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Status: {response.status}
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Service: {response.Service}
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Response Message : {response.ResponseMessage}
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Unsuccessful Messages:
    </Typography>
    {/* Render each unsuccessful message */}
    {response.UnsuccessfulMessages.map((message, index) => (
      <div key={index}>
        <Typography variant="body1" gutterBottom sx={{ color: 'red', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Phone: {message.phone}, Message: {message.message}, Error: {message.error}
        </Typography>
      </div>
    ))}
    <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Return to Dashboard
    </Button>
  </div>
);



export default function UploadB2cForm({ messages, setMessages,handleDelete,handleBack }) {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [uploadType, setUploadType] = useState('');
  const [url, setUrl] = useState('');
  const [message, setPayment] = useState('');
  const [nullpayments, setNullPayments] = useState(false);
  const [excelData, setExcelData] = useState(null);

  const [paymentError, setPaymentError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState(null);
  const [balance, setBalance] = useState(user ? user.smsbalance : '');
  const [transactionCost, setTransactionCost] = useState(false);
  // const [transactionAmount, setTransactionAmount] = useState(false);
  const messageCost = process.env.REACT_APP_MESSAGECOST;

  // let totalTransactionAmount = 0;
  // let totalTransactionCost = 0;
  
  
  const [loading, setLoading] = useState(false); // Added loading state

  // useEffect(() => {
  //   fetchBalance(user, setBalance);
  // }, [user]);  
  
  useEffect(() => {
    if (user) {
      fetchBalance(user, setBalance);
    }
  // }, [user]);  
  }, []);  

  if (!user) {
    <Login />;
  }
  if (!user) {
    return <Navigate to="/login" />; // Use Navigate to redirect to the login page
  }

  const fetchBalance = async (user, setBalance) => {
    try {
      const data = await SMSbalance(user);
      const bal = data.Account_Bal;
      setBalance(bal); // Set balance state
    } catch (error) {
      if(error.message){
        if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          navigate('/login');
        }else{
          console.error(`Error fetching transactions: ${error}`);
        }
      }
    }
  };

  const showToast = (message) => {
    toast.success(message, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  const handleSuccessButtonClick = () => {
    setSuccess(false); // Set success state back to false
    navigate('/business-dashboard/app', { replace: true }); // Navigate to the dashboard
  };

  const handleUploadTypeChange = (event) => {
    setUploadType(event.target.value);
    setUrl(''); // Reset URL input when switching upload type
  };

  const resetUploadType = () => {
    setUploadType('');
    setUrl(''); // Reset URL input when switching upload type
  };

  const resetExcelUpload = () => {
    setUploadType('');
    setUrl(''); // Reset URL input when switching upload type
    setExcelData(false);
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      // Perform bulk SMS operation using messages array
      // console.log('SMS(s):', messages);
      // Calculate the sum of amounts in the messages array
      // const totalAmount = messages.reduce((acc, curr) => acc + curr.message, 0);
      
      const totalMessages = messages.length;
      const messageCostInt = parseFloat(messageCost,10);

      const totalAmount = parseFloat(totalMessages,10) * messageCostInt;

      // console.log('Total Amount:', totalAmount);

      const minimumBalance = parseInt(process.env.REACT_APP_MINIMUM_AMOUNT, 10);

      const balanceInt = parseInt(balance,10);
      // console.log(balanceInt)

      // Calculate the minimum allowed balance after withdrawal
      const minimumAllowedBalance = balanceInt - minimumBalance;
      // console.log(minimumAllowedBalance);

      if (messageCostInt > balanceInt) {
        setError(`Kindly add more funds to the Rotsi wallet, to be able to send SMS(s) from the platform. Your SMS Balance is currently at ${balanceInt} KES, 
          while cost of message is ${messageCostInt} KES`);
        toast.error(`Kindly add more funds to the Rotsi wallet, to be able to send SMS(s) from the platform. Your SMS Balance is currently at ${balanceInt} KES, 
          while cost of message is ${messageCostInt} KES.`);
        setLoading(false);
        return;
      }  

      // if (minimumBalance > balanceInt) {
      //   setError(`Kindly add more funds to the Rotsi wallet, to be able to send SMS(s) from the platform. Minimum Account Balance is ${minimumBalance} KES`);
      //   toast.error(`A minimum of ${minimumBalance} KES is required to maintain your account. Kindly add more funds to your wallet.`);
      //   setLoading(false);
      //   return;
      // }  

      // if (totalAmount > minimumAllowedBalance) {
      //   setError(`Kindly add more funds to the Rotsi wallet, to be able to send SMS(s) from the platform. Minimum Account Balance is ${minimumBalance} KES. Total amount for your messages is : ${totalAmount}`);
      //   toast.error(`A minimum of ${minimumBalance} is required to maintain your account. You can withdraw up to ${minimumAllowedBalance} KES`);
      //   setLoading(false);
      //   return;
      // }  

      // console.log(messages);

      const responseData = await bulksmsPush(user, messages);
      setSuccess(true);
      setResponse(responseData);
    } catch (error) {
      if(error.message){
        if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          navigate('/login');
        }else{
          toast.error('Bulk sms sending Failed. Please try again later.');
          setError(true);
          setPaymentError(error);
        }
      }else{
        setError(true);
        toast.error('Bulk sms sending Failed. Please try again later.');
        const errorResponse = { message: 'Bulk sms sending Failed. Please try again later.' };
        setPaymentError(errorResponse);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFileRead = (e) => {
    setLoading(true); // Set loading to true during file read
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      setExcelData(jsonData);

      // Retrieve rows based on MobileNumber and Phone columns and add them to messages array
      const messages = [];
      for (let i = 1; i < jsonData.length; i += 1) { // Start from index 1 to skip headers
        const row = jsonData[i];
        const mobileNumber = row[0]; // Assuming MobileNumber is the first column
        const phone = formatphone(mobileNumber);
        const message = String(row[1]);
        
        // const purposeOfPayment = row[2]; // Assuming PurposeOfPayment is the third column
        // const name = row[3]; // Assuming Name is the fourth column
        // if (phone && !isNaN(message)) { // Check if phone is not empty and message is a valid 
        //   messages.push({ phone, message, purposeOfPayment, name });
        // }
        if (phone && message) { // Check if phone is not empty and message is a valid 
          messages.push({ phone, message });

          const messageCostInt = parseFloat(messageCost,10);

          setMessages(messages);

          const totalMessages = messages.length;

          // console.log(messageCostInt);
          // console.log(totalMessages);

          const totalAmount = parseFloat(totalMessages,10) * messageCostInt;
          
          // setTransactionAmount(totalTransactionAmount);
          setTransactionCost(totalAmount);

          showToast(`${totalMessages} Custom Message added successfully`);
        }else{
          setNullPayments(true);
        }
      }
      setLoading(false); // Set loading to false after processing
    };

    reader.readAsArrayBuffer(file);
  };  

  const formatphone = (phone) => {
    if (!phone) {
      return null;
    }

    phone = String(phone)

    // console.log(phone);

    // Remove leading plus sign if present
    let formattedPhone = phone.startsWith('+') ? phone.slice(1) : phone;
    
    // Convert '7' to '07' if length is 9
    if (formattedPhone.startsWith('7') && formattedPhone.length === 9) {
      formattedPhone = `0${formattedPhone}`;
    } 
    // Convert '11' to '011' if length is 8
    else if (formattedPhone.startsWith('11') && formattedPhone.length === 9) {
      formattedPhone = `0${formattedPhone}`;
    }
    // Convert '2547' to '07' if length is 12
    else if (formattedPhone.startsWith('2547') && formattedPhone.length === 12) {
      formattedPhone = `0${formattedPhone.slice(3)}`;
    }
    // Convert '+2547' to '07' if length is 13
    else if (formattedPhone.startsWith('+2547') && formattedPhone.length === 13) {
      formattedPhone = `0${formattedPhone.slice(4)}`;
    } 
    // Add leading '0' for '07' and '011' if length is 10
    else if (formattedPhone.length === 10 && (formattedPhone.startsWith('07') || formattedPhone.startsWith('011'))) {
      // No action needed, maintain the current format
    } 
    else {
      formattedPhone = null;
    }
    
    return formattedPhone;
  };
  
  const handleFetchFromUrl = async () => {
    setLoading(true); 
    try {
      const response = await axios.get(url, { responseType: 'arraybuffer' });
  
      const data = new Uint8Array(response.data);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
      // Filter out rows with no meaningful content and skip the header row
      const filteredData = jsonData.filter((row, index) => {
        // Skip the header row
        if (index === 0 || row.length === 0) return false;
  
        // Check if any cell (except the first cell) has non-empty and non-null content
        return row.slice(1).some(cell => cell !== null && cell !== '');
      });
  
      // console.log("filteredData", filteredData);
  
      setExcelData(filteredData);
  
      // Format phone numbers and parse amounts for valid data rows
      const messages = [];

      for (let i = 1; i < filteredData.length; i += 1) { // Start from index 1 to skip headers
        const row = filteredData[i];
        const phone = formatphone(row[1]); // Assuming MobileNumber is the second column, renamed to "phone"
        const message = String(row[2]); // Assuming Amount is the third column, renamed to "message"
      
        if (phone && message) { // Check if phone is not empty and message is a valid 
          messages.push({ phone, message });
          // console.log("messages", messages);
          const messageCostInt = parseFloat(messageCost,10);

          setMessages(messages);

          const totalMessages = messages.length;

          // console.log(messageCostInt);
          // console.log(totalMessages);

          const totalAmount = parseFloat(totalMessages,10) * messageCostInt;
          
          // setTransactionAmount(totalTransactionAmount);
          setTransactionCost(totalAmount);

          showToast(`${totalMessages} Custom Message added successfully`);
        } else {
          setNullPayments(true);
        }
      } 
    } catch (error) {
      console.error('Error fetching or parsing Excel file:', error);
    } finally{
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <ProcessingComponent />}
      {paymentError && <ErrorComponent paymentError={paymentError} handlePaymentErrorButtonClick={() => setPaymentError('')} />}
      {success && <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick} />}
      
      {!excelData && !loading && !paymentError && !success && uploadType === '' && (
        <Container>
            <IconButton onClick={handleBack} sx={{ marginBottom: 5,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              <ArrowBack />
            </IconButton>
            <Typography
                variant="body1"
                component="a"
                href={'https://dashboard.rotsi.co.ke/templates/SMS-Bulk-Template.xlsx'} // Use getTemplateDownloadLink to directly get the template link
                target="_blank"
                rel="noopener noreferrer"
                sx={{ marginTop: 5, cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              >
                Download SMS Template File (Ensure you follow this template format)
            </Typography>
            <FormControl fullWidth sx={{ marginBottom: 2 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}>
              
              <InputLabel id="upload-type-label">Upload Type</InputLabel>
              <Select
                labelId="upload-type-label"
                id="upload-type"
                value={uploadType}
                onChange={handleUploadTypeChange}
              >
                <MenuItem value="file">
                  File Upload
                </MenuItem>
                <MenuItem value="url">
                  <Link /> URL Upload
                </MenuItem>
              </Select>
            </FormControl>
        </Container>
      )}
      {!excelData && !loading && !paymentError && !success && uploadType === 'file' && (
        <Container>
          <IconButton onClick={resetUploadType} sx={{ marginBottom: 2 }}>
              <ArrowBack />
          </IconButton>
          <Typography sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Click to upload SMS excel sheet file.
          </Typography>
          <label htmlFor="contained-button-file">
            <input
              accept=".xlsx"
              id="contained-button-file"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileRead}
            />
            <LoadingButton variant="contained" component="span" sx={{ marginTop: 2 }}>
              <CloudUpload />
            </LoadingButton>
          </label>
        </Container>
      )} 
      {!excelData && !loading && !paymentError && !success && uploadType === 'url' && (
        <Container>
          <IconButton onClick={resetUploadType} sx={{ marginBottom: 2 }}>
              <ArrowBack />
          </IconButton>
          <Typography sx={{ marginBottom: 2 }}>
            Kindly enter google excel sheet url below :
          </Typography>
          <Typography sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Kindly ensure you set General Access to "Anyone With Link", with the role as "Editor" 
          </Typography>
          <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label="URL"
              variant="outlined"
              value={url}
              type="url"
              onChange={(e) => setUrl(e.target.value)}
            />
            <LoadingButton variant="contained" onClick={handleFetchFromUrl} sx={{ marginTop: 2 }}>
              Fetch from URL
            </LoadingButton>
          </FormControl>
        </Container>
      )}
      {excelData && !loading && !paymentError && !success && (
        <>
          <div>
            <IconButton onClick={resetExcelUpload} sx={{ marginBottom: 2 }}>
                <ArrowBack />
            </IconButton>
            <Typography variant="h6" sx={{ marginTop: 4,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              SMS(s) Data:
            </Typography>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {excelData[0].map((header, index) => (
                      <TableCell key={index}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {excelData.slice(1).map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <TableCell key={cellIndex}>{cell}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {transactionCost !== false  && (
            <Container sx={{ marginTop: 2 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Bulk Custom Message Details : 
              </Typography>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  We price a single message at : {messageCost} KES, Way below market rates.
                </Typography>
              </Container>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Total Cost: {transactionCost} KES
                </Typography>
              </Container>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Number of Messages Added: {messages.length}
                </Typography>
              </Container>
            </Container>
          )}

          {error && (
            <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
              {error}
            </Typography>
          )}

          <LoadingButton variant="contained" onClick={handleFormSubmit} sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} disabled={loading}>
            Send Bulk Custom Messages
          </LoadingButton>
        </>
      )}

    </>
  );
}