import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner';
import { useUserContext } from '../../../UserContext';
import { b2ccustomer } from '../../../apis/chatbotapis';

const ProcessingComponent = () => (
  <Container style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Ensures the container takes the full height of the viewport
  }}>
    <div style={{ textAlign: 'center' }}>
      <ThreeDots 
        visible
        height="80"
        width="80"
        color="#4fa94d"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Retrieving Information ...
      </Typography>
    </div>
  </Container>
);

const B2CRepeatedCustomerComponent = () => {
  const { user } = useUserContext();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRepeatedCustomer = async () => {
      try {
        const response = await b2ccustomer(user);
        // console.log(response);
        setData(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRepeatedCustomer();
  }, [user]);

  if (loading) {
    return <ProcessingComponent />;
  }

  return (
    <div>
      {data ? (
        <div>
          <h3>Most Repeated Customer</h3>
          <p><strong>Customer Name:</strong> {data.recipientname}</p>
          <p><strong>Customer Phone:</strong> {data.phone}</p>
          <p><strong>Amount:</strong> {data.total_amount}</p>
        </div>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default B2CRepeatedCustomerComponent;