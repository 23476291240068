import React, { useEffect, useState } from 'react';
import { useNavigate,Navigate,Redirect } from 'react-router-dom';
import {
  Typography,Container,
  Button,Card,CardContent,Paper,Grid,
  IconButton,
  Table,Select,InputLabel,Link,MenuItem,InputAdornment,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ThreeDots } from 'react-loader-spinner';
// import { ThreeDots } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';
import { CloudUpload, Delete, ArrowBack, ArrowForward } from '@mui/icons-material';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify'; // Moved the import here

import { useUserContext } from '../../../../UserContext';
import Login from '../../../Login';
import { mmfbalance } from '../../../../apis/gettransactions';
import { bulkbuygoodspayments } from '../../../../apis/recurringpaymentsapis';

const ProcessingComponent = () => (
  // View when processing
  <Container style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Ensures the container takes the full height of the viewport
  }}>
    <div style={{ textAlign: 'center' }}>
      <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
      <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Processing Payment...
      </Typography>
    </div>
  </Container>
);  

const ErrorComponent = ({ paymentError, handlePaymentErrorButtonClick }) => (
  <div style={{ textAlign: 'center' }}>
    <IoMdAlert size={24} color="red" />
    <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>The following error occurred :</Typography>
    {/* <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      {paymentError.status}
    </Typography> */}
    <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      {paymentError.Message}
    </Typography>
    <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Try Again
    </Button>
  </div>
);

export default function UploadPaybillForm({ payments, setPayments,handleDelete,handleBack }) {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [uploadType, setUploadType] = useState('');
  const [url, setUrl] = useState('');
  const [amount, setPayment] = useState('');
  const [nullpayments, setNullPayments] = useState(false);
  const [excelData, setExcelData] = useState(null);

  const [paymentError, setPaymentError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState(null);
  const [balance, setBalance] = useState(user ? user.balance : '');
  const [transactionCost, setTransactionCost] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(false);
  let totalTransactionAmount = 0;
  let totalTransactionCost = 0;
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [loading, setLoading] = useState(false); // Added loading state

  const [emptyfields, setEmptyfields] = useState(false);
  const [step, setStep] = useState(1);
  const [taskname, setTaskName] = useState('');
  const [periodValue, setPeriodValue] = useState('');
  const [periodUnit, setPeriodUnit] = useState('months');
  // const [time, setTime] = useState('');

  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [initialTime, setInitialTime] = useState('');

  const ErrorComponent = ({ paymentError, handlePaymentErrorButtonClick }) => (
    <div style={{ textAlign: 'center' }}>
      <IoMdAlert size={24} color="red" />
      <Typography variant="body1" color="error" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        An error occurred!
      </Typography>
      <Typography 
        variant="body1" color="error" 
        style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        {paymentError.Service}
      </Typography>
      <Typography 
        variant="body1" color="error" 
        style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        {paymentError.Message}
      </Typography>
      {paymentError.TotalAmount &&
        <Typography 
          variant="body1" color="error" 
          style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          {paymentError.TotalAmount}
        </Typography>
      }
      {paymentError.AvailableBalance &&
        <Typography 
          variant="body1" color="error" 
          style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          {paymentError.AvailableBalance}
        </Typography>
      }
      {paymentError.Error &&
        <Typography 
          variant="body1" color="error" 
          style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          {paymentError.Error}
        </Typography>
      }
      {paymentError.BulkRequests.unsuccessful.map((request, index) => (
        <div key={index}>
          <Typography variant="body1" gutterBottom sx={{ color: 'red', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Unsuccessful Disbursement: Phone - {request.phone}, Status - {request.status}, Error - {request.error || request.message}
          </Typography>
        </div>
      ))}
      <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Try Again
      </Button>
    </div>
  );
  
  const SuccessComponent = ({ response, handleSuccessButtonClick}) => (
    <div style={{ textAlign: 'center' }}>
      <Checkmark size="96px" color="green" />
      <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Service: {response.Service}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Message: {response.Message}
      </Typography>
      <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Return to Dashboard
      </Button>
    </div>
  );

  // useEffect(() => {
  //   fetchBalance(user, setBalance);
  // }, [user]);  
  
  useEffect(() => {
    if (user) {
      fetchBalance(user, setBalance);
    }
  // }, [user]);  
  }, []);  

  if (!user) {
    <Login />;
  }
  if (!user) {
    return <Navigate to="/login" />; // Use Navigate to redirect to the login page
  }

  const fetchBalance = async (user, setBalance) => {
    try {
      const data = await mmfbalance(user);
      const bal = data.Account_Bal;
      setBalance(bal); // Set balance state
    } catch (error) {
      console.error(`Error fetching transactions: ${error}`);
    }
  };

  const showToast = (amount) => {
    toast.success(amount, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  const handleSuccessButtonClick = () => {
    setSuccess(false); // Set success state back to false
    navigate('/business-dashboard/app', { replace: true }); // Navigate to the dashboard
  };

  const handleUploadTypeChange = (event) => {
    setUploadType(event.target.value);
    setUrl(''); // Reset URL input when switching upload type
  };

  const resetUploadType = () => {
    setUploadType('');
    setUrl(''); // Reset URL input when switching upload type
  };

  const resetExcelUpload = () => {
    setUploadType('');
    setUrl(''); // Reset URL input when switching upload type
    setExcelData(false);
  };

  const calculateTransactionCharge = (deductedAmount) => {
    // Your existing table data
    const transactionChargeTable = [
        { min: 1, max: 49, registered: 0, unregistered: 8 },
        { min: 50, max: 100, registered: 0, unregistered: 8 },
        { min: 101, max: 500, registered: 5, unregistered: 8 },
        { min: 501, max: 1000, registered: 5, unregistered: 14 },
        { min: 1001, max: 1500, registered: 5, unregistered: 14 },
        { min: 1501, max: 2500, registered: 9, unregistered: 18 },
        { min: 2501, max: 3500, registered: 9, unregistered: 25 },
        { min: 3501, max: 5000, registered: 9, unregistered: 30 },
        { min: 5001, max: 7500, registered: 11, unregistered: 37 },
        { min: 7501, max: 10000, registered: 11, unregistered: 46 },
        { min: 10001, max: 15000, registered: 11, unregistered: 62 },
        { min: 15001, max: 20000, registered: 11, unregistered: 67 },
        { min: 20001, max: 25000, registered: 13, unregistered: 73 },
        { min: 25001, max: 30000, registered: 13, unregistered: 73 },
        { min: 30001, max: 35000, registered: 13, unregistered: 73 },
        { min: 35001, max: 40000, registered: 13, unregistered: null },
        { min: 40001, max: 45000, registered: 13, unregistered: null },
        { min: 45001, max: 50000, registered: 13, unregistered: null },
        { min: 50001, max: 70000, registered: 13, unregistered: null },
        { min: 70001, max: 250000, registered: 13, unregistered: null },
    ];
    

    const foundRow = transactionChargeTable.find((row) => deductedAmount >= row.min && deductedAmount <= row.max);
    return foundRow ? foundRow.registered : null;
  };

  const handleNextStep = () => {
    setEmptyfields(false);
    if (taskname === '') {
      setEmptyfields('Task Name field is missing');
      return;
    }
    if (periodValue === '') {
      setEmptyfields('Period field is missing');
      return;
    }
    if (date === '') {
      setEmptyfields('Time fields is missing');
      return;
    }
    if (time === '') {
      setEmptyfields('Time fields is missing');
      return;
    }
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleFormSubmit = async () => {
    setIsSubmitting(true);
    setLoading(true);
    try {
      if (taskname === '' || periodValue === '' || date === '' || time === '') {
        setEmptyfields(true);
        return;
      }
      
      const totalAmount = transactionAmount;
      // console.log('Total Amount:', totalAmount);

      const minimumBalance = parseInt(process.env.REACT_APP_MINIMUM_AMOUNT, 10);

      const balanceInt = parseInt(balance,10);
      // console.log(balanceInt)

      // Calculate the minimum allowed balance after withdrawal
      const minimumAllowedBalance = balanceInt - minimumBalance;
      // console.log(minimumAllowedBalance);
      
      if (minimumBalance > balanceInt) {
        setError(`Kindly add more funds to the Rotsi wallet, to be able to make a Bulk disbursement from the account. Minimum Account Balance is ${minimumBalance} KES`);
        toast.error(`A minimum of ${minimumBalance} KES is required to maintain your account. Kindly add more funds to your wallet.`);
        setLoading(false);
        return;
      }   

      if (totalAmount > minimumAllowedBalance) {
        setError(`Kindly add more funds to the Rotsi wallet, to be able to make a Bulk disbursement from the account. Minimum Account Balance is ${minimumBalance} KES. Total amount for your messages is : ${totalAmount}`);
        toast.error(`A minimum of ${minimumBalance} is required to maintain your account. You can withdraw up to ${minimumAllowedBalance} KES`);
        setLoading(false);
        return;
      }  

      // Check if any amount inside the payments array is less than 10
      const hasAmountLessThanTen = payments.some((payment) => payment.amount < 10);
      if (hasAmountLessThanTen) {
        setError('Minimum amount allowed to disburse is KES. 10');
        toast.error('Minimum amount allowed to disburse is KES. 10');
        setIsSubmitting(false);
        setLoading(false);
        return;
      }

      const initialDateTime = `${date}T${time}`;
      setInitialTime(initialDateTime);

      // console.log('Initial Time:', initialDateTime);

      const initialDate = new Date(initialDateTime);
      const now = new Date();

      const timeDifferenceMs = initialDate - now; // Difference in milliseconds
      // Convert milliseconds to different time units
      const timeDifferenceSeconds = Math.floor(timeDifferenceMs / 1000); // Convert to seconds
      const timeDifferenceDays = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24)); // Convert to days
      const timeDifferenceHours = Math.floor(timeDifferenceMs / (1000 * 60 * 60)); // Convert to hours

      // console.log('Time Difference in milliseconds:', timeDifferenceMs);
      // console.log('Time Difference in seconds:', timeDifferenceSeconds);
      // console.log('Time Difference in days:', timeDifferenceDays);
      // console.log('Time Difference in hours:', timeDifferenceHours);

      let formattedPeriod;
      if (periodUnit === 'months') {
        formattedPeriod = `${periodValue * 30}d`;
      } else {
        formattedPeriod = `${periodValue}${periodUnit}`;
      }

      const requestBody = {
        payments,
        taskname,
        period: formattedPeriod,
        time : `${(timeDifferenceSeconds).toString()}s`,
      };

      // console.log(requestBody);

      // console.log(payments);
      const responseData = await bulkbuygoodspayments(user,requestBody);
      setSuccess(true);
      setResponse(responseData);
    } catch (error) {
      // Handle error
      // console.error(error);
      // toast.error('Bulk disbursement unavailable. Please try again later.', error);
      setError(true);
      setPaymentError(error);
    } finally {
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  const handleFileRead = (e) => {
    setLoading(true); // Set loading to true during file read
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      setExcelData(jsonData);

      // Retrieve rows based on MobileNumber and Phone columns and add them to payments array
      const payments = [];
      for (let i = 1; i < jsonData.length; i += 1) { // Start from index 1 to skip headers
        const row = jsonData[i];
        
        const recipientSC = String(row[0]);
        const accountNumber = String(row[1]);
        const amount = parseInt(row[2], 10);
        const mobileNumber = row[3]; // Assuming MobileNumber is the first column
        const phone = formatphone(mobileNumber);
        
        // const purposeOfPayment = row[2]; // Assuming PurposeOfPayment is the third column
        // const name = row[3]; // Assuming Name is the fourth column
        // if (phone && !isNaN(amount)) { // Check if phone is not empty and amount is a valid number
        //   payments.push({ phone, amount, purposeOfPayment, name });
        // }
        if (recipientSC && accountNumber && phone && !Number.isNaN(amount)) { // Check if phone is not empty and amount is a valid number
          payments.push({ phone, amount,recipientSC,accountNumber });

          const transactionCharge = calculateTransactionCharge(amount); 

          totalTransactionCost += transactionCharge;

          const transactionAmount = amount + transactionCharge;

          totalTransactionAmount += transactionAmount;

          setTransactionAmount(totalTransactionAmount);
          setTransactionCost(totalTransactionCost);

          setPayments(payments); // Assuming you have a state variable for payments
          // // console.log(payments);
        }else{
          setNullPayments(true);
        }
      }
      setLoading(false); // Set loading to false after processing
    };

    reader.readAsArrayBuffer(file);
  };  

  const formatphone = (phone) => {
    phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    }else if (phone.startsWith('11') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
  };

  const handleFetchFromUrl = async () => {
    setLoading(true); 
    try {
      const response = await axios.get(url, { responseType: 'arraybuffer' });
  
      const data = new Uint8Array(response.data);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
      // Filter out rows with no meaningful content and skip the header row
      const filteredData = jsonData.filter((row, index) => {
        // Skip the header row
        if (index === 0 || row.length === 0) return false;
  
        // Check if any cell (except the first cell) has non-empty and non-null content
        return row.slice(1).some(cell => cell !== null && cell !== '');
      });
  
      // console.log("filteredData", filteredData);
  
      setExcelData(filteredData);
  
      // Format phone numbers and parse amounts for valid data rows
      const payments = [];
      let totalTransactionAmount = 0;
      let totalTransactionCost = 0;
      for (let i = 1; i < filteredData.length; i += 1) { // Start from index 1 to skip headers
        const row = filteredData[i];

        // const recipientSC = String(row[0]);
        // const accountNumber = String(row[1]);
        // const amount = parseInt(row[2], 10);
        // const mobileNumber = row[3]; // Assuming MobileNumber is the first column
        // const phone = formatphone(mobileNumber);

        const recipientSC = String(row[1]);
        const accountNumber = String(row[2]);
        const amount = parseInt(row[3], 10); // Assuming Amount is the third column, renamed to "amount"
        const phone = formatphone(row[4]); // Assuming MobileNumber is the second column, renamed to "phone"
      
      
        if (recipientSC && accountNumber && phone && !Number.isNaN(amount)) { // Check if phone is not empty and amount is a valid number
          payments.push({ phone, amount,recipientSC,accountNumber });
          // console.log("payments", payments);
          // Set payments
          const transactionCharge = calculateTransactionCharge(amount);
          totalTransactionCost += transactionCharge;

          const transactionAmount = amount + transactionCharge;

          totalTransactionAmount += transactionAmount;
          
          setPayments(payments);
          setTransactionAmount(totalTransactionAmount);
          setTransactionCost(totalTransactionCost);
        } else {
          setNullPayments(true);
        }
      } 
    } catch (error) {
      console.error('Error fetching or parsing Excel file:', error);
    } finally{
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <ProcessingComponent />}
      {paymentError && <ErrorComponent paymentError={paymentError} handlePaymentErrorButtonClick={() => setPaymentError('')} />}
      {success && <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick} />}
      
      {!excelData && !loading && !paymentError && !success && uploadType === '' && step === 1 && (
        <>
                 <Card>
          <CardContent>
            <IconButton onClick={handleBack} sx={{ marginBottom: 2 }}>
              <ArrowBack />
            </IconButton>
            <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              Enter Standing Order Information
            </Typography>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
              <Grid item xs={12}>
                <Typography variant="h6">Task Name</Typography>
                <TextField
                  label="Task Name"
                  variant="outlined"
                  fullWidth
                  value={taskname}
                  onChange={(e) => setTaskName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Interval Period i.e 1 month</Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={8}>
                    <TextField
                      label="Value"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={periodValue}
                      onChange={(e) => setPeriodValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      fullWidth
                      value={periodUnit}
                      onChange={(e) => setPeriodUnit(e.target.value)}
                    >
                      <MenuItem value='m'>Minutes</MenuItem>
                      <MenuItem value='h'>Hours</MenuItem>
                      <MenuItem value="d">Days</MenuItem>
                      <MenuItem value="w">Weeks</MenuItem>
                      <MenuItem value="months">Month(s)</MenuItem>
                      <MenuItem value='y'>Year</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Initial Payment Time</Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={6}>
                    <TextField
                      label="Date"
                      type="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Time"
                      type="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {emptyfields && (
              <Typography variant="body2" color="error" style={{ marginTop: '1rem', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                {emptyfields}
              </Typography>
            )}
            <Button
              onClick={handleNextStep}
              variant="contained"
              style={{ marginTop: '1rem', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
            >
              Proceed to setup recipients and amounts
            </Button>
          </CardContent>
        </Card>
        </>
      )}

      {!excelData && !loading && !paymentError && !success && uploadType === '' && step === 2 && (
        <Container>
            <IconButton onClick={handlePreviousStep} sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              <ArrowBack />
            </IconButton>
            <FormControl fullWidth sx={{ marginBottom: 2 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}>
              <InputLabel id="upload-type-label">Upload Type</InputLabel>
              <Select
                labelId="upload-type-label"
                id="upload-type"
                value={uploadType}
                onChange={handleUploadTypeChange}
              >
                <MenuItem value="file">
                  File Upload
                </MenuItem>
                <MenuItem value="url">
                  <Link /> URL Upload
                </MenuItem>
              </Select>
            </FormControl>
        </Container>
      )}
      {!excelData && !loading && !paymentError && !success && uploadType === 'file' && (
        <Container>
          <IconButton onClick={resetUploadType} sx={{ marginBottom: 2 }}>
              <ArrowBack />
          </IconButton>
          <Typography sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Click to upload payment excel sheet file.
          </Typography>
          <Typography sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Kindly ensure you set General Access to "Anyone With Link", with the role as "Editor" 
          </Typography>
          <label htmlFor="contained-button-file">
            <input
              accept=".xlsx"
              id="contained-button-file"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileRead}
            />
            <LoadingButton variant="contained" component="span" sx={{ marginTop: 2 }}>
              <CloudUpload />
            </LoadingButton>
          </label>
        </Container>
      )} 
      {!excelData && !loading && !paymentError && !success && uploadType === 'url' && (
        <Container>
          <IconButton onClick={resetUploadType} sx={{ marginBottom: 2 }}>
              <ArrowBack />
          </IconButton>
          <Typography sx={{ marginBottom: 2 }}>
            Kindly enter google excel sheet url below :
          </Typography>
          <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label="URL"
              variant="outlined"
              value={url}
              type="url"
              onChange={(e) => setUrl(e.target.value)}
            />
            <LoadingButton variant="contained" onClick={handleFetchFromUrl} sx={{ marginTop: 2 }}>
              Fetch from URL
            </LoadingButton>
          </FormControl>
        </Container>
      )}
      {excelData && !loading && !paymentError && !success && (
        <>
          <div>
            <IconButton onClick={resetExcelUpload} sx={{ marginBottom: 2 }}>
                <ArrowBack />
            </IconButton>
            <Typography variant="h6" sx={{ marginTop: 4,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              Payment Data:
            </Typography>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {excelData[0].map((header, index) => (
                      <TableCell key={index}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {excelData.slice(1).map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <TableCell key={cellIndex}>{cell}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {transactionCost !== false && transactionAmount !== false && (
            <Container sx={{ marginTop: 2 }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Bulk Payment Details : 
                </Typography>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Total Transaction Amount: {transactionAmount} KES
                </Typography>
              </Container>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Transaction Cost: {transactionCost} KES
                </Typography>
              </Container>
              <Container sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                  Number of Payments Added: {payments.length}
                </Typography>
              </Container>
            </Container>
          )}

          {error && (
            <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
              {error}
            </Typography>
          )}

          <LoadingButton variant="contained" onClick={handleFormSubmit} sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} disabled={isSubmitting}>
            Setup Buygoods Payment Based Standing Order
          </LoadingButton>
        </>
      )}

    </>
  );
}