import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Checkmark } from 'react-checkmark';
import { Button, Typography, Container, Box, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function OnboardingSuccess() {
  return (
    <Page title="Business-Onboarding Success">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography sx={{marginBottom: 4, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="p" paragraph>
            You have successfully onboarded your Paybill number to the Rotsi-EcoSystem. To get better personalized insights based on your pay-ins and pay-outs, leading to improved sales and profits.
          </Typography>

          <Checkmark size='96px' color='green' />

          <Typography sx={{marginTop: 5, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="p" paragraph>
            Kindly wait for account status verification, to be delivered via SMS. We will verify your uploaded documents and active status of your Paybill Number. As you wait, you can follow us on LinkedIn to get more from Rotsi. 
          </Typography>

          <Typography sx={{marginTop: 2, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="p" paragraph>
            Rotsi, Empowering Businesses. 
          </Typography>

          <IconButton
            href="https://www.linkedin.com/company/rotsi-api"
            target="_blank"
            aria-label="LinkedIn"
          >
            <LinkedInIcon style={{ fontSize: 40, color: '#0077b5' }} />
          </IconButton>

          {/* <Button to="/" size="large" variant="contained" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} component={RouterLink}>
            Go to Home
          </Button> */}
        </ContentStyle>
      </Container>
    </Page>
  );
}