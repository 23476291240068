import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// eslint-disable-next-line camelcase
const PaymentsAPI = (secretKey) =>
  axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': secretKey,
    },
});

const url = process.env.REACT_APP_SERVER_URL;

export const generateLink = async (user, body) => {
  try {
    const { secretKey, username } = user;

    const { initiatorName,paymentReason,amount } = body;

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const response  = await PaymentsAPIInstance.post(`${url}/paymentlink/paymentlinkcreation/v1`,
     { username, initiatorName,paymentReason,amount });
    
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const initiatePaymentLink = async (body) => {
  try {
    // const { secretKey, username } = user;

    const { phone,inviteLink } = body;

    console.log(phone,inviteLink);

    const response  = await axios.post(`${url}/paymentlink/initiateescrow/v1`, {
     body
    });
    
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const RenewSubscriptionFetchingUsers = async (user) => {
  try {
    const { secretKey, username } = user;

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
  
    const { data }  = await PaymentsAPIInstance.post(`${url}/auth/renewsubscription-fetchusers/v1`, { username });

    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const RenewSubscriptionPayments = async (user,body) => {
  try {
    const { secretKey, username } = user;

    const { phone, months,amount } = body;

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
  
    const { data }  = await PaymentsAPIInstance.post(`${url}/auth/renewsubscription/v1`, 
      { username, phone, months,amount });
    
    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const generateStaffLink = async (user, body) => {
  try {
    const { lastname, firstname, email } = user;

    const { initiatorName,paymentReason,amount } = body;

    // const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const response  = await axios.post(`${url}/paymentlink/paymentlinkcreation/v1`,
     { email,firstname,lastname, initiatorName,paymentReason,amount, isStaff: true });
    
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const getinvitedetails = async (body) => {
  try {
    const { invitelink } = body;
    console.log(invitelink);

    const response  = await axios.post(`${url}/paymentlink/getinvitedetails/v1`,
     { invitelink });
    
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};