import axios from 'axios';

const url = process.env.REACT_APP_SERVER_URL;

// eslint-disable-next-line camelcase
const GetTransactionsAPI = (secretKey, username) => {
  return axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': secretKey,
      'Username': username, 
    },
  });
};

export const repeatedCustomer = async (user) => {
  try {
    // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
  
    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/chatbot/getmostrepeatedcustomer');
    return data;
  } catch (error) {
    // if (error.response.data.message) {
    //   if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
    //     toast.info('To secure your account, We renew your credentials every hour.');
    //     toast.error('Kindly re-login, to resume access.');
    //   } else{
    //     toast.error(error.response.data.message) // Display error message from server
    //   }
    // } else {
    //   toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    // }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const mvpcustomer = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
     // eslint-disable-next-line camelcase
    // console.log('Secret Key:', secretKey);
    // console.log('Username:', username);

     // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/chatbot/gettoppaybillcustomer');
    return data;
  } catch (error) {
    // if (error.response.data.message) {
    //   if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
    //     toast.info('To secure your account, We renew your credentials every hour.');
    //     toast.error('Kindly re-login, to resume access.');
    //   }else{
    //     toast.error(error.response.data.message) // Display error message from server
    //   }
    // } else {
    //   toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    // }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const Nmvpcustomer = async (user,n) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
  
    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get(`/chatbot/getnmostamountcustomer?limit=${n}`);
    
    return data;
  } catch (error) {
    // if (error.response.data.message) {
    //   if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
    //     toast.info('To secure your account, We renew your credentials every hour.');
    //     toast.error('Kindly re-login, to resume access.');
    //   }else{
    //     toast.error(error.response.data.message) // Display error message from server
    //   }
    // } else {
    //   toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    // }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const NrepeatedCustomer = async (user,n) => {
  try {
    // eslint-disable-next-line camelcase
    const { secretKey, username } = user;

    // console.log(n);
  
    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get(`/chatbot/getnmostrepeatedcustomer?limit=${n}`);
    return data;
  } catch (error) {
    // if (error.response.data.message) {
    //   if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
    //     toast.info('To secure your account, We renew your credentials every hour.');
    //     toast.error('Kindly re-login, to resume access.');
    //   }else{
    //     toast.error(error.response.data.message) // Display error message from server
    //   }
    // } else {
    //   toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    // }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const b2ccustomer = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
  
     // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/chatbot/gethighestpaidtophonenumber');
    return data;
  } catch (error) {
    // if (error.response.data.message) {
    //   if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
    //     toast.info('To secure your account, We renew your credentials every hour.');
    //     toast.error('Kindly re-login, to resume access.');
    //   }else{
    //     toast.error(error.response.data.message) // Display error message from server
    //   }
    // } else {
    //   toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    // }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const Nb2ccustomer = async (user,n) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
  
    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get(`/chatbot/getnhighestpaidtophonenumber?limit=${n}`);
    
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const m2bcustomer = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
     // eslint-disable-next-line camelcase
    // console.log('Secret Key:', secretKey);
    // console.log('Username:', username);

     // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/chatbot/gethighestpaidtobank');
    return data;
  } catch (error) {
    // if (error.response.data.message) {
    //   if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
    //     toast.info('To secure your account, We renew your credentials every hour.');
    //     toast.error('Kindly re-login, to resume access.');
    //   }else{
    //     toast.error(error.response.data.message) // Display error message from server
    //   }
    // } else {
    //   toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    // }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const Nm2bcustomer = async (user,n) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
  
    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get(`/chatbot/getnhighestpaidtobank?limit=${n}`);
    
    return data;
  } catch (error) {
    // if (error.response.data.message) {
    //   if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
    //     toast.info('To secure your account, We renew your credentials every hour.');
    //     toast.error('Kindly re-login, to resume access.');
    //   }else{
    //     toast.error(error.response.data.message) // Display error message from server
    //   }
    // } else {
    //   toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    // }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const paybillcustomer = async (user) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
     // eslint-disable-next-line camelcase
    // console.log('Secret Key:', secretKey);
    // console.log('Username:', username);

     // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get('/chatbot/gethighestpaidforservice');
    return data;
  } catch (error) {
    // if (error.response.data.message) {
    //   if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
    //     toast.info('To secure your account, We renew your credentials every hour.');
    //     toast.error('Kindly re-login, to resume access.');
    //   }else{
    //     toast.error(error.response.data.message) // Display error message from server
    //   }
    // } else {
    //   toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    // }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const Npaybillcustomer = async (user,n) => {
  try {
     // eslint-disable-next-line camelcase
    const { secretKey, username } = user;
  
    // eslint-disable-next-line camelcase
    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);

    const { data } = await PaymentsAPIInstance.get(`/chatbot/getnhighestpaidtobank?limit=${n}`);
    
    return data;
  } catch (error) {
    // if (error.response.data.message) {
    //   if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
    //     toast.info('To secure your account, We renew your credentials every hour.');
    //     toast.error('Kindly re-login, to resume access.');
    //   }else{
    //     toast.error(error.response.data.message) // Display error message from server
    //   }
    // } else {
    //   toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    // }
    console.error(error.response.data);
    throw error.response.data;
  }
};

