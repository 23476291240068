import axios from 'axios';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../UserContext';

// eslint-disable-next-line camelcase
const PaymentsAPI = (secretKey) =>
  axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': secretKey,
    },
});

const url = process.env.REACT_APP_SERVER_URL;

export const Kickstartercreation = async (user, body) => {
  try {
    const { secretKey, username } = user;

    const { productname, productDescription, pitchvideo, prototypevideo,whatsappnumber } = body;

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const response  = await PaymentsAPIInstance.post(`${url}/kickstarter/creation/v1`,
     { username, prototypevideo,productDescription,productname,pitchvideo,whatsappnumber });
    
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error); // Toast the error message from the server
    } else {
      toast.error('Failed to Create kickstarter Wallet. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const getinvitedetails = async (body) => {
  try {
    const { invitelink } = body;
    const response  = await axios.post(`${url}/kickstarter/getinvitedetails/v1`,
     { invitelink });
   
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error); // Toast the error message from the server
    } else {
      toast.error('Failed to Create kickstarter Wallet. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const getwalletdetails = async () => {
  try {
    const response = await axios.post(`${url}/kickstarter/getwalletdetails/v1`);
    
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }

    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error); // Toast the error message from the server
    } else {
      toast.error('Failed to Get Escrow Wallet. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};
export const sendwhatsappinformation = async (body) => {
  try {
    // console.log(body);  
    const { whatsappnumber,email } = body;
    // console.log(whatsappnumber,email);  
  
    const response = await axios.post(`${url}/kickstarter/sendemail/v1`, whatsappnumber,email);
  
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error); // Toast the error message from the server
    } else {
      toast.error('Failed to initiate payment.');
    }
    throw error.response.data;
  }
};
export const helpfund = async (body) => {
  try {
    // console.log(body);  
    const { amount, phone,invitelink } = body;
    // console.log(amount, phone,invitelink);  
  
    const response = await axios.post(`${url}/kickstarter/initiatekickstarter/v1`, body);
  
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error); // Toast the error message from the server
    } else {
      toast.error('Failed to initiate payment.');
    }
    throw error.response.data;
  }
};